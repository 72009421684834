import React from "react";
import "./loading.css";

class LoadingPage extends React.Component{
    render(){
        return(
            <div className="iconcontainer">
                 
            <img className="loadingicon" src={require("assets/img/aspirelogo.jpg")} alt="logo"/>
            </div>
        )
    }
}

export default LoadingPage;