import React from 'react';
import EachFillBlanks from './EachFillBlanks';

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuesFillBlanks extends React.Component{
    constructor(props){
        super(props)
        this.state={
            part:this.props.part,
            questions:this.props.quesdata
        }
        this.results = [];
        this.loadquestiondetails = this.loadquestiondetails.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata[0]._id !== this.props.quesdata[0]._id) {
            this.setState({
                questions: this.props.quesdata,
                part:this.props.part
            },()=>{console.log(this.state.part)});
          }
    }

    loadquestiondetails(){
        const ctx = this;
        const quesrows = [];
        if(this.state.questions){
            this.state.questions.map(function (ques, i){
                for(let j=0;j<ctx.props.results.length; j++){
                    if(ctx.props.results[j].questionnum===ques.questionnum){
                        return quesrows.push(<EachFillBlanks 
                                                ispara={ctx.state.part.ispara} 
                                                question={ques} 
                                                resultindex={j}
                                                currentques = {ctx.props.currentques}
                                                setting ={ctx.props.setting}/>)
                    }
                }
            })
        }
        return quesrows;
    }

    render(){
        return(
            <>
                {this.loadquestiondetails()}
            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuesFillBlanks);