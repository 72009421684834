import React from "react";
import axios from "axios";
// reactstrap components

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 


import {
    Button,
    Card,
    Form,
    Input,
    Table,
    
  } from "reactstrap";
import UserHeader from "components/Headers/UserHeader.jsx";

class EditQuesDetails extends React.Component{

    constructor(props){
        super(props);

        this.state={
            testid:this.props.location.state.testid,
            partid:this.props.location.state.partid,
            ispara:this.props.location.state.ispara,
            questype:this.props.location.state.questype,
            id:this.props.location.state.ques._id,
            questionnum:this.props.location.state.ques.questionnum,
            question:this.props.location.state.ques.question,
            option1:this.props.location.state.ques.option1,
            option2:this.props.location.state.ques.option2,
            option3:this.props.location.state.ques.option3,
            option4:this.props.location.state.ques.option4,
            option5:this.props.location.state.ques.option5,
            rightanswer:this.props.location.state.ques.rightanswer,

            answerexplain:this.props.location.state.ques.answerexplain,
            singlerightanswer:'',
            showques:true,

            showoptions:false,
            showrightanswer:true,
            showwritingrightanswer:false
        }
        this.onSubmitClicked = this.onSubmitClicked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
        this.handleChangeanswerexplain = this.handleChangeanswerexplain.bind(this);

    }

    componentDidMount(){
        if(this.state.questype === "MCQ Single Right" || this.state.questype === "MCQ Multiple Right"){
            this.setState({
                showoptions:true,
                showrightanswer:false
            })
        }
        if(this.state.questype === "Speaking"||this.state.questype === "Cue Card Speaking"){
            this.setState({
                showoptions:false,
                showrightanswer:false
            })
        }
        if(this.state.questype === "Writing" ){
            this.setState({
                showwritingrightanswer:true,
                showrightanswer:false,
                showoptions:false
            })
        }
        if(this.state.questype === "Single Part Multiple Question" ){
            this.setState({
                showwritingrightanswer:false,
                showrightanswer:true,
                showoptions:false,
                showques:false
            })
        }
        if(this.state.rightanswer){
            var answer = '';
            for(let i=0 ;i<this.state.rightanswer.length; i++){
                if(i === this.state.rightanswer.length-1){
                    answer = answer+this.state.rightanswer[i]
                }
                else{
                    answer = answer+this.state.rightanswer[i]+"/"
                }
            }
            this.setState({
                singlerightanswer:answer
            })
        }
        
    }

    onSubmitClicked(){
        var ques = this.state.question
        var box = ques.replace("###","<input type='text' name = '"+this.state.questionnum+"' placeholder= '"+this.state.questionnum+"'/>")
        this.setState({
            question: box
        });

        if(this.state.showrightanswer && this.state.singlerightanswer){
            var singleright = this.state.singlerightanswer.split("/"); 
            this.setState({
                rightanswer:singleright
            },()=>{
                //console.log(this.state.question)
                axios.post('https://api.testacademia.com/admin/test/editques/'+this.state.id, this.state)
                .then(res => {
                    console.log(res.data)
                    alert("Question Details Updated");
                });    
            })
        }else{
            //console.log(this.state.question)
            axios.post('https://api.testacademia.com/admin/test/editques/'+this.state.id, this.state)
            .then(res => {
                console.log(res.data)
                alert("Question Details Updated");
            });
        }
    }

    handleChangeanswerexplain(value){
        this.setState({
            answerexplain:value
        })
    }

    handleChange (evt) {
        if(evt.target.name==="iscorrect1" && evt.target.checked){
            this.setState({
                rightanswer:[this.state.option1]
            })
        }else if(evt.target.name==="iscorrect1" && !evt.target.checked){
            this.setState({
                rightanswer: [],
            })
        }

        if(evt.target.name==="iscorrect2" && evt.target.checked){
            this.setState({
                rightanswer:[this.state.option2]
            })
        }else if(evt.target.name==="iscorrect2" && !evt.target.checked){
            this.setState({
                rightanswer: [],
            })
        }

        if(evt.target.name==="iscorrect3" && evt.target.checked){
            this.setState({
                rightanswer:[this.state.option3]
            })
        }else if(evt.target.name==="iscorrect3" && !evt.target.checked){
            this.setState({
                rightanswer: [],
            })
        }
        if(evt.target.name==="iscorrect4" && evt.target.checked){
            this.setState({
                rightanswer:[this.state.option4]
            })
        }else if(evt.target.name==="iscorrect4" && !evt.target.checked){
            this.setState({
                rightanswer: [],
            })
        }
        if(evt.target.name==="iscorrect5" && evt.target.checked){
            this.setState({
                rightanswer:[this.state.option5]
            })
        }else if(evt.target.name==="iscorrect5" && !evt.target.checked){
            this.setState({
                rightanswer: [],
            })
        }
        this.setState({ [evt.target.name]: evt.target.value });
        if(evt.target.name==="singlerightanswer"){
            this.setState({
                singlerightanswer: evt.target.value
            })
        }
        if(evt.target.name==="question"){
            this.setState({
                question: evt.target.value
            },()=>{
                console.log(this.state.question)
            })
        }

    }

    handleChangeQuestion(value){
        this.setState({
            question:value
        })
    }


    render(){
        return(
            <>
                <UserHeader/>
                <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                                    
                                    <tbody>
                                        <tr>
                                            <th>Question Number:</th>
                                            <th>
                                                <Form>
                                                    <Input
                                                        name="questionnum"
                                                        placeholder="Write your question number"
                                                        type="text"
                                                        value={this.state.questionnum}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form>
                                            </th>
                                        </tr>
                                        {this.state.showques && <tr>
                                            <th scope="row">Question:</th>
                                    {this.state.ispara==="yes" &&
                                    <td colSpan="5"><Form>
                                        <Input
                                        name="question"
                                        placeholder="Write your question here ..."
                                        type="textarea"
                                        value={this.state.question}
                                        onChange={this.handleChange}
                                    />
                                        </Form>
                                    </td>}
                                    {(this.state.ispara!=="yes") &&
                                    <td>
                                        <Form>
                                            <ReactQuill value={this.state.question}
                                                onChange={this.handleChangeQuestion}
                                                name="question" />
                                        </Form>
                                    </td>}
                                    </tr>}
                                    {(this.state.questype !=="MCQ Single Right" && this.state.questype !=="MCQ Multiple Right")  &&
                                    <tr>
                                    <th scope="row">Right Answer:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="rightanswer"
                                            placeholder="Enter Single Word Answer Here.."
                                            rows=""
                                            type="text"
                                            name = "singlerightanswer"
                                            onChange={this.handleChange}
                                            value={this.state.singlerightanswer}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4"></td>
                                        </tr>  } 
                                    {this.state.showoptions && <>
                                    <tr>
                                    
                                    <th scope="row">Option 1:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option1"
                                            placeholder="Enter Option 1 Here.."
                                            rows=""
                                            type="text"
                                            name = "option1"
                                            value={this.state.option1}
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                            name = "iscorrect1"
                                            defaultChecked={this.state.rightanswer.includes(this.state.option1)}
                                            onChange={this.handleChange}
                                            />
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 2:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option2"
                                            placeholder="Enter Option 2 Here.."
                                            rows=""
                                            type="text"
                                            name = "option2"
                                            value={this.state.option2}
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect2"
                                        defaultChecked={this.state.rightanswer.includes(this.state.option2)}
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 3:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option3"
                                            placeholder="Enter Option 3 Here.."
                                            rows=""
                                            type="text"
                                            name = "option3"
                                            value={this.state.option3}
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect3"
                                        defaultChecked={this.state.rightanswer.includes(this.state.option3)}
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 4:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option4"
                                            placeholder="Enter Option 4 Here.."
                                            rows=""
                                            type="text"
                                            name = "option4"
                                            value={this.state.option4}
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect4"
                                        defaultChecked={this.state.rightanswer.includes(this.state.option4)}
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 5:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option5"
                                            placeholder="Enter Option 5 Here.."
                                            rows=""
                                            type="text"
                                            name = "option5"
                                            value={this.state.option5}
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect5"
                                        defaultChecked={this.state.rightanswer.includes(this.state.option5)}
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    </>}
                                    {this.state.showwritingrightanswer &&
                                    <tr><th>Right Answer Type:</th><td><Input placeholder="Select Right Answer Type" 
                                    list="rightanswer"
                                    name="rightanswer"
                                    onChange={this.handleChange}/>
                                    <datalist id="rightanswer">
                                        <option value="Essay"/>
                                        <option value="Letter"/>
                                        <option value="Graph"/>
                                    </datalist>
                                        </td></tr>}
                                    <tr><th>Answer Explanation:</th>
                                    <td><Form>
                                            <ReactQuill 
                                                value={this.state.answerexplain || ''}
                                                onChange={this.handleChangeanswerexplain}
                                                name="answerexplain" />
                                        </Form> </td> 
                                    </tr>

                                    <tr>
                                    <td colSpan="4"></td>
                                    <td><Button color="primary" size = "sm" onClick={this.onSubmitClicked} >Submit</Button></td>
                                    </tr>
                                    </tbody>
                                    
                                    </Table>
                </Card>
            </>
        )
    }
}

export default EditQuesDetails;