import React from "react";
import axios from 'axios';
import Loader from 'react-loader-spinner'
import classnames from "classnames";

 import {
    Nav,
    NavLink,
    TabContent,
    TabPane,
 } from "reactstrap";
 import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import {
  Table,
  Row,
  Col
} from "reactstrap";

import {LoadPackagelist, LoadUserlist} from "../package/LoadPackageListfn"
import UserHeader from "components/Headers/UserHeader.jsx";
import UserRow from "./UserRow"
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

class Users extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      tabs:1,
      users: [],
      isLoading:true,
      studname: null,
      selectedCourse: 'IELTS',
      package:[],
      ptepackage:[],
      allpackages: [],
    };
    this.loaduser = this.loaduser.bind(this);
    this.searchstud = this.searchstud.bind(this);
  }

  componentWillMount(){
      this.fetchPackages(this.state.selectedCourse);
      this.loaduser(this.state.selectedCourse);
  }

  toggleNavs = (e, state, index,course) => {
    e.preventDefault();
    this.setState({
      [state]: index,
      isLoading:true,
      selectedCourse: course,
    },async ()=>{
      await this.fetchPackages(course);
      this.loaduser(course);
    });
  };

  async loaduser(course){
    var user = await LoadUserlist(course)
    this.setState({
      users: user
    },()=>{
      this.setState({isLoading:false})
    })
  }

  async fetchPackages(course){
    const allpacks = await LoadPackagelist(course);
    this.setState({
      allpackages: allpacks, 
    });
  }

  userList(){
      if(this.state.users && !this.state.studname){
        const ctx = this;
        return this.state.users.map(function(currentUser, i){
          // const pack = await ctx.fetchPackages(ctx.state.selectedCourse, currentUser);
          return <UserRow 
                    user={currentUser} 
                    key={i} 
                    selectedCourse={ctx.state.selectedCourse}
                    allpackages={ctx.state.allpackages}
                    />;
        });
      }
      if(this.state.studname){
        const ctx = this;
        return this.state.users.map(function(currentUser, i){
          if(currentUser.name.toLowerCase().includes(ctx.state.studname)){
            // const pack = await ctx.fetchPackages(ctx.state.selectedCourse, currentUser);
            return <UserRow 
                    user={currentUser} 
                    key={i} 
                    selectedCourse={ctx.state.selectedCourse} 
                    allpackages={ctx.state.allpackages}/>;
          }
      });
      }
  }

  searchstud(evt){
    this.setState({
      studname: evt.target.value
    },()=>{console.log(this.state.studname)})
  }
  
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"		
				/>}

          <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
                style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
              >
                <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 1,"IELTS")}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px",  "width":"150px", height:"40px"}}
                  >
                    <center>IELTS</center>
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 2,'PTE')}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>PTE</center>
                  </NavLink>
                  <NavLink
            aria-selected={this.state.tabs === 3}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 3
            })}
            onClick={e => this.toggleNavs(e, "tabs", 3,"GRE")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>GRE</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 4}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 4
            })}
            onClick={e => this.toggleNavs(e, "tabs", 4,"GMAT")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>GMAT</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 5}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 5
            })}
            onClick={e => this.toggleNavs(e, "tabs", 5,"TOEFL")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"100px", height:"40px"}}
          >
            <center>TOEFL</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 6}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 6
            })}
            onClick={e => this.toggleNavs(e, "tabs", 6,"SAT")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"100px", height:"40px"}}
          >
            <center>SAT</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 7}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 7
            })}
            onClick={e => this.toggleNavs(e, "tabs", 7,"OET")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"100px", height:"40px"}}
          >
            <center>OET</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 8}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 8
            })}
            onClick={e => this.toggleNavs(e, "tabs", 8,"EXPERT ENGLISH")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>EXPERT ENGLISH</center>
          </NavLink>
        </Nav>
        <input placeholder="search user" 
          style={{float:'right', marginRight:'20px'}}
          onChange={this.searchstud}/>
			{!this.state.isLoading &&
        <Col>
        <Row style={{"marginTop":"30px", "marginBottom":"30px","marginLeft":"15px","marginRight":"15px"}}>
        <Col className="mb-5 mb-xl-0" xl="8">
            <TabContent activeTab={"tabs" + this.state.tabs}>
              
              <TabPane tabId="tabs1">
              <Table className="align-items-center table-flush" >
            <thead className="thead-light">
                <tr>
                <th scope="col">Name</th>
                <th scope="col">User Id</th>
                <th scope="col">Course</th>
                <th scope="col">Type</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Mobile</th>
                <th scope="col">Edit</th>
                </tr>
            </thead>
              <tbody>
                {this.userList()}
              </tbody>
            </Table>
            </TabPane>
              <TabPane tabId="tabs2">
              <Table className="align-items-center table-flush" >
              <thead className="thead-light">
                <tr>
                <th scope="col">Name</th>
                <th scope="col">User Id</th>
                <th scope="col">Course</th>
                <th scope="col">Type</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Mobile</th>
                <th scope="col">Edit</th>
                </tr>
            </thead>
                <tbody>
                  {this.userList()}
                </tbody>
              </Table>
              </TabPane>
              <TabPane tabId="tabs3">
                  <Table className="align-items-center table-flush" >
                  <thead className="thead-light">
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Course</th>
                                <th scope="col">Type</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.userList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs4">
                  <Table className="align-items-center table-flush" >
                  <thead className="thead-light">
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Course</th>
                                <th scope="col">Type</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.userList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs5">
                  <Table className="align-items-center table-flush" >
                  <thead className="thead-light">
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Course</th>
                                <th scope="col">Type</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.userList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs6">
                  <Table className="align-items-center table-flush" >
                  <thead className="thead-light">
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Course</th>
                                <th scope="col">Type</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.userList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs7">
                  <Table className="align-items-center table-flush" >
                  <thead className="thead-light">
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Course</th>
                                <th scope="col">Type</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.userList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs8">
                  <Table className="align-items-center table-flush" >
                  <thead className="thead-light">
                                <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Course</th>
                                <th scope="col">Type</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.userList()}
                    </tbody>
                  </Table>
                  </TabPane>
            </TabContent>
            </Col>
            </Row>
            </Col>
         } 
      </>
    );
  }
}

export default Users;
