import React from "react";
import "../../assets/css/custom.css";

import 'react-quill/dist/quill.snow.css';
import "./panel.css";
import ReactHtmlParser from 'react-html-parser';
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';
import ParaInput from "./ParaInput";
import {Modal, 
  ModalBody,
  Button,
  Input,
  Row
} from "reactstrap";

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuestionPara extends React.Component{

    constructor(props){
        super(props);
        this.state={
            highlightmodal:false,
            paragraph: this.props.passage,
            image: this.props.image,
            part: this.props.part,
            useranswer: null,
            currentques:this.props.currentques,
            startquestion:this.props.part.startques,
            note:null,
            selectedstr:null,
            parsedHtml:null
        }
        this.transform = this.transform.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.togglemodal = this.togglemodal.bind(this);
        this.handlechangenote= this.handlechangenote.bind(this);
        this.onclick = this.onclick.bind(this);
    }

    componentDidMount(){
        this.setState({
            parsedHtml:ReactHtmlParser(this.state.paragraph,{transform: this.transform})
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.passage !== this.props.passage ||
             prevProps.image !== this.props.image || 
             prevProps.part !== this.props.part || 
             prevProps.currentques !== this.props.currentques) {
            this.setState({
                paragraph: this.props.passage,
                image: this.props.image,
                part: this.props.part,
                currentques:this.props.currentques,
                startquestion:this.props.part.startques,
            },()=>{
                this.setState({
                    parsedHtml:ReactHtmlParser(this.state.paragraph,{transform: this.transform})
                })
            });
        }
    }

    transform(node, index) {
        if (node.type === 'tag' && node.name === 'input') {
            for(let i=0; i<this.props.results.length; i++){
                if(this.props.results[i].parttype===this.state.part.parttype 
                    && this.props.results[i].questionnum===(this.state.startquestion)){
                        var droptarget = <ParaInput quesnum={this.state.startquestion} 
                                            part={this.state.part}
                                            resultindex={i}
                                            currentques={this.state.currentques}
                                            setting = {this.props.setting}/>
                        this.state.startquestion = this.state.startquestion +1;
                        return droptarget;
                    }
            }
            
        }
    }

    handlechange(evt){
        console.log(window.getSelection().toString());
        if(window.getSelection().toString().length!==0){
            this.setState({
                highlightmodal:true,
                selectedstr:window.getSelection().toString()
            })
        }
    }

    handlechangenote(evt){
        console.log(evt.target.value)
        this.setState({
            note:evt.target.value
        })
    }

    togglemodal(){
        this.setState(prevState=>({
            highlightmodal: !prevState.highlightmodal
        }))
    }

    onclick(){
        this.setState({
            highlightmodal: false
        },()=>{
            this.props.highlightpassage(this.state.selectedstr, this.state.note);
        })
    }

    render(){

        return(
            <>
                <div className={"passage_"+this.props.setting}>
                {this.state.part.parttype!=="Label Blanks" && <img src={this.state.image} alt=""/>}
                    <div onMouseUpCapture={()=>{this.handlechange()}} onDoubleClick={this.handlechange}>{this.state.parsedHtml}</div>
                </div>
                <Modal isOpen={this.state.highlightmodal} toggle={this.togglemodal} centered="true">
                    <ModalBody>
                        <Input type="textarea" placeholder="Type a note.." onChange={(evt)=>{this.handlechangenote(evt)}}/>
                        <Row style={{alignItems:"center", justifyContent:"center", marginTop:"10px"}}>
                            <Button color="primary" size="sm" 
                                onClick={()=>{this.onclick()}}>Highlight</Button>
                            <Button color="primary" size="sm"
                                onClick={()=>{this.onclick()}}>Make A Note</Button>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuestionPara);