import React from "react";

import { NavLink as Link } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'

class FreeVideoRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            video:this.props.video
        }
        this.onPlayClicked = this.onPlayClicked.bind(this);
        this.deleteVideo = this.deleteVideo.bind(this);
    }

    onPlayClicked(){
        this.props.getOnClickVideoId(this.props.index);
    }

    deleteVideo(){

    confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete the video?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
                if(this.props.video.course==="IELTS"){
                    axios.post("https://api.testacademia.com/admin/course/deletevideo/"+this.state.video._id)
                .then(res=>{
                    console.log(res.data);
                    alert(res.data)
                }).catch(err=>{
                    console.log(err)
                })
            }
            else{
                const name = this.props.video.course.toLowerCase().replace(" ","","g")
                axios.post("https://api.testacademia.com/admin/"+name+"/course/deletevideo/"+this.state.video._id)
                .then(res=>{
                    console.log(res.data);
                    alert(res.data)
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
          },
          {
            label: 'No'
          }
        ]
      });

    
}

    render(){
        return(
            <>
                <tr>
                    <th scope="row">{this.state.video.videoname}</th>
                    <td className="text-blue" onClick={this.onPlayClicked}>
                        <i className="ni ni-button-play text-blue" style={{marginRight:"5px"}}/>{" "}
                        Play
                    </td>
                    <Link to={{pathname:"/admin/editvideo/"+this.state.video._id,state:{course:this.state.video}}}>
                    <td className="text-red">
                        <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                        Edit
                    </td>
                    </Link>
                    <td className="text-red"  onClick={() => this.deleteVideo()}>
                        <i className="ni ni-basket text-red" style={{marginRight:"5px"}}/>{" "}
                        Delete
                    </td>
                </tr>
            </>
        )
    }
}

export default FreeVideoRow;