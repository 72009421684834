import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import "../testpanel/panel.css";

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class EachFillBlanks extends React.Component{
    constructor(props){
        super(props);

        this.state={
            currentques:this.props.currentques,
            question: this.props.question,
            useranswer: this.props.results[this.props.resultindex].useranswer,
            results: this.props.results[this.props.resultindex],
            ispara: this.props.ispara
        }
        this.renderquestion = this.renderquestion.bind(this);
        this.transform = this.transform.bind(this);
        this.handlechange = this.handlechange.bind(this);

        this.textInput = React.createRef();
    }

    componentDidMount(){
        if(this.state.question.questionnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.question !== this.props.question || prevProps.currentques!==this.props.currentques) {
            this.setState({
                question: this.props.question,
                useranswer: this.props.results[this.props.resultindex].useranswer,
                results: this.props.results[this.props.resultindex],
                ispara: this.props.ispara,
                currentques: this.props.currentques
            });
        }
        if(this.state.question.questionnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    renderquestion(){
        return (<div 
                className={""+(this.state.ispara==="yes"?
                            "fillblanks-ispara-ques_":"fillblanks-ques_")+this.props.setting}>
                {ReactHtmlParser(this.state.question.question,{transform: this.transform})}
                </div>)
    }

    transform(node) {
        // do not render any <span> tags
        if (node.type === 'tag' && node.name === 'input') {
          return <input name={this.state.question.questionnum} 
                    placeholder={this.state.question.questionnum}
                    onChange={this.handlechange}
                    value={this.state.useranswer}
                    ref={this.textInput}
                    onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}/>;
        }
    }

    handlechange(evt){
        this.setState({
            useranswer: evt.target.value
        },()=>{
            //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer,this.state.question.answerexplain);
            var results = this.props.results;
            var quesresult = results[this.props.resultindex];
            if(quesresult.rightanswer.includes(this.state.useranswer)){
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=1;
            }else{
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=0;
            }
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })
    }

    render(){
        return(
            <>
                {this.renderquestion()}
            </>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(EachFillBlanks);