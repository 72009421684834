import React from "react";

export default class Timer extends React.Component{
    
    constructor(props){
        super(props);

        this.state={
            mins:this.props.duration,
            secs:0,
            starttimer : this.props.starttimer
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.starttimer !== this.props.starttimer){
            this.setState({
                starttimer:this.props.starttimer
            },()=>{
                this.myInterval = setInterval(() => {
                    if (this.state.secs > 0) {
                        this.setState({
                            secs: this.state.secs - 1
                        })
                    }
                    if (this.state.secs === 0) {
                        if (this.state.mins === 0) {
                            clearInterval(this.myInterval)
                        } else {
                            this.setState({
                                mins: this.state.mins - 1,
                                secs: 59
                            })
                        }
                    } 
                }, 1000)
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render(){
        return(
            <div>
                { this.state.mins === 0 && this.state.secs === 0
                    ? this.props.ontimerstop(this.props.category)
                    : <h3 style={{fontSize:"14px", margin:"0px",marginRight:"10px"}}>
                        Time Remaining: {this.state.mins}:{this.state.secs < 10 ? `0${this.state.secs}` : this.state.secs}
                    </h3>
                }
            </div>
        )
    }
}