import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

// import AdminLayout from "layouts/AdminN.jsx";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import TestLayout from "layouts/TestLayout.jsx";
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import {createStore} from 'redux';
import allReducers from './redux-reducers/reducers';
import {Provider} from 'react-redux';

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <Switch>
      {/* <Route path="/adminN" render={props => <AdminLayout {...props} />} /> */}
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/test" render={props => <TestLayout {...props} />} />
      <Route path="/admin/Course/:id" render={props => <AdminLayout {...props} />}/>
      <Redirect from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
