import React from 'react';
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';
import ReactHtmlParser from 'react-html-parser';

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class EachMatch extends React.Component{
    constructor(props){
        super(props);

        this.state={
            currentques:this.props.currentques,
            question: this.props.question,
            useranswer: this.props.results[this.props.resultindex].useranswer,
            results: this.props.results[this.props.resultindex]
        }
        this.renderquestion = this.renderquestion.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.transform = this.transform.bind(this);
        this.textInput = React.createRef();
    }

    componentDidMount(){
        if(this.state.question.questionnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.question.questionnum !== this.props.question.questionnum || 
            prevProps.currentques !==this.props.currentques ||
            prevProps.results[this.props.resultindex] !== this.props.results[this.props.resultindex]) {
            this.setState({
                question: this.props.question,
                useranswer: this.props.results[this.props.resultindex].useranswer,
                results: this.props.results[this.props.resultindex],
                currentques:this.props.currentques
            });
        }
        if(this.state.question.questionnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    renderquestion(){
       return(<div>{ReactHtmlParser(this.state.question.question,{transform: this.transform})}</div>)
    }

    transform(node) {
        if (node.type === 'tag' && node.name === 'input') {
          return <DropTarget targetKey="options" onHit={this.onDrop}>
                    <input name={this.state.question.questionnum} 
                    placeholder={this.state.question.questionnum} 
                    value={this.state.useranswer}
                    ref={this.textInput}/>
                </DropTarget>;
        }
      }

    onDrop(data){
        if(data.target.name!=null){
            this.setState({
                useranswer:data.dragData
            },()=>{
                //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer,this.state.question.answerexplain);
                var results = this.props.results;
                var quesresult = results[this.props.resultindex];
                if(quesresult.rightanswer.includes(this.state.useranswer)){
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=1;
                }else{
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=0;
                }
                results[this.props.resultindex] = quesresult;
                this.props.updateResult(results);
            })
        }
    }

    render(){
        return(
            <>
                {this.renderquestion()}
            </>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(EachMatch);