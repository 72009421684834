import React from "react";
import axios from "axios";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

import  { Redirect } from 'react-router-dom'
// reactstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Input,
    InputGroup,
    Table,
    
  } from "reactstrap";

import UserHeader from "components/Headers/UserHeader.jsx";
 import PackageDetails from "./PackageDetails.jsx";

class EditTestDetails extends React.Component{

    constructor(props){
        super(props);

        this.state={
            id:this.props.location.state.test._id,
            testname:this.props.location.state.test.testname,
            maincategory:this.props.location.state.test.maincategory,
            premium:this.props.location.state.test.premium,
            testinstruction:this.props.location.state.test.testinstruction,
            duration:this.props.location.state.test.duration,
            readduration:this.props.location.state.test.readduration,
            listenduration:this.props.location.state.test.listenduration,
            writeduration:this.props.location.state.test.writeduration,
            speakduration:this.props.location.state.test.speakduration,
            totalmarks:this.props.location.state.test.totalmarks,
            parts:this.props.location.state.test.parts,
            packages:[],
            selectedpack:[],
            receivedpack:[],
            showtotalmarks:this.props.location.state.test.maincategory!=="Writing"&&this.props.location.state.test.maincategory!=="Speaking",

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTestinstruction = this.handleChangeTestinstruction.bind(this);
        this.onSubmitClicked = this.onSubmitClicked.bind(this);
        this.loadpackagelist = this.loadpackagelist.bind(this);
        this.handleChangePackage = this.handleChangePackage.bind(this);
        this.renderpackagedetail = this.renderpackagedetail.bind(this);
        this.receivepack = this.receivepack.bind(this);
        this.renderpackagedetail = this.renderpackagedetail.bind(this);


    }

    renderpackagedetail(){
        const packrows = [];
        const ctx = this;
        if(this.state.selectedpack)
        this.state.selectedpack.map(function(pack,i){
            packrows.push(<PackageDetails id={ctx.state.id} package={pack} receivepack={ctx.receivepack}/>)
        })
        return packrows;
    }

    handleChange (evt) {
            this.setState({ [evt.target.name]: evt.target.value });
            if(evt.target.name === "maincategory"){
                if(evt.target.value === "Writing" || evt.target.value === "Speaking"){
                    this.setState({
                        showtotalmarks : false
                    })
                }
                else{
                    this.setState({
                        showtotalmarks : true
                    })
                }
            }
    }

    handleChangeTestinstruction(value){
        this.setState({
            testinstruction:value
        })
    }
    componentDidMount(){
        axios.post('https://api.testacademia.com/admin/package/showallpack')
        .then(res=>{
            this.setState({
                packages: res.data,
                selectedpack: res.data.filter(item => this.state.premium.includes(item._id))

            })
        })
        .catch(function(err){
            console.log(err)
        })
    }

    loadpackagelist(){
        const packagelist = [];
        const ctx = this;
        
        if(this.state.packages){
            this.state.packages.map(function(pack,i){
                packagelist.push(
                    <td>
                        <input type="checkbox" 
                            name="premium" 
                            value={pack.packagename}
                            onChange={(evt)=>{ctx.handleChangePackage(evt,pack)}}
                            checked = {ctx.state.premium.includes(pack._id)?true:false}
                            />
                            {pack.packagename}
                    </td>
                )
            })
            return packagelist
        }
    }
    
    handleChangePackage(evt, pack){
                if(evt.target.checked){
                    this.setState({
                        selectedpack: [...this.state.selectedpack,pack],
                        premium:[...this.state.premium,pack._id]
                    })
                }else if(!evt.target.checked){
                    var selectedpackArr = [...this.state.selectedpack];
                    var premiumArr = [...this.state.premium];
        
                    var indextoremove1 = selectedpackArr.indexOf(pack);
                    var indextoremove2 = premiumArr.indexOf(pack._id);
                    if((indextoremove1!==-1)||(indextoremove2!==-1) ){
                        selectedpackArr.splice(indextoremove1,1);
                        premiumArr.splice(indextoremove2,1);
        
                        this.setState({
                            selectedpack: selectedpackArr,
                            premium:premiumArr
                        },()=>{
                            console.log(this.state.premium)
                        })
                    }
                }
            }
        

    receivepack(pack){
        this.setState({
            receivedpack: [...this.state.receivedpack, pack]
        },()=>{
            console.log(this.state.receivedpack)
        })
    }


    onSubmitClicked(){
        axios.post('https://api.testacademia.com/admin/test/edittest/'+this.state.id, this.state)
        .then(res => {
            console.log(res.data);
            alert("Test Details Updated")

            this.state.receivedpack.map(function(pack){
                var testtopackage = {
                    packageid: pack.packageid,
                    packagecat: pack.testcategory,
                    testid: res.data
                }
                axios.post('https://api.testacademia.com/admin/package/edittest/'+pack.packageid, testtopackage)
                .then(res=>{
                    console.log(res.data);
                })
            })
        });
    }

    render(){
        return(
            <>
                <UserHeader />
                <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                                    
                                    <tbody>
                                    <tr>
                                    <th scope="row">Test Name:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Test Name" type="text"
                                            name = "testname"
                                            value ={this.state.testname}
                                            onChange={this.handleChange}/>
                                    </InputGroup></td>

                                    <th scope="row">Main Category:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Select Main Category" list="category" 
                                            name = "maincategory"
                                            value={this.state.maincategory}
                                            onChange={this.handleChange}/>
                                    <datalist id="category">
                                        <option value="Reading"/>
                                        <option value="Writing"/>
                                        <option value="Listening"/>
                                        <option value="Speaking"/>
                                        <option value="Full Length Test"/>
                                    </datalist>
                                    </InputGroup></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Premium:</th>
                                        {this.loadpackagelist()}
                                    </tr>
                                    {this.renderpackagedetail()}
                                    <tr>
                                    <th scope="row ">Test Instruction:</th>
                                    <td colSpan="5"><Form>
                                            <ReactQuill value={this.state.testinstruction}
                                                onChange={this.handleChangeTestinstruction}
                                                name="testinstruction" />
                                        </Form> </td> 
                                    </tr>
                                    {this.state.maincategory!=="Full Length Test" &&
                                    <tr>
                                    <th scope="row">Duration:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Duration of test" list="duration" 
                                            name = "duration"
                                            value={this.state.duration}
                                            onChange={this.handleChange}/>
                                        <datalist id="duration">
                                        <option value="0.5 min"/>
                                        <option value="1 min"/>
                                        <option value="30 mins"/>
                                        <option value="45 mins"/>
                                        <option value ="60 mins"/>
                                        <option value = "180 mins"/>
                                    </datalist>
                                    </InputGroup></td>
                                    {this.state.showtotalmarks === true &&
                                    <th scope="row">Total Marks:</th>}
                                    {this.state.showtotalmarks === true &&
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Total Marks" list="marks" 
                                            name = "totalmarks"
                                            onChange={this.handleChange}/>
                                    <datalist id="marks">
                                        <option value="10"/>
                                        <option value="20"/>
                                        <option value="30"/>
                                        <option value="40"/>
                                        <option value ="120"/>
                                    </datalist>
                                    </InputGroup></td>}
                                    </tr>}
                                    
                                    {this.state.maincategory==="Full Length Test" &&
                                    <tr>
                                        <th scope="row">Reading Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "readduration"
                                                value={this.state.readduration}
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                        <th scope="row">Listening Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "listenduration"
                                                value={this.state.listenduration}
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                    </tr>}
                                    {this.state.maincategory==="Full Length Test" &&
                                    <tr>
                                        <th scope="row">Writing Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "writeduration"
                                                value={this.state.writeduration}
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                        <th scope="row">Speaking Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "speakduration"
                                                value={this.state.speakduration}
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                    </tr>}

                                    <tr>
                                        <th colSpan="5"/>
                                        <td ><Button color="primary" size = "sm" onClick = {this.onSubmitClicked}>Submit</Button></td>
                                    </tr>
                                    </tbody>
                                    
                                    </Table>
                </Card>
            </>
        )
    }
}

export default EditTestDetails;