import React from 'react';
import "../testpanel/panel.css";
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';

import{
    Row,
    Col,
    Table
} from "reactstrap";
import EachHeading from './EachHeading';

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuesListHead extends React.Component{
    constructor(props){
        super(props)
        this.state={
            currentques:this.props.currentques,
            questions:this.props.quesdata,
            part:this.props.part,
            listofheadings:this.props.listofheadings
        }
        this.results = [];
        this.loadquestiondetails = this.loadquestiondetails.bind(this);
        this.loadlistofheadings = this.loadlistofheadings.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata !== this.props.quesdata) {
            this.setState({questions: this.props.quesdata});
          }
        if(prevProps.listofheadings !== this.props.listofheadings) {
            this.setState({listofheadings: this.props.listofheadings});
          }
        if(prevProps.question !== this.props.question) {
            this.setState({question: this.props.question});
          }
        if(prevProps.part !== this.props.part) {
            this.setState({part: this.props.part});
        }
        if(prevProps.currentques !== this.props.currentques){
            this.setState({currentques:this.props.currentques},()=>{console.log(this.state.currentques)});
        }
    }

    loadlistofheadings(){
        const headingrows = [];
        const ctx =this;
        if(this.state.listofheadings){
            this.state.listofheadings.map(function(heading, i){
                headingrows.push(<DragDropContainer targetKey="headings" dragData={heading}><button className={"headingTD_"+ctx.props.setting} dangerouslySetInnerHTML={{ __html: heading  }} /></DragDropContainer>)
            })
        }
        return headingrows;
    }

    loadquestiondetails(){
        const quesrows = [];
        const ctx =this;
        //console.log(this.state.questions)
        if(this.state.questions){
            this.state.questions.map(function (ques, i){
                for(let i=0;i<ctx.props.results.length; i++){
                    if(ctx.props.results[i].questionnum===ques.questionnum
                        && ctx.props.results[i].parttype===ctx.props.part.parttype){
                        quesrows.push(<EachHeading ispara={ctx.state.part.ispara} 
                                        question={ques} 
                                        resultindex={i}
                                        currentques = {ctx.props.currentques}
                                        setting ={ctx.props.setting}/>)
                        return
                    }
                }
            })
        }
        return quesrows;
    }

    onDrop(data){
        if(data.target.name!=null){
            console.log("Answered: "+data.target.name+" With: "+data.dragData)
            console.log("Right Answer is: "+this.state.questions[data.target.name-1].rightanswer[0])
        }
    }
    render(){
        return(
            <>
            
            <Row>
            <div >{this.loadquestiondetails()}</div>
            </Row>
            <Row>
                {this.loadlistofheadings()}
            </Row>

            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuesListHead);