const resultsReducer = (state = [], action)=>{
    switch(action.type){
        case 'update_result':
            //update one specific result
            state = action.result;
            return state;
        case 'initialise_results':
            state = action.results;
            return state;
        default:
            return state;
    }
}

export default resultsReducer;