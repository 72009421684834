import React from "react";

import { NavLink as Link } from "react-router-dom";

import {
    Button,
    Card,
    CardHeader,
    Table,
    Row,
}from "reactstrap";
import ReactPlayer from 'react-player';


class ListeningInstruction extends React.Component{

    constructor(props){
        super(props);
        this.state={
            play:false,
            btn:"Play",
            volumeVal:0.5,
        }
        this.play = this.play.bind(this);
        this.handleVolume = this.handleVolume.bind(this);
    }
    play(){
        var url = "https://aspireaudio.s3.amazonaws.com/01 Listening for IELTS";
        console.log(url)
        this.setState((prevState => ({
            play: !prevState.play
        })),()=>{
            this.setState({
                btn:this.state.play?"Play":"Pause"
            })
        })
    }

    handleVolume(e) {
            this.setState({
                volumeVal: e.target.value,
            },()=>{
                this.props.setvolume(this.state.volumeVal);
            });
    }

    render(){
        return(
            <>
            <Card className="shadow" style={{marginLeft:"30%",marginTop:"170px",display:"inline-block"}}>
              <div>
                    <div >
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Test Sound</h3>
                                </div>
                                <div className='row' style={{marginRight:"15px"}}>
                                <div className='slider-wrapper'>
                                    <i className="ni ni-notification-70" style={{marginRight:"15px"}}></i>
                                    <input type="range" min='0' max='1' value={this.state.volumeVal} onChange={this.handleVolume} step='0.1' />
                                </div>  
                            </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <tbody>
                                <tr>
                                    <td>Put on your headphones and click on the Play sound button to play a sample sound.</td>
                                    
                                </tr> 
                                <tr>
                                    <td><Button color="primary" size="sm" type="button" style={{marginLeft:"200px"}} onClick={()=>{this.play()}}>{this.state.btn} sound</Button>
                                    <ReactPlayer 
                                            url= "https://testacademia-files.s3.amazonaws.com/01 Listening for IELTS"
                                            playing = {this.state.play}
                                            volume = {this.state.volumeVal}
                                            style={{display:"none"}}/></td>
                                </tr>
                                <tr>
                                    <td><i className="ni ni-bell-55 text-red"/> 
                                    If you cannot hear the sound clearly, please check speaker settings.
                                    </td>                                    
                                </tr>
                                <tr>
                                    
                                    <td>
                                        <Button color="primary" 
                                                size="sm" 
                                                type="button" 
                                                style={{marginLeft:"210px"}}
                                                onClick={()=>{this.props.changelistteststate()}}>
                                                    continue
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                            </Table>

                            
                        </Card>
                    </div>
                  
              </div>
            </Card>
            </>
        )
    }
}

export default ListeningInstruction;