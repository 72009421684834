import axios from "axios";

export async function LoadPackagelist(course) {
    if(course!=="IELTS"){
    const name = course.toLowerCase().replace(" ","","g")
    var res = await axios.post('https://api.testacademia.com/admin/'+name+'/package/showallpack');
    return res.data}
    else{
        var res = await axios.post('https://api.testacademia.com/admin/package/showallpack');
        return res.data
    }
}

export async function LoadUserlist(course){
    if(course!=="IELTS"){
        const name = course.toLowerCase().replace(" ","","g")
        var res = await axios.post('https://api.testacademia.com/admin/'+name+'/student/showallstud');
        return res.data}
        else{
            var res = await axios.post('https://api.testacademia.com/admin/student/showallstud');
            return res.data
        }
}

export async function LoadVideolist(course){
    if(course!=="IELTS"){
        const name = course.toLowerCase().replace(" ","","g")
    var res = await axios.post('https://api.testacademia.com/admin/'+name+'/course/showallvideo')
        return res.data;
    }
    else{
        var res = await axios.post('https://api.testacademia.com/admin/course/showallvideo')
        return res.data
    }
}