export const evaluateListen = (listenResultList) =>{
    var listentotalscore = 0;
    var listeningband = 0;
    for(let i=0; i<listenResultList.length; i++){
        listentotalscore = listentotalscore + listenResultList[i].score
    }
    if(listentotalscore>=11 && listentotalscore<=12) listeningband=4;
    else if(listentotalscore>=13 && listentotalscore<=15) listeningband=4.5;
    else if(listentotalscore>=16 && listentotalscore<=17) listeningband=5;
    else if(listentotalscore>=18 && listentotalscore<=22) listeningband=5.5;
    else if(listentotalscore>=23 && listentotalscore<=25) listeningband=6;
    else if(listentotalscore>=26 && listentotalscore<=29) listeningband=6.5;
    else if(listentotalscore>=30 && listentotalscore<=31) listeningband=7;
    else if(listentotalscore>=32 && listentotalscore<=34) listeningband=7.5;
    else if(listentotalscore>=35 && listentotalscore<=36) listeningband=8;
    else if(listentotalscore>=37 && listentotalscore<=38) listeningband=8.5;
    else if(listentotalscore>=39 && listentotalscore<=40) listeningband=9;
    
    return listeningband;
}