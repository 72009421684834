import axios from "axios";
export const evaluateSpeak = (userid,test, speakPartList) =>{
    axios.post('https://api.testacademia.com/user/upgrade/finduser',{userid:userid})
        .then(res=>{
            axios.post('https://api.testacademia.com/admin/package/onepackage/'+res.data.userType)
            .then(res=>{
                if(res.data.scored.includes(test._id)){
                    var scoredtest = true;
                    var speakPartListResult = speakPartList;
                    const headers = {
                        'x-api-key': '6ih0Rdi2qm4faked9SbD33mCvgraTca53dZAxCsA',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin':'*'
                      }
                    for(let i=0; i<speakPartList.length; i++){
                        axios.post("https://api.crucibledesk.com/v1/testacademia-speaking-api",
                                    {"url":"https://pte.crucibledesk.com/student-details/782_38/764/blob.wav","text":"String of the audio"},
                                    {headers: headers})
                                    .then((res)=>{
                                        console.log(res.data);
                                        speakPartListResult[i].result = res.data;
                                    })
                    }
                    return {scoredtest:scoredtest, result:speakPartListResult}
                }else{
                    return {scoredtest:false,result:null}
                }
            })
        })
}