import React from "react";
import axios from "axios";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

class TestRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            testname:this.props.test.testname,
            testid:this.props.test._id,
            testpremium:this.props.test.premium,
        }
        this.deleteTest = this.deleteTest.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.test !== this.props.test) {
            this.setState({
                testname:this.props.test.testname,
                testid:this.props.test._id,
                testpremium:this.props.test.premium,
            });
          }
        }
        
        deleteTest(){

            confirmAlert({
                title: 'Confirm to Delete',
                message: 'Are you sure you want to delete the test?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        axios.post("https://api.testacademia.com/admin/test/deletetest/"+this.state.testid)
                        .then(res=>{
                            console.log(res.data);
                            alert(res.data)
                        }).catch(err=>{
                            console.log(err)
                        })
                    }
                  },
                  {
                    label: 'No'
                  }
                ]
              });
                }

    render(){
        return(
            <>
                <tr>
                    <th scope="row">{this.state.testname}</th>
                    <td></td>
                    <td></td>
                    {this.state.testpremium.includes("free") && <Link to={"/test/livetest/"+this.state.testid}>
                        <td className="text-green">
                        <i className="ni ni-single-copy-04" style={{"marginRight":"10px"}}/>{" "}
                        Take Test
                        </td>
                    </Link>}

                    {!this.state.testpremium.includes("free") && <Link to={"/test/livetest/"+this.state.testid}>
                        <td className="text-red">
                        <i className="ni ni-bag-17 text-red" style={{marginRight:"5px"}}/>{" "}
                        Upgrade
                        </td>
                    </Link>}

                    <Link to={"/admin/edittest/"+this.state.testid}>
                    <td className="text-red">
                        <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                        Edit
                    </td>
                    </Link>
                    <td className="text-red">
                        <i className="ni ni-basket text-red" style={{marginRight:"5px"}} onClick={() => this.deleteTest()}/>{" "}
                        Delete
                    </td>
                </tr>
            </>
        )
    }
}

export default TestRow;