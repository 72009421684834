import React from "react";
import axios from "axios";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

// reactstrap components
import {
    Button,
    Card,
    Form,
    Input,
    InputGroup,
    Table,
    
  } from "reactstrap";
  // core components


import AddPartDetails from "./AddPartDetails";
import AddQuesDetails from "./AddQuesDetails";
import TestDetail from "./TestDeatil";
import PartDetail from "./PartDetail"
import PackageDetails from "./PackageDetails";

export default class TestDetails extends React.Component{

    constructor(props){
        super(props);
        this.state={
            showpartcomp:false,
            showquescomp:false,
            testname:'',
            maincategory:'',
            premium:[],
            showpackdetail:false,
            showtotalmarks:false,
            testinstruction:'',
            duration:'',
            totalmarks:'',
            parts:[],
            partdetails:'',
            questions:'',
            quesdatas:'',
            packages:[],
            selectedpack:[],
            receivedpack:[],
            readduration:null,
            listenduration:null,
            writeduration:null,
            speakduration:null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.onNextClicked = this.onNextClicked.bind(this);
        this.renderpartcomp = this.renderpartcomp.bind(this);
        this.addpartsintoarray = this.addpartsintoarray.bind(this);
        this.getPartDetails = this.getPartDetails.bind(this);
        this.getQuesArray = this.getQuesArray.bind(this);
        this.rerenderpartcomp = this.rerenderpartcomp.bind(this);
        this.submittest = this.submittest.bind(this);
        this.handleChangePackage = this.handleChangePackage.bind(this);
        this.renderpackagedetail = this.renderpackagedetail.bind(this);
        this.receivepack = this.receivepack.bind(this);

        this.handleChangeTestinstruction = this.handleChangeTestinstruction.bind(this);

        this.loadpackagelist = this.loadpackagelist.bind(this);
    }

    componentDidMount(){
        axios.post('https://api.testacademia.com/admin/package/showallpack')
        .then(res=>{
            this.setState({
                packages: res.data
            })
        })
        .catch(function(err){
            console.log(err)
        })
    }

    loadpackagelist(){
        const packagelist = [];
        const ctx = this;
        if(this.state.packages){
            this.state.packages.map(function(pack,i){
                packagelist.push(
                    <td>
                        <input type="checkbox" 
                            name="premium" 
                            value={pack._id}
                            onChange={(evt)=>{ctx.handleChangePackage(evt, pack)}}/>
                            {pack.packagename}
                    </td>
                )
            })
            return packagelist
        }
    }

    handleChangePackage(evt, pack){
        if(evt.target.checked){
            this.setState({
                selectedpack: [...this.state.selectedpack,pack],
                premium:[...this.state.premium,pack._id]
            })
        }else if(!evt.target.checked){
            var selectedpackArr = [...this.state.selectedpack];
            var premiumArr = [...this.state.premium];

            var indextoremove = selectedpackArr.indexOf(pack);
            if(indextoremove!==-1){
                selectedpackArr.splice(indextoremove,1);
                premiumArr.splice(indextoremove,1);

                this.setState({
                    selectedpack: selectedpackArr,
                    premium:premiumArr
                })
            }
        }
    }

    renderpackagedetail(){
        const packrows = [];
        const ctx = this;
        this.state.selectedpack.map(function(pack,i){
            packrows.push(<PackageDetails package={pack} receivepack={ctx.receivepack}/>)
        })
        return packrows;
    }

    receivepack(pack){
        this.setState({
            receivedpack: [...this.state.receivedpack, pack]
        },()=>{
            console.log(this.state.receivedpack)
        })
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value });
        
        if(evt.target.name === "maincategory"){
            if(evt.target.value === "Writing" || evt.target.value === "Speaking"){
                this.setState({
                    showtotalmarks : false
                })
            }
            else{
                this.setState({
                    showtotalmarks : true
                })
            }
        }
    }

    handleChangeTestinstruction(value){
        this.setState({
            testinstruction:value
        },()=>{console.log(this.state.testinstruction)})
    }

    onNextClicked(){
        this.setState({
            showpartcomp:true
        },()=>{
            console.log(this.state)
        })
    }
    
    renderpartcomp(){
        return <AddPartDetails getPartDetails = {this.getPartDetails}/>
    }

    renderquescomp(){
        return <AddQuesDetails 
                    startques={this.state.partdetails.startques}
                    parttype = {this.state.partdetails.parttype} 
                    endques={this.state.partdetails.endques} 
                    ispara={this.state.partdetails.ispara}
                    getQuesArray = {this.getQuesArray}
                    rerenderpartcomp = {this.rerenderpartcomp}
                    submittest = {this.submittest}/>
    }

    submittest(){
        axios.post('https://api.testacademia.com/admin/test/addtest', this.state)
        .then(res => {
            console.log(res.data);
            alert("Test Added Successfully")
            window.location.reload();
            //send packagae_id, package_category and test_id
            this.state.receivedpack.map(function(pack){
                var testtopackage = {
                    packageid: pack.packageid,
                    packagecat: pack.testcategory,
                    testid: res.data
                }
                axios.post('https://api.testacademia.com/admin/package/addtest/'+pack.packageid, testtopackage)
                .then(res=>{
                    console.log(res.data);
                })
            })
            
        }); 
        console.log(this.state)
    }

    rerenderpartcomp(){
        this.setState({
            showquescomp:false,
            showpartcomp:true
        })
    }

    getPartDetails(partDetails){
        this.setState({
            partdetails:partDetails,
            showpartcomp:false,
            showquescomp:true
        },()=>{
            console.log(this.state.partdetails)
        })
    }

    getQuesArray(quesArray, quesdatas){
        var partdetails = {...this.state.partdetails};
        partdetails.questions = quesArray;
        partdetails.quesdata = quesdatas;
        this.setState({partdetails}, ()=>{
            this.addpartsintoarray(this.state.partdetails)
        });
    }

    addpartsintoarray(part){
        this.setState({
            parts: [...this.state.parts, part]
        })
    }

    render(){
        return(
            <>
            {/* Page content */}
            {this.state.showpartcomp && <TestDetail testdetails={this.state}/>}
            {this.state.showquescomp && <PartDetail partdetails={this.state.partdetails}/>}
            {!this.state.showpartcomp && !this.state.showquescomp  && 
                <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                                    
                                    <tbody>
                                    <tr>
                                    <th scope="row">Test Name:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Test Name" type="text"
                                            name = "testname"
                                            onChange={this.handleChange}/>
                                    </InputGroup></td>

                                    <th scope="row">Main Category:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Select Main Category" list="category" 
                                            name = "maincategory"
                                            onChange={this.handleChange}/>
                                    <datalist id="category">
                                        <option value="Reading"/>
                                        <option value="Writing"/>
                                        <option value="Listening"/>
                                        <option value="Speaking"/>
                                        <option value="Full Length Test"/>
                                    </datalist>
                                    </InputGroup></td>
                                    </tr>
                                    </tbody>
                                    <tbody>
                                    <tr>
                                        <th scope="row">Premium:</th>
                                        {this.loadpackagelist()}
                                    </tr>
                                    </tbody>
                                    <tbody>
                                    {this.renderpackagedetail()}
                                    <tr>
                                    <th scope="row ">Test Instruction:</th>
                                    <td colSpan="5"><Form>
                                            <ReactQuill value={this.state.testinstruction}
                                                onChange={this.handleChangeTestinstruction}
                                                name="testinstruction" />
                                        </Form> </td> 
                                    </tr>
                                    <tr>
                                    {this.state.maincategory!=="Full Length Test" &&
                                    <th scope="row">Duration:</th>}
                                    {this.state.maincategory!=="Full Length Test" &&
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Duration of test" list="duration" 
                                            name = "duration"
                                            onChange={this.handleChange}/>
                                        <datalist id="duration">
                                        <option value="1 min"/>
                                        <option value="30 mins"/>
                                        <option value="45 mins"/>
                                        <option value ="60 mins"/>
                                        <option value = "180 mins"/>
                                    </datalist>
                                    </InputGroup></td>}
                                    {this.state.showtotalmarks === true && this.state.maincategory!=="Full Length Test" &&
                                    <th scope="row">Total Marks:</th>}
                                    {this.state.showtotalmarks === true && this.state.maincategory!=="Full Length Test" &&
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Total Marks" list="marks" 
                                            name = "totalmarks"
                                            onChange={this.handleChange}/>
                                    <datalist id="marks">
                                        <option value="10"/>
                                        <option value="20"/>
                                        <option value="30"/>
                                        <option value="40"/>
                                        <option value ="120"/>
                                    </datalist>
                                    </InputGroup></td>}
                                    </tr>
                                    {this.state.maincategory==="Full Length Test" &&
                                    <tr>
                                        <th scope="row">Reading Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "readduration"
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                        <th scope="row">Listening Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "listenduration"
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                    </tr>}
                                    {this.state.maincategory==="Full Length Test" &&
                                    <tr>
                                        <th scope="row">Writing Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "writeduration"
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                        <th scope="row">Speaking Duration:</th>
                                        <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Duration of test" list="duration" 
                                                name = "speakduration"
                                                onChange={this.handleChange}/>
                                            <datalist id="duration">
                                            <option value="1 min"/>
                                            <option value="30 mins"/>
                                            <option value="45 mins"/>
                                            <option value ="60 mins"/>
                                            <option value = "180 mins"/>
                                        </datalist>
                                        </InputGroup></td>
                                    </tr>}
                                    <tr>
                                        <th colSpan="5"/>
                                        <td ><Button color="primary" size = "sm" onClick = {this.onNextClicked}>Next</Button></td>
                                    </tr>
                                    </tbody>
                                    
                                    </Table>
                </Card>}
                {!this.state.showquescomp && this.state.showpartcomp && this.renderpartcomp()}
                {this.state.showquescomp && !this.state.showpartcomp && this.renderquescomp()}
            </>
        );
    }

}