import React from "react";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'

import { NavLink as NavLinkRRD, Link } from "react-router-dom";

class InstituteRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            institute :this.props.institute,
        }

    }

    deleteInstitute(){

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete the Institute?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                        axios.post("https://api.testacademia.com/admin/institute/deleteinstitute/"+this.state.institute._id)
                    .then(res=>{
                        console.log(res.data);
                        alert(res.data)
                    }).catch(err=>{
                        console.log(err)
                    })
            }
              },
              {
                label: 'No'
              }
            ]
          });
    
        
    }


    render(){
        return(
            <>
                <tr>
                            <th scope="row">{this.state.institute.institutename}</th>
                            
                            <Link to={{
                                pathname:"/admin/editinstitute/"+this.state.institute._id,  
                                state:{
                                        institute: this.state.institute
                                    }}}>
                              <td className="text-red">
                                <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                                  Edit
                                </td>
                            </Link>
                            <td className="text-red"  onClick={() => this.deleteInstitute()}>
                                <i className="ni ni-basket text-red" style={{marginRight:"5px"}}/>{" "}
                                Delete
                            </td>
                          </tr>
            </>
        )
    }
}

export default InstituteRow;