import React from "react";

import {
    Input,
    InputGroup,
    Button
} from "reactstrap";

class MatchingInput extends React.Component{
    constructor(props){
        super(props);

        this.state ={
            matchingnum:this.props.matchingnum,
            option:"",
            showinput:true
        }

        this.handleChange = this.handleChange.bind(this);
        this.onOptionSubmit = this.onOptionSubmit.bind(this);
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    onOptionSubmit(){
        this.setState({
            showinput:false
        });
        this.props.receiveoption(this.state.option);
    }

    render(){
        return(
            <>
            {this.state.showinput && 
                <tr>
                    <th scope="row">Option {this.state.matchingnum}:</th>
                    <td><InputGroup className="input-group-alternative">
                            <Input placeholder="Enter Options"
                                    type="text" 
                                    onChange={this.handleChange}
                                    name ="option"/>
                        </InputGroup>
                    </td>
                    <td>
                        <Button color="primary" size = "sm" onClick={this.onOptionSubmit}>Submit Option</Button>
                    </td>
                </tr>
            }
            </>
        )
    }
}

export default MatchingInput;