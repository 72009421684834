import React from "react";
import { Link } from "react-router-dom";
// reactstrap components

import { Button } from "reactstrap";
// import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                      <Link to="/admin/addStudent">
                      <Button className="ml-4 d-none d-lg-block" color="secondary" type="button">
                        Add student
                      </Button>
                      </Link>
                    <Link to="/admin/addvideo">
                      <Button className="ml-4 d-none d-lg-block" color="secondary" type="button">
                        Add Course
                      </Button>
                      </Link>
                      <Link to="/admin/addTest">
                      <Button className="ml-4 d-none d-lg-block" color="secondary" type="button">
                        Add Test
                      </Button>
                      </Link>
                      <Link to="/admin/addblog">
                      <Button className="ml-4 d-none d-lg-block" color="secondary" type="button">
                        Add Blog
                      </Button>
                      </Link>
                      <Link to="/admin/addpackage">
                      <Button className="ml-4 d-none d-lg-block" color="secondary" type="button">
                        Add Package
                      </Button>
                      </Link>
                      <Link to="/admin/addinstitute">
                      <Button className="ml-4 d-none d-lg-block" color="secondary" type="button">
                        Add Institute
                      </Button>
                      </Link>
                  </Media>
                </DropdownToggle>
               
                
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
