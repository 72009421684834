import React from "react";
import axios from 'axios';
// import { Button } from "reactstrap";
 import { NavLink as NavLinkRRD, Link } from "react-router-dom";
 import Loader from 'react-loader-spinner'

import {
  Table,
  Row,
  Col
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import InstituteRow from "./InstituteRow.jsx"

class Institute extends React.Component {

  constructor(props){
    super(props);
    this.state = {institutes: [],
    isLoading:true};
  }

  componentDidMount(){
      axios.post('https://api.testacademia.com/admin/institute/showallinst')
          .then(res => {
              this.setState({institutes: res.data},()=>{
                this.setState({isLoading:false})});
          })
          .catch(function(error){
              console.log(error);
          });
  }

  componentDidUpdate(){
      axios.post('https://api.testacademia.com/admin/institute/showallinst')
          .then(res => {
              this.setState({institutes: res.data});
          },()=>{
            this.setState({isLoading:false})})
          .catch(function(error){
              console.log(error);
          });
  }

  instituteList(){
      return this.state.institutes.map(function(currentInstitute, i){
          return <InstituteRow institute={currentInstitute} key={i} />;
      });
  }
  
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"		
				/>}
			{!this.state.isLoading &&
        <Col>
        <Row style={{"marginTop":"30px", "marginBottom":"30px","marginLeft":"15px","marginRight":"15px"}}>
        <Col className="mb-5 mb-xl-0" xl="8">
        <Table className="align-items-center table-flush" >
                            <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Institute Name</th>

                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.instituteList()}
                            </tbody>
                            </Table>
            </Col>
            </Row>
            </Col>
  }  
      </>
    );
  }
}

export default Institute;
