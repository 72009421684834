import React from "react";
// node.js library that concatenates classes (strings)
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Input, Select, Button, Checkbox } from 'antd';
import axios from 'axios';
import Loader from 'react-loader-spinner'
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import {LoadPackagelist} from "../package/LoadPackageListfn"
import UserHeader from "components/Headers/UserHeader.jsx";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export default class AddStudents extends React.Component {

  constructor(props){
    super(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeCourse = this.onChangeCourse.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadpackagelist = this.loadpackagelist.bind(this);
    this.state={
      institutename:'',
      name: '',
      mobile:'',
      email:'',
      password:'',
      course:'',
      userType:'',
      startDate:new Date(),
      endDate:new Date(),
      packages:[],
      isLoading:false
    }
  }
  
  onChangeName(e){
    this.setState({
        name: e.target.value
    });
  }

  onChangeMobile(e){
    if(!isNaN(e.target.value)){
      this.setState({
          mobile: e.target.value
      })}
  }

  onChangeEmail(e){
    this.setState({
        email: e.target.value
    });
  }

  onChangePassword(e){
    this.setState({
        password: e.target.value
    });
  }

  async onChangeCourse (evt) {
    this.setState({ [evt.target.name]: evt.target.value });
    if(evt.target.name==="course"){
        var pack = await LoadPackagelist(evt.target.value)
        this.setState({
          packages: pack
        })
    }
  }

  onChangeStartDate = date =>{
    this.setState({
        startDate: date
    },()=>{this.onChangeEndDate()});
  }

  onChangeEndDate() {
    var validity = 0;
    for(var i=0;i<this.state.packages.length;i++){
      if(this.state.packages[i]._id===this.state.userType){
          validity = this.state.packages[i].validity;
      }
    }
    this.setState({
        endDate:  new Date(this.state.startDate.valueOf() + (validity*86400000))
    },()=>{console.log(this.state.endDate)});
  }

  loadpackagelist(){
    const packagelist = [];
    const ctx = this;
      if(this.state.packages){
          this.state.packages.map(function(pack,i){
              packagelist.push(
                  <td>
                      <input type="checkbox" 
                          name="premium" 
                          
                          value={pack._id}
                          onChange={(evt)=>{ctx.onChangeType(evt,pack)}}/>
                          {pack.packagename}
                  </td>
              )
          })
          return packagelist
      }
}


onChangeType(evt, pack){
  this.setState({
          userType:pack._id
      },()=>{console.log(this.state.userType);
              var validity = pack.validity;
              this.setState({
                  endDate: new Date(this.state.startDate.valueOf() + (validity*86400000))
              },()=>{console.log(this.state.endDate)});    
      })
}

  onSubmit(e){
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    if(this.state.course==="IELTS"){
      axios.post('https://api.testacademia.com/admin/student/addstudent', this.state)
            .then(res => {console.log(res.data)
                  alert(res.data)
                })
            .catch(e=>{
              alert("Fill all the details")
            })
    this.setState({
      name: '',
      mobile:'',
      email:'',
      password:'',
      course:'',
      userType:'',
      startDate:new Date(),
      endDate:new Date(),
      isLoading:false
      })
    }
    else {
      var name = this.state.course.toLowerCase().replace(" ","","g");
      axios.post('https://api.testacademia.com/admin/'+name+'/student/addstudent', this.state)
            .then(res => {console.log(res.data)
                  alert(res.data)})
            .catch(e=>{
              alert("Fill all the details")
            })
    this.setState({
      name: '',
      mobile:'',
      email:'',
      password:'',
      course:null,
      userType:'',
      startDate:new Date(),
      endDate:new Date(),
      isLoading:false
      })
    }
    }
    
    render() {
    return (
      <>
      <UserHeader />
      {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
      <Col lg="8" md="7">
      <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
            <form onSubmit={this.onSubmit}>
              <Form
              {...formItemLayout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                >
                 <FormGroup>
                   <Form.Item
                   label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Student name!',
                      },
                    ]}
                    style={{display:"flex"}}
                  >
                    <Input placeholder="Name" 
                      type="text" 
                      value={this.state.name}
                      onChange={this.onChangeName}/>
                  </Form.Item>
                  </FormGroup> 
                  <FormGroup> 
                  <Form.Item
                  label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Phone No!',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || (getFieldValue('mobile').length === 10 && (!isNaN(getFieldValue('mobile'))))) {
                            return Promise.resolve();
                          }
            
                          return Promise.reject('Enter valid phone no');
                        },
                      }),
                    ]}
                    style={{display:"flex"}}
                  >
                    <Input placeholder="Mobile"
                       type="text"
                       value={this.state.mobile}
                        onChange={this.onChangeMobile} />
                  </Form.Item>
                </FormGroup> 
                <FormGroup>
                <Form.Item
                  label="Email Id: "
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                    style={{display:"flex"}}
                  >
                    <Input placeholder="Email" 
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      type="email" />
                  </Form.Item>
                </FormGroup>
                <FormGroup>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback
                    style={{display:"flex"}}
                  >
                    <Input.Password placeholder="Password" 
                      type="text" 
                      value={this.state.password}
                      onChange={this.onChangePassword}/>
                  </Form.Item>
                </FormGroup>
                <FormGroup>
                    <Row> Course:
                    <div className="custom-control custom-radio mb-3">
                      <InputGroup className="input-group-alternative">
                                    <select  id ="course"
                                            list="course" 
                                            name = "course"
                                            value ={this.state.course} 
                                            onChange={this.onChangeCourse}>
                                        <option >Select the course</option>                                             
                                        <option value="IELTS">IELTS</option>
                                        <option value="PTE">PTE</option>
                                        <option value="GRE">GRE</option>
                                        <option value="GMAT">GMAT</option>
                                        <option value="TOEFL">TOEFL</option>
                                        <option value="SAT">SAT</option>
                                        <option value="OET">OET</option>
                                        <option value="EXPERT ENGLISH">EXPERT ENGLISH</option>
                                    </select>
                                    </InputGroup>
                      </div>
                    </Row>
                </FormGroup>
                <FormGroup>
                <Row>
                  {this.loadpackagelist()}
                    </Row>
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                            
                        </InputGroupText>
                        </InputGroupAddon>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.onChangeStartDate}
                            dateFormat = "dd/MM/yyyy"
                        />
                                              
                    </InputGroup>
                </FormGroup>
                {/* <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                        </InputGroupAddon>                      
                          <DatePicker
                            selected={this.state.endDate}
                            onChange={this.onChangeEndDate}
                            dateFormat = "dd/MM/yyyy"
                        />
                    </InputGroup>
                </FormGroup> */}
                
                {/* <div className="form-group"  >
                        <input  type="submit"
                                value="Add Student"
                                className="btn btn-primary"/>
                    </div> */}
                <Form.Item
                  wrapperCol={{
                    span: 12,
                    offset: 6,
                  }}
                >
                  <Button className="btn btn-primary" type="primary" htmlType="submit" onClick={this.onSubmit}>
                    Add Student
                  </Button>
                </Form.Item>
              </Form>
              </form>
            </CardBody>
          </Card>
      </Col>}
      </>
      );
    }
  }
  
  
  