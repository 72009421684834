import React from "react";
import axios from 'axios';
// import { Button } from "reactstrap";
 import { NavLink as NavLinkRRD, Link } from "react-router-dom";
 import Loader from 'react-loader-spinner'
 import classnames from "classnames";

import {
  Table,
  Row,
  Col,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import PackageRow from "./PackageRow.jsx";
import {LoadPackagelist} from "./LoadPackageListfn"
class Package extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      packages: [],
      isLoading:true,
      tabs:1
    };
    this.packageList = this.packageList.bind(this);
    this.loadlist = this.loadlist.bind(this);
    }

  toggleNavs = (e, state, index,course) => {
    e.preventDefault();
    this.setState({
      [state]: index,
      isLoading:true
    },()=>{
      this.loadlist(course)
    });
  };

  async loadlist(course){
    var pack = await LoadPackagelist(course)
    this.setState({
      packages: pack
    },()=>{
      console.log(this.state.packages);
      this.setState({isLoading:false})
    })
  }
  componentDidMount(){
    axios.post('https://api.testacademia.com/admin/package/showallpack')
          .then(res => {
              this.setState({
                packages: res.data
              },()=>{
                console.log(this.state.packages)
                  this.setState({isLoading:false})
                });
          })
          .catch(function(error){
              console.log(error);
          });
  }

 packageList(){
    var list=[];
    this.state.packages.map(function(currentPackage, i){
          list.push(<PackageRow package={currentPackage} key={i} />);
      });
      return list;
  }
  
  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      <Nav
        className="nav-fill flex-column flex-md-row"
        id="tabs-icons-text"
        pills
        role="tablist"
        style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
      >
        <NavLink
            aria-selected={this.state.tabs === 1}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 1
            })}
            onClick={e => this.toggleNavs(e, "tabs", 1,"IELTS")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px",  "width":"150px", height:"40px"}}
          >
            <center>IELTS</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 2}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 2
            })}
            onClick={e => this.toggleNavs(e, "tabs", 2,"PTE")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>PTE</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 3}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 3
            })}
            onClick={e => this.toggleNavs(e, "tabs", 3,"GRE")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>GRE</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 4}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 4
            })}
            onClick={e => this.toggleNavs(e, "tabs", 4,"GMAT")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>GMAT</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 5}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 5
            })}
            onClick={e => this.toggleNavs(e, "tabs", 5,"TOEFL")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"100px", height:"40px"}}
          >
            <center>TOEFL</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 6}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 6
            })}
            onClick={e => this.toggleNavs(e, "tabs", 6,"SAT")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"100px", height:"40px"}}
          >
            <center>SAT</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 7}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 7
            })}
            onClick={e => this.toggleNavs(e, "tabs", 7,"OET")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"100px", height:"40px"}}
          >
            <center>OET</center>
          </NavLink>
          <NavLink
            aria-selected={this.state.tabs === 8}
            className={classnames("mb-sm-2 mb-md-2", {
              active: this.state.tabs === 8
            })}
            onClick={e => this.toggleNavs(e, "tabs", 8,"EXPERT ENGLISH")}
            href="#pablo"
            role="tab"
            style={{"marginRight":"20px", "width":"150px", height:"40px"}}
          >
            <center>EXPERT ENGLISH</center>
          </NavLink>
          </Nav>
      {!this.state.isLoading &&
        <Col>
        <Row style={{"marginTop":"30px", "marginBottom":"30px","marginLeft":"15px","marginRight":"15px"}}>
        <Col className="mb-5 mb-xl-0" xl="8">        
          <TabContent activeTab={"tabs" + this.state.tabs}>                  
              <TabPane tabId="tabs1">
                <Table className="align-items-center table-flush">
                <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                  <tbody>
                    {this.packageList()}
                  </tbody>
                </Table>
                </TabPane>
                  <TabPane tabId="tabs2">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs3">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs4">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs5">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs6">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs7">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                  <TabPane tabId="tabs8">
                  <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                                <tr>
                                <th scope="col-md-3">Package</th>
                                <th scope="col-md-3">Price</th>
                                <th scope="col-md-3">Validity</th>
                                <th scope="col-md-3">View</th>
                                <th scope="col-md-3">Edit</th>
                                </tr>
                            </thead>
                    <tbody>
                      {this.packageList()}
                    </tbody>
                  </Table>
                  </TabPane>
                </TabContent>
            </Col>
            </Row>
            </Col>
  }   
      </>
    );
  }
}

export default Package;
