import React from "react";

import axios from 'axios';
import HeadingInput from './HeadingInput';
import MatchingInput from './MatchingInput';

import UserHeader from "components/Headers/UserHeader.jsx";

import {
    Button,
    Card,
    Form,
    Input,
    InputGroup,
    Table,
    
  } from "reactstrap";


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import LabelInputs from "./LabelInputs";

let imgTag = null;
class EditPartDetails extends React.Component{

    constructor(props){
        super(props);

        this.state={
            testid:this.props.location.state.testid,
            id:this.props.location.state.part._id,
            startques:this.props.location.state.part.startques,
            endques:this.props.location.state.part.endques,
            parttype:this.props.location.state.part.parttype,
            partcategory:this.props.location.state.part.partcategory,
            partinstruction:this.props.location.state.part.partinstruction,
            ispara:this.props.location.state.part.ispara,
            passage:this.props.location.state.part.passage,
            audio:this.props.location.state.part.audio,
            image: this.props.location.state.part.image,
            questions:this.props.location.state.part.questions,
            startoption:this.props.location.state.part.startoption,
            endoption:this.props.location.state.part.endoption,
            noofheadings:this.props.location.state.part.noofheadings,
            queslabel:this.props.location.state.part.queslabel,
            optionlabel:this.props.location.state.part.optionlabel,
            noofoptions:this.props.location.state.part.noofoptions,
            listofheadings:[],
            listofoptions:[],
            cuetimer:this.props.location.state.part.cuetimer,
            singlepartques:this.props.location.state.part.singlepartques,
            
            labelinput:this.props.location.state.part.labelinput,

            coordinates:this.props.location.state.part.coordinates,
            showmatrix:false,
            showheadings:false,
            showmatch:false,

            showheadinginput:false,
            showmatchinginput:false,

            success:false,
            successimg:false,
            error:false,
            errormessage:"",

            viewheadings:this.props.location.state.part.listofheadings,
            viewoptions :this.props.location.state.part.listofoptions
        }

        this.onSubmitClicked = this.onSubmitClicked.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleChangeQuestion=this.handleChangeQuestion.bind(this);
        this.handleaudiofile = this.handleaudiofile.bind(this);
        this.onChangeImage = this.onChangeImage.bind(this);

        this.renderheadinginput = this.renderheadinginput.bind(this);
        this.rendermatchinginput = this.rendermatchinginput.bind(this);

        this.onaddheadings = this.onaddheadings.bind(this);
        this.onaddoptions = this.onaddoptions.bind(this);

        this.receiveheading = this.receiveheading.bind(this);
        this.receiveoption = this.receiveoption.bind(this);

        this.handleChangePassage = this.handleChangePassage.bind(this);
        this.handleChangePartinstruction = this.handleChangePartinstruction.bind(this);

        this._onMouseMove = this._onMouseMove.bind(this);
        this.removeinput = this.removeinput.bind(this);
        this.renderlabelinput = this.renderlabelinput.bind(this);
        
    }

    onaddheadings(){
        this.setState({
            showheadinginput:true
        })
    }

    handleChangeQuestion(value){
        this.setState({
            singlepartques:value
        })
    }

    onaddoptions(){
        this.setState({
            showmatchinginput:true
        })
    }

    onSubmitClicked(){
        if((this.state.parttype === "Matching" ||
        this.state.parttype === "Single Part Multiple Question"
         )&& this.state.listofoptions.length === 0){
            alert("Please submit options")
        }
        else if((this.state.parttype === "List of Headings" 
        || this.state.parttype === "Paragraph Headings" || 
        this.state.parttype === "Label Headings")
         && this.state.listofheadings.length === 0){
            alert("Please submit headings")
        }
        else{
            console.log(this.state.testid)

            var paragraph = this.state.passage;
            var re = new RegExp("###", 'g');
            paragraph = paragraph.replace(re,"<input type='text'/>");
            this.setState({
                passage:paragraph
            },()=>{
                axios.post('https://api.testacademia.com/admin/test/editpart/'+this.state.id, this.state)
                .then(res => {
                    console.log(res.data);
                    alert("Part Details Updated")
                });
            });
        }
    }
    
    handleChangePassage(value){
        this.setState({
            passage:value
        })
    }

    handleChangePartinstruction(value){
        this.setState({
            partinstruction:value
        })
    }

    handleChange (evt) {
    if(evt.target.name === "ispara"){
        if(evt.target.checked === true){
            this.setState({
                ispara:"yes"
            })
        }
        else{
            this.setState({
                ispara:"no"
            })
        }
    }
    else{
        this.setState(
            { 
                [evt.target.name]: evt.target.value 
            }, () =>{
                
                if(this.state.parttype === "Matrix"){
                    this.setState({
                        showmatrix: true,
                        showheadings:false,
                        showmatch:false
                    })
                }
                else if(this.state.parttype === "List of Headings"){
                    this.setState({
                        showmatrix:false,
                        showheadings:true,
                        showmatch:false
                    })
                }
                else if(this.state.parttype === "Matching"){
                    this.setState({
                        showmatrix: false,
                        showheadings:false,
                        showmatch:true
                    })
                }

                if(this.state.noofheadings>0){
                    this.onaddheadings()
                }

                if(this.state.noofoptions>0){
                    this.onaddoptions()
                }
            }
        );   
        }    
    }
    
    receiveheading(heading,headingnum){
        var headings = this.state.listofheadings;
        headings.push(heading)
        this.setState({
            listofheadings:headings
        }, () =>{
            console.log(this.state.listofheadings);
        })
    }

    receiveoption(option,optionnum){
        var options = this.state.listofoptions;
        options.push(option);
        this.setState({
            listofoptions:options
        }, () =>{
            console.log(this.state.listofoptions);
        })
    }

    renderheadinginput(){
        const InputHeadingRows = [];
        var i;
        for (i=0; i<this.state.noofheadings; i++){
            
            InputHeadingRows.push(<HeadingInput 
                                    headingnum={i+1} 
                                    heading={this.state.viewheadings[i]} 
                                    receiveheading={this.receiveheading}/>);
        }
        return InputHeadingRows;
    }

    rendermatchinginput(){
        const InputMatchingRows = [];
        var i;
        for (i=0; i<this.state.noofoptions; i++){
            InputMatchingRows.push(<MatchingInput 
                                        matchingnum={i+1} 
                                        option={this.state.viewoptions[i]} 
                                        receiveoption={this.receiveoption}/>);
        }
        return InputMatchingRows;
    }
    
    handleaudiofile(ev){
            this.setState({
                success: false, audio : "",audio:ev.target.value
            }); 
            let file = ev.target.files[0];
            
            // Split the filename to get the name and type
            let fileParts = ev.target.files[0].name.split('.');
            let fileName = fileParts[0];
            let fileType = fileParts[1];
            axios.post("https://api.testacademia.com/admin/audio/sign_s3",{
              fileName : fileName,
              fileType : fileType
            })
            .then(response => {
              var returnData = response.data.data.returnData;
              var signedRequest = returnData.signedRequest;
              var url = returnData.url;
              this.setState({audio: url,
                })
              var options = {
                headers: {
                  'Content-Type': fileType,
                  'x-amz-acl': 'public-read'
                }
              };
              axios.put(signedRequest,file,options)
              .then(result => {
                this.setState({success: true});
                alert("the audio is uploaded");
              })
              .catch(error => {
                alert("ERROR " + JSON.stringify(error));
              })
            })
            .catch(error => {
              alert(JSON.stringify(error));
            })
        }
        
        onChangeImage(ev){
            this.setState({
                successimg: false, image : "",image:ev.target.value
            }); 
            let file = ev.target.files[0];
            
            // Split the filename to get the name and type
            let fileParts = ev.target.files[0].name.split('.');
            let fileName = fileParts[0];
            let fileType = fileParts[1];
            axios.post("https://api.testacademia.com/admin/audio/sign_s3",{
              fileName : fileName,
              fileType : fileType
            })
            .then(response => {
              var returnData = response.data.data.returnData;
              var signedRequest = returnData.signedRequest;
              var url = returnData.url;
              this.setState({image: url,
                })
              var options = {
                headers: {
                  'Content-Type': fileType,
                  'x-amz-acl': 'public-read'
                }
              };
              axios.put(signedRequest,file,options)
              .then(result => {
                this.setState({successimg: true});
              })
              .catch(error => {
                alert("ERROR " + JSON.stringify(error));
              })
            })
            .catch(error => {
              alert(JSON.stringify(error));
            })
        }  

        _onMouseMove(e) {
            var coordinate = {
                x: e.nativeEvent.offsetX,
                y: e.nativeEvent.offsetY
            }
            this.setState({
                coordinates: [...this.state.coordinates,coordinate]
            })
        }
    
        removeinput(xvalue,yvalue){
            const index = this.state.coordinates.findIndex(coord => (coord.x===xvalue && coord.y===yvalue));
            let coordinates = this.state.coordinates;
            console.log(coordinates);
            if(index!==-1){
                coordinates.splice(index,1);
                this.setState({
                    coordinates:coordinates
                },()=>{})
            }
        }
    
        renderlabelinput(){
            var inputrows = [];
            for(let i=0; i<this.state.coordinates.length; i++){
                inputrows.push(<LabelInputs 
                                    key ={i}
                                    xvalue={this.state.coordinates[i].x} 
                                    yvalue={this.state.coordinates[i].y}
                                    removeinput={this.removeinput}/>)
            }
            console.log(inputrows[0])
            return inputrows;
        }

    render(){

        const SuccessMessage = () => (
            <div className="text-green">
                <i className="fas fa-check-circle text-green" style={{marginRight:"30px"}}/>{" "} 
            </div>
          )
          const ErrorMessage = () => (
            <div >
              <i className="fas fa-times-circle text-red">{" "}</i>
              
            </div>
         )

        return(
            <>
                <UserHeader/>
                <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                                    
                                    <tbody>
                                    <tr>
                                    <th scope="row">Part Type:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Select Part Type" type="text"
                                            list="part"
                                            name="parttype"
                                            value={this.state.parttype}
                                            onChange={this.handleChange}/>
                                        <datalist id="part">
                                            <option value="MCQ Single Right"/>
                                            <option value="MCQ Multipe Right"/>
                                            <option value="Single Part Multiple Question"/>
                                            <option value="Fill Blanks"/>
                                            <option value="Matrix"/>
                                            <option value="List of Headings"/>
                                            <option value="Matching"/>
                                            <option value="Writing"/>
                                            <option value="Speaking"/>
                                            <option value="Cue Card Speaking"/>
                                            <option value="Paragraph Headings"/>
                                            <option value="Label Blanks"/>
                                            <option value="Label Headings"/>
                                        </datalist>
                                    </InputGroup></td>
                                    
                                    <th scope="row">Start Question:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Select Start Question"
                                                type="Number" 
                                                value={this.state.startques}
                                                onChange={this.handleChange}
                                                name ="startques"/>
                                    </InputGroup></td>

                                    <th scope="row">End Question:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Select End Question" 
                                                type="Number" 
                                                value={this.state.endques}
                                                onChange={this.handleChange}
                                                name="endques"/>
                                    </InputGroup></td>
                                   
                                    </tr>
                                    <tr>
                                    <th scope="row">Part Category:</th>
                                    <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Select Part Category" type="text"
                                            list="category"
                                            name="partcategory"
                                            value={this.state.partcategory}
                                            onChange={this.handleChange}/>
                                        <datalist id="category">
                                            <option value="Reading"/>
                                            <option value="Listening"/>
                                            <option value="Writing"/>
                                            <option value="Speaking"/>
                                        </datalist>
                                    </InputGroup></td>
                                    <th>Is question of paragraph type?</th>
                                        <td><input
                                            name="ispara"
                                            type="checkbox"
                                            defaultChecked = {this.state.ispara==="yes"}
                                            onChange={this.handleChange}
                                        /></td>
                                    </tr>
                                    <tr> 
                                    <th scope="row ">Part Instruction:</th>
                                    <td colSpan="5">
                                    <Form>
                                            <ReactQuill value={this.state.partinstruction || ''}
                                                onChange={this.handleChangePartinstruction}
                                                name="partinstruction" />
                                        </Form> 
                                        </td> 
                                        
                                    </tr>
                                    <tr> 
                                    <th scope="row ">Passage:</th>
                                    <td colSpan="5">
                                        <Form>
                                            <ReactQuill value={this.state.passage}
                                                onChange={this.handleChangePassage}
                                                name="passage" />
                                        </Form>    
                                    </td> 
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Audio File:</th>
                                    <td colSpan="4">
                                        <input  type="file"
                                        name ="audio"
                                        onChange={this.handleaudiofile}/>
                                    </td>
                                    <td colSpan="1">{(this.state.success || this.state.audio ? <SuccessMessage/> : <ErrorMessage/>)}</td>
                                    
                                    </tr>
                                    <tr><th scope="row">Image File:</th>
                                    <td colSpan="4">
                                        <input
                                        id={this.state.id}
                                        type="file"
                                        onChange={this.onChangeImage}
                                        className="show-for-sr" />
                                    </td>
                                    <td colSpan="1">{(this.state.successimg || this.state.image ? <SuccessMessage/> : <ErrorMessage/>)}</td>
                                    </tr>
                                    {(this.state.parttype==="Single Part Multiple Question"&&
                                        this.state.ispara==="yes")&&
                                        <tr><th>Question:</th>
                                            <td colSpan="5"><Form>
                                            <Input
                                            name="singlepartques"
                                            placeholder="Write your question here ..."
                                            type="textarea"
                                            value={this.state.singlepartques}
                                            onChange={this.handleChange}
                                        />
                                            </Form>
                                        </td>
                                    </tr>}
                                    {(this.state.parttype==="Single Part Multiple Question"&&
                                        this.state.ispara!=="yes")&&
                                        <tr><th>Question:</th>
                                            <td colSpan="5">
                                            <Form>
                                                <ReactQuill value={this.state.singlepartques}
                                                    onChange={this.handleChangeQuestion}
                                                    name="singlepartques" />
                                            </Form>
                                        </td>
                                    </tr>}
                                    {(this.state.parttype==="Label Blanks"||
                                    this.state.parttype==="Label Headings") &&
                                    <tr>
                                        <th>Selected Image:</th>
                                        <td style={{height:"600px"}}>
                                            <div className="labelimage-container">
                                                <img className="labelimage" 
                                                    src={this.state.image}
                                                    onError={(e)=>{e.target.onerror = null; e.target.src=this.state.image}}
                                                    alt="selected"
                                                    onMouseDown={this._onMouseMove.bind(this)}/>
                                                
                                                <div className="inputcontainer">
                                                    {this.renderlabelinput()}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>}
                                    {(this.state.parttype==="Matrix")&&
                                    <tr>
                                    <th scope="row">Start Option:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Select Start Option"
                                                type="text" 
                                                value={this.state.startoption}
                                                onChange={this.handleChange}
                                                name ="startoption"/>
                                    </InputGroup></td>

                                    <th scope="row">End Option:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Select End Option" 
                                                type="text" 
                                                value={this.state.endoption}
                                                onChange={this.handleChange}
                                                name="endoption"/>
                                    </InputGroup></td>
                                    </tr>
                                     }
                                     {(this.state.parttype==="List of Headings" ||
                                        this.state.parttype==="Paragraph Headings"||
                                        this.state.parttype==="Label Headings") &&
                                    <tr>
                                    <th scope="row">No Of Headings:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Number Of Headings"
                                                type="Number" 
                                                value={this.state.noofheadings}
                                                onChange={this.handleChange}
                                                name ="noofheadings"/>
                                    </InputGroup></td>
                                    </tr>}
                                    {(this.state.parttype==="List of Headings" ||
                                        this.state.parttype==="Paragraph Headings"||
                                        this.state.parttype==="Label Headings") && 
                                         this.renderheadinginput() }
                                    {this.state.parttype==="Matching" &&
                                    <tr>
                                    {/* Matching -> questionlabel, optionlabel, noofoptions, listofoptions */}
                                    <th scope="row">Question Label:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Question Label"
                                                type="text" 
                                                value={this.state.queslabel}
                                                onChange={this.handleChange}
                                                name ="queslabel"/>
                                    </InputGroup></td>
                                    <th scope="row">Option Label:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Option Label"
                                                type="text" 
                                                value={this.state.optionlabel}
                                                onChange={this.handleChange}
                                                name ="optionlabel"/>
                                    </InputGroup></td>
                                    <th scope="row">No Of Options:</th>
                                    <td><InputGroup className="input-group-alternative">
                                        <Input placeholder="Number Of Options"
                                                type="Number" 
                                                value={this.state.noofoptions}
                                                onChange={this.handleChange}
                                                name ="noofoptions"/>
                                    </InputGroup></td>
                                    </tr>  
                                     }
                                     {this.state.parttype==="Single Part Multiple Question" &&
                                        <tr><th scope="row">No Of Options:</th>
                                        <td><InputGroup className="input-group-alternative">
                                            <Input placeholder="Number Of Options"
                                                    type="Number" 
                                                    value={this.state.noofoptions}
                                                    onChange={this.handleChange}
                                                    name ="noofoptions"/>
                                        </InputGroup></td>
                                        </tr>  
                                     }
                                    {(this.state.parttype==="Matching"|| this.state.parttype==="Single Part Multiple Question") && this.rendermatchinginput() }
                                    {this.state.parttype==="Cue Card Speaking"&&
                                    <tr>
                                        <th>Cue Card Timer:</th>
                                        <td><InputGroup className="input-group-alternative">
                                    <Input placeholder="Cue card timer" list="cuetimer" 
                                            name = "cuetimer"
                                            value = {this.state.cuetimer}
                                            onChange={this.handleChange}/>
                                        <datalist id="cuetimer">
                                        <option value="1 min"/>
                                        <option value="2 mins"/>
                                        <option value="3 mins"/>
                                        <option value ="4 mins"/>
                                        <option value = "5 mins"/>
                                    </datalist>
                                    </InputGroup></td></tr>
                                    }
                                    <tr>
                                        <th colSpan="5"/>
                                        <td ><Button color="primary" size = "sm" onClick={this.onSubmitClicked}>Submit</Button></td>
                                    </tr>                              
                                    </tbody>
                                    
                                    </Table>
                </Card>
            </>
        )
    }
}

export default EditPartDetails;