import React from "react";
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class LabelInput extends React.Component{

    constructor(props){
        super(props);

        this.state={
            quesnum:this.props.quesnum,
            xvalue:this.props.xvalue,
            yvalue:this.props.yvalue,
            result:this.props.results[this.props.resultindex],
            useranswer:this.props.results[this.props.resultindex].useranswer
        }
        this.handlechange = this.handlechange.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount(){
        console.log(this.props.results)
    }

    componentDidUpdate(prevProps){
        if(this.props.quesnum!==prevProps.quesnum){
            this.setState({
                quesnum:this.props.quesnum,
                xvalue:this.props.xvalue,
                yvalue:this.props.yvalue,
                result:this.props.results[this.props.resultindex],
                useranswer:this.props.results[this.props.resultindex].useranswer
            })
        }
    }

    handlechange(evt){
        if(this.props.parttype!=="Label Headings"){
            this.setState({
                useranswer: evt.target.value
            },()=>{
                //this.props.sendResults(this.state.quesnum,this.state.result.rightanswer,this.state.useranswer);
                var results = this.props.results;
                var quesresult = results[this.props.resultindex];
                if(quesresult.rightanswer.includes(this.state.useranswer)){
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=1;
                }else{
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=0;
                }
                results[this.props.resultindex] = quesresult;
                this.props.updateResult(results);
            })
        }
    }

    onDrop(data){
        this.setState({
            useranswer:data.dragData
        },()=>{
            //this.props.sendResults(this.state.quesnum, this.state.result.rightanswer, this.state.useranswer);
            var results = this.props.results;
            var quesresult = results[this.props.resultindex];
            if(quesresult.rightanswer.includes(this.state.useranswer)){
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=1;
            }else{
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=0;
            }
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })
    }

    render(){
        return(
            <DropTarget targetKey="headings" onHit={this.onDrop}>
                <input
                    className="labelinput" 
                    type="text" 
                    placeholder={this.state.quesnum}
                    value={this.state.useranswer}
                    onChange={this.handlechange}
                    style={{position:"absolute",
                    left:this.state.xvalue, 
                    top:this.state.yvalue}}/>
            </DropTarget>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(LabelInput);