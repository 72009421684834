import React from 'react';

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

const Option = props =>(
    <div className="custom-radio">
        <input
            style={{margin:"10px",display:"inline"}}
            type="checkbox"
            value={props.option}
            name={props.quenum}
            onChange={(evt) => {props.handlechange(evt,props.option)}}
        />
        <label style={{display:"inline"}} >
            {props.option}
        </label>
    </div>
)
class SinglePartMultiQues extends React.Component{

    constructor(props){
        super(props)
        this.state={
            questions:this.props.part.singlepartques,
            quesdata:this.props.quesdata,
            questionnum:this.props.currentques,
            results:this.props.results,
            useranswer:[]
        }
        this.checkbox = React.createRef();
        this.renderquestion = this.renderquestion.bind(this);
        this.renderoptions = this.renderoptions.bind(this);
        this.handlechange = this.handlechange.bind(this);

    }

    componentDidUpdate(prevProps){
        if(prevProps.part.singlepartques !== this.props.part.singlepartques 
            ||prevProps.quesdata !== this.props.quesdata
            || prevProps.currentques !== this.props.currentques) {
            this.setState({
                questions: this.props.part.singlepartques,
                quesdata:this.props.quesdata,
                results: this.props.results,
                questionnum:this.props.currentques,
            });
          }
    }

    handlechange(evt, option){
        if(evt.target.checked){
            var answervalue = evt.target.value;
            for(let i=this.props.part.startques-1,j=0; i<this.props.part.endques; i++,j++){
                var results = this.props.results;
                var quesresult = results[i];
                if(quesresult.rightanswer.includes(answervalue) && quesresult.parttype===this.props.part.parttype){
                    quesresult.useranswer = answervalue;
                    quesresult.score=1;
                }
                results[i] = quesresult;
                this.props.updateResult(results); 
            }
        }
        else if(!evt.target.checked){
            for(let i=this.props.part.startques-1,j=0; i<this.props.part.endques; i++,j++){
                var results = this.props.results;
                var quesresult = results[i];
                if(quesresult.rightanswer.includes(option) && quesresult.parttype===this.props.part.parttype){
                    quesresult.useranswer = null;
                    quesresult.score=null;
                }
                results[i] = quesresult;
                this.props.updateResult(results);
            }
        }
    }
    renderquestion(){
        return (<p className="mcq-question-active">
                    <div className="mcq-question-html" dangerouslySetInnerHTML={{ __html: this.state.questions}}/>
                </p>)
    }

    renderoptions(){
        const optionrows = [];
        for(var i=0;i<this.props.part.listofoptions.length;i++){
            optionrows.push(<Option 
                quenum={this.state.questionnum} 
                option={this.props.part.listofoptions[i]} 
                handlechange={this.handlechange} 
                useranswer={this.state.results[this.state.questionnum].useranswer}
                />)
        }
        return optionrows;
    }


    render(){
        return(
            <>
            <div className={"mcq-ques_"+this.props.setting}>
                {this.renderquestion()}
                {this.renderoptions()}
            </div>
            </>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(SinglePartMultiQues);