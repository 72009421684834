import React from "react";
import axios from "axios";

import { confirmAlert } from 'react-confirm-alert'; // Import
import UserHeader from "components/Headers/UserHeader.jsx";
import LoadingPage from "../LoadingPage";
import "./edittest.css";
import PartDetails from "./PartDetails";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import EditPartDetails from "./EditPartDetails";
import EditQuesDetails from "./EditQuesDetails";
import Loader from 'react-loader-spinner';
class TestDetails extends React.Component{

    constructor(){
        super();
        this.state={
            test:'',
            parts:'',
            isLoading:true,
            pakagename:[]
        }

        this.loadtestdetails = this.loadtestdetails.bind(this);
        this.loadpartandques = this.loadpartandques.bind(this);
        this.deletepart = this.deletepart.bind(this);
    }

    componentDidMount(){
        
        const id = this.props.match.params.id;
        axios.post('https://api.testacademia.com/admin/test/loadtest/'+id)
        .then(res => {
            const testdata = res.data;
            this.setState({test: testdata}, ()=>{
                for(var i=0;i<this.state.test.premium.length;i++){
                axios.post('https://api.testacademia.com/admin/package/onepackage/'+this.state.test.premium[i])
                .then(response=>{
                    var p = response.data.packagename;
                    var pack = this.state.pakagename;
                    pack.push(p);
                    this.setState({
                        packagename:pack
                    })
                })
                .catch(e=>console.log(e))
             }
                this.loadpartsofquestions(testdata.parts);
            });

        })
        .catch(function(error){
            console.log(error);
        });

    }

    async loadpartsofquestions(parts){
		const ctx = this;
		for (let i=0; i<parts.length; i++){
			const part = parts[i];
			var partdata = this.forloopforques(part);
			this.setdata(partdata);
        }
        this.setState({
            isLoading:false
        },()=>{
            console.log("Loading done!")
        })
    }
    
    async forloopforques(partdata){
		partdata.quesdata = await [];
		for (let j=0; j<partdata.questions.length; j++){
			const question = partdata.questions[j];
            partdata.quesdata.push(question);
		}
		return partdata;
    }
    deletepart(){

        console.log(this.state.testid)

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete the part?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    axios.post("https://api.testacademia.com/admin/test/deletepart/"+this.state.part._id, {testid: this.state.testid})
                    .then(res=>{
                        console.log(res.data);
                        alert(res.data)
                    }).catch(err=>{
                        console.log(err)
                    })
                }
              },
              {
                label: 'No'
              }
            ]
          });
    }

	async setdata(partdata){
		const updatedpartdata = await partdata;
		this.setState({
			parts:[...this.state.parts, updatedpartdata]
		}, () => {
            console.log(this.state.test)
			console.log(this.state.parts)
		})
    }

    loadtestdetails(){
        return(
            <>
                <div className="testable">
                    <table className="edittest">
                        <tr>
                            <th>Test Name:</th>
                            <td>{this.state.test.testname}</td>
                            <th>Main Category</th>
                            <td>{this.state.test.maincategory}</td>
                            <th>Duration:</th>
                            <td>{this.state.test.duration}</td>
                            <th>Total Marks:</th>
                            <td>{this.state.test.totalmarks}</td>
                        </tr>
                        {this.state.test.maincategory==="Full Length Test" && <tr>
                            <th>Listening Duration:</th>
                            <td>{this.state.test.listenduration}</td>
                            <th>Reading Duration:</th>
                            <td>{this.state.test.readduration}</td>
                            <th>Writing Duration:</th>
                            <td>{this.state.test.writeduration}</td>
                            <th>Speaking Duration:</th>
                            <td>{this.state.test.speakduration}</td>
                        </tr>}
                        <tr>
                            <th>Premium:</th>
                            <td colSpan="7">{this.state.packagename?this.state.packagename.toString():''}</td>
                        </tr>
                        <tr>
                            <th>Test Instruction:</th>
                            <td colSpan="7"><div dangerouslySetInnerHTML={{ __html: this.state.test.testinstruction}} 
                            style={{width:"700px", wordWrap:"break-word"}} /></td>
                        </tr>
                        <tr>
                        <td className="text-red" colSpan="7" style={{textAlign:"right"}}>
                                <Link to={{
                                    pathname:"/admin/addnewpart",
                                    state:{
                                        test: this.state.test
                                    }}}>
                                    <i className="ni ni-fat-add text-red" style={{marginRight:"5px"}}/>{" "}
                                    Add New Part
                                </Link>
                            </td>
                            <td className="text-red" colSpan="8" style={{textAlign:"right"}}>
                                <Link to={{
                                    pathname:"/admin/edittestdetails/"+this.state.test._id,
                                    state:{
                                        test: this.state.test
                                    }}}>
                                    <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                                    Edit
                                </Link>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="editbutton">
                    
                </div>
            </>
        )
    }
    
    loadpartandques(){
        const partsrow = [];
        if(this.state.parts){
            for(var i = 0; i<this.state.parts.length; i++){
                partsrow.push(<PartDetails testid={this.state.test._id} part={this.state.parts[i]}/>)
            }
            return partsrow;
        }
    }

    render(){
        return(
            <>
                <UserHeader />
                {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"
					/>}
                {!this.state.isLoading && this.loadtestdetails()}
                {!this.state.isLoading && this.loadpartandques()}
            </>
        )
    }

}

export default TestDetails;