import React from "react";
import axios from "axios";
import Loader from 'react-loader-spinner'

import TestHeader from "../../components/Headers/TestHeader.jsx";
import TestInstruction from "../testpanel/TestInstruction";
import ResultComponent from "../testresult/ResultComponent";
import TestPanel from "../testpanel/TestPanel";

import ListeningInstruction from "./ListeningInstruction";

import {
    initialiseResult
} from "../../redux-actions/actions";
  
import {connect} from 'react-redux';
  
const mapDispatchToProps = () =>{
	return{
        initialiseResult
    }
}

class LiveTest extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			test: '',
			parts:[],
			readingparts:[],
			listenparts:[],
			speakingparts:[],
			writingparts:[],
			questions:'',
			showtestinstr: true,
			showlisttest:false,
			showquestions: false,
			isLoading:true,	
			isblocked: false,

			showlistensec:true,
			showreadsec:false,
			showwritesec:false,
			showspeaksec:false,
			showresults:false,
			volume:1
		};
		this.totalquestions = 0;
		this.results = [];
		this.changelistteststate = this.changelistteststate.bind(this);
		this.changetestinstrstate = this.changetestinstrstate.bind(this);
		this.loadpartsofquestions = this.loadpartsofquestions.bind(this);
		this.setdata = this.setdata.bind(this);
		this.forloopforques = this.forloopforques.bind(this);
		this.ontimerstop = this.ontimerstop.bind(this);
		this.setvolume = this.setvolume.bind(this);

	}



	componentDidMount(){
		const id = this.props.match.params.id;
		axios.post('https://api.testacademia.com/admin/test/onetest/'+id)
			.then(res => {
				const testdata = res.data;
				this.setState({test: testdata}, ()=>{
					this.loadpartsofquestions(testdata.parts);
			})
		})
		.catch(function(error){
			console.log(error);
		});

		navigator.getUserMedia({ audio: true ,video:false},
            () => {
              console.log('Permission Granted');
              this.setState({ isblocked: false });
            },
            () => {
              console.log('Permission Denied');
              this.setState({ isblocked: true })
            },
        );

	}

	async loadpartsofquestions(parts){
		const ctx = this;
		for (let i=0; i<parts.length; i++){
			var partdata = parts[i];
			partdata = await ctx.forloopforques(partdata);
			ctx.setdata(partdata);
		}
		this.setState({
			isLoading:false
		},()=>{
			this.props.initialiseResult(this.results)
			console.log("Loading Done!")
		})
	}

	setvolume(volume){
		this.setState({
			volume:volume
		})
	}

	componentWillUnmount() {
        clearInterval(this.myInterval)
    }

	async forloopforques(partdata){
		this.totalquestions = this.totalquestions+partdata.questions.length;
		
		partdata.quesdata = await [];
		for (let j=0; j<partdata.questions.length; j++){
				const question = await partdata.questions[j];
				await partdata.quesdata.push(question);
				this.results.push({
					question:question.question,
					questionnum:question.questionnum,
					rightanswer:question.rightanswer,
					useranswer:[],
					score:null,
					parttype:partdata.parttype,
					partcategory:partdata.partcategory,
					audiourl:partdata.audio,
					passage:partdata.passage,
					answerexpl:question.answerexplain,
				});
		}
		return partdata;
	}

	async setdata(partdata){
		const updatedpartdata = await partdata;
		this.setState({
			parts:[...this.state.parts, updatedpartdata]
		},()=>{
			this.setState({
				readingparts:this.state.parts.filter(part=> part.partcategory==="Reading"),
				listenparts:this.state.parts.filter(part=> part.partcategory==="Listening"),
				speakingparts:this.state.parts.filter(part=> part.partcategory==="Speaking"),
				writingparts:this.state.parts.filter(part=> part.partcategory==="Writing")
			})
		})
	}

	changetestinstrstate(){
		if(this.state.test.maincategory==="Listening"){
			this.setState({
				showtestinstr:false,
				showlisttest:true,
			});
		}
		else{
			this.setState({
				showtestinstr:false,
				showlisttest:false,
				showquestions:true
			});
		}
	}

	changelistteststate(){
		this.setState({
			showlisttest:false,
			showquestions:true
		})
	}

	ontimerstop(section){
		if(this.state.test.maincategory==="Full Length Test"){
			if(section==="Listening"){
				alert("Time of Listening section is over. Press Ok to go to next section.")
				this.setState({
					showlistensec:false,
					showreadsec:true,
				})
			}else if(section==="Reading"){
				alert("Time of Reading section is over. Press Ok to go to next section.")
				this.setState({
					showreadsec:false,
					showwritesec:true,
				})
			}else if(section==="Writing"){
				alert("Time of Writing section is over. Press Ok to go to next section.")
				this.setState({
					showwritesec:false,
					showspeaksec:true,
				})
			}else if(section==="Speaking"){
				this.setState({
					showspeaksec:false,
					showresults:true
				})
			}
		}else{
			this.setState({
				showtestinstr:false,
				showquestions:false,
				showresults:true
			});
		}
	}
  
	render() {
		
		return (
		<>
			{this.state.test.maincategory!=="Full Length Test" &&
			<>
				<TestHeader />
				{/* Page content */}
				{this.state.isLoading && <Loader
						type="Puff"
						color="#00BFFF"
						height={80}
						width={80}
						className="iconcontainer"
						/>}
				{!this.state.isLoading && this.state.showtestinstr && <TestInstruction test={this.state.test} changetestinstrstate={this.changetestinstrstate}/>}
				{!this.state.isLoading && this.state.showlisttest && <ListeningInstruction changelistteststate={this.changelistteststate} setvolume={this.setvolume}/>}
				{!this.state.isLoading && this.state.showquestions && 
					<TestPanel results={this.results} 
						volume={this.state.volume}
						parts={this.state.parts} 
						testname={this.state.test.testname}
						testcategory={this.state.test.maincategory}  
						ontimerstop={this.ontimerstop} 
						isblocked ={this.state.isblocked}
						duration={this.state.test.duration.split(" ")[0]}/>}
				{!this.state.isLoading &&!this.state.showquestions && !this.state.showtestinstr && !this.state.showlisttest && <ResultComponent results={this.results} test={this.state.test}/>}
			</>}

			{this.state.test.maincategory==="Full Length Test" &&
			<>
				<TestHeader />
				{/* Page content */}
				{this.state.isLoading && <Loader
						type="Puff"
						color="#00BFFF"
						height={80}
						width={80}
						className="iconcontainer"
						/>}
				{!this.state.isLoading && this.state.showtestinstr && 
					<TestInstruction test={this.state.test} changetestinstrstate={this.changetestinstrstate}/>}

				{!this.state.isLoading && this.state.showlisttest && 
					<ListeningInstruction changelistteststate={this.changelistteststate}/>}

				{!this.state.isLoading && this.state.showquestions && this.state.showlistensec &&
					<TestPanel 
						results={this.results} 
						parts={this.state.listenparts} 
						testname={this.state.test.testname}
						volume={this.state.volume}
						testcategory={this.state.test.maincategory} 
						ontimerstop={this.ontimerstop} 
						duration={this.state.test.listenduration.split(" ")[0]}/>}
				{!this.state.isLoading && this.state.showquestions && this.state.showreadsec &&
					<TestPanel 
						results={this.results} 
						parts={this.state.readingparts} 
						testname={this.state.test.testname}
						testcategory={this.state.test.maincategory} 
						ontimerstop={this.ontimerstop} 
						duration={this.state.test.readduration.split(" ")[0]}/>}
				{!this.state.isLoading && this.state.showquestions && this.state.showwritesec &&
					<TestPanel 
						results={this.results} 
						parts={this.state.writingparts} 
						testname={this.state.test.testname}
						testcategory={this.state.test.maincategory} 
						ontimerstop={this.ontimerstop} 
						duration={this.state.test.writeduration.split(" ")[0]}/>}
				{!this.state.isLoading && this.state.showquestions && this.state.showspeaksec &&
					<TestPanel 
						results={this.results} 
						parts={this.state.speakingparts} 
						testname={this.state.test.testname}
						testcategory={this.state.test.maincategory} 
						ontimerstop={this.ontimerstop} 
						isblocked ={this.state.isblocked}
						duration={this.state.test.speakduration.split(" ")[0]}/>}

				{this.state.showresults&&
					<ResultComponent test={this.state.test}/>}
			</>}
		</>
		);
	}
}

export default connect(null,mapDispatchToProps())(LiveTest);
