import React from "react";
import axios from "axios";
// reactstrap components

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 


import {
    Button,
    Card,
    Form,
    Input,
    Table,
    
  } from "reactstrap";
  // core components

  export default class QuesInput extends React.Component{

    constructor(props){
        super(props);
        this.showcard = true;
        this.state={
            questionnum:this.props.quesnum,
            question:'',
            option1:'',
            option2:'',
            option3:'',
            option4:'',
            option5:'',
            rightanswer:[],
            singlerightanswer:'',
            answerexplain:"",
            testid:'',
            partid:'',

            showoptions:false,
            showrightanswer:true,
            showwritingrightanswer:false,
            showques:true,

        }
        this.onquesubmit = this.onquesubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
        this.handleChangeanswerexplain = this.handleChangeanswerexplain.bind(this);
    }

    onquesubmit(){
        this.showcard = false
        var ques = this.state.question
        var box = ques.replace("###","<input type='text' name = '"+this.state.questionnum+"' placeholder= '"+this.state.questionnum+"'/>")
        this.state.question = box;

        if(this.state.showrightanswer && this.state.singlerightanswer){
            var singleright = this.state.singlerightanswer.split("/"); 
            this.setState({
                rightanswer:singleright
            },()=>{   
                this.props.appendquesarray(this.state,this.state)
            })
        }else{
            this.props.appendquesarray(this.state, this.state)
        }
    }

    handleChange (evt) {
        if(evt.target.name==="iscorrect1" && evt.target.checked){
            this.setState({
                rightanswer:[...this.state.rightanswer, this.state.option1]
            })
        }else if(evt.target.name==="iscorrect1" && !evt.target.checked){
            var selectedanswerarr = [...this.state.rightanswer];
            var indextoremove = selectedanswerarr.indexOf(this.state.option1);
            if(indextoremove!==-1){
                selectedanswerarr.splice(indextoremove,1);

                this.setState({
                    rightanswer: selectedanswerarr,
                })
            }
        }

        if(evt.target.name==="iscorrect2" && evt.target.checked){
            this.setState({
                rightanswer:[...this.state.rightanswer, this.state.option2]
            })
        }else if(evt.target.name==="iscorrect2" && !evt.target.checked){
            var selectedanswerarr = [...this.state.rightanswer];
            var indextoremove = selectedanswerarr.indexOf(this.state.option2);
            if(indextoremove!==-1){
                selectedanswerarr.splice(indextoremove,1);

                this.setState({
                    rightanswer: selectedanswerarr,
                })
            }
        }

        if(evt.target.name==="iscorrect3" && evt.target.checked){
            this.setState({
                rightanswer:[...this.state.rightanswer, this.state.option3]
            })
        }else if(evt.target.name==="iscorrect3" && !evt.target.checked){
            var selectedanswerarr = [...this.state.rightanswer];
            var indextoremove = selectedanswerarr.indexOf(this.state.option3);
            if(indextoremove!==-1){
                selectedanswerarr.splice(indextoremove,1);

                this.setState({
                    rightanswer: selectedanswerarr,
                })
            }
        }
        if(evt.target.name==="iscorrect4" && evt.target.checked){
            this.setState({
                rightanswer:[...this.state.rightanswer, this.state.option4]
            })
        }else if(evt.target.name==="iscorrect4" && !evt.target.checked){
            var selectedanswerarr = [...this.state.rightanswer];
            var indextoremove = selectedanswerarr.indexOf(this.state.option4);
            if(indextoremove!==-1){
                selectedanswerarr.splice(indextoremove,1);

                this.setState({
                    rightanswer: selectedanswerarr,
                })
            }
        }
        if(evt.target.name==="iscorrect5" && evt.target.checked){
            this.setState({
                rightanswer:[...this.state.rightanswer, this.state.option5]
            })
        }else if(evt.target.name==="iscorrect5" && !evt.target.checked){
            var selectedanswerarr = [...this.state.rightanswer];
            var indextoremove = selectedanswerarr.indexOf(this.state.option5);
            if(indextoremove!==-1){
                selectedanswerarr.splice(indextoremove,1);

                this.setState({
                    rightanswer: selectedanswerarr,
                })
            }
        }
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleChangeQuestion(value){
        this.setState({
            question:value
        })
    }

    handleChangeanswerexplain(value){
        this.setState({
            answerexplain:value
        })
    }

    componentDidMount(){
        if(this.props.parttype === "MCQ Single Right" || this.props.parttype === "MCQ Multiple Right"){
            this.setState({
                showoptions:true,
                showrightanswer:false
            })
        }
        if( this.props.parttype === "Speaking"||this.props.parttype === "Cue Card Speaking"){
            this.setState({
                showoptions:false,
                showrightanswer:false
            })
        }
        if(this.props.parttype === "Writing" ){
            this.setState({
                showwritingrightanswer:true,
                showrightanswer:false,
                showoptions:false
            })
        }
        if(this.props.parttype === "Single Part Multiple Question" || this.props.parttype === "Label Blanks"||this.props.parttype === "Label Headings" ){
            this.setState({
                showwritingrightanswer:false,
                showrightanswer:true,
                showoptions:false,
                showques:false
            })
        }
    }

    render(){
        return(
            <>
            {/* Page content */}
                
                {this.showcard && <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                                    
                                    <tbody>
                                        <tr>
                                            <th>Question Number:</th>
                                            <td>
                                                <Input
                                                    type="text"
                                                    name="questionnum"
                                                    value={this.state.questionnum}
                                                    onChange={this.handleChange}/>
                                            </td>
                                        </tr>
                                        {this.state.showques && <tr>
                                            <th scope="row">Question:</th>
                                    {this.props.ispara==="yes" &&
                                    <td colSpan="5"><Form>
                                        <Input
                                        name="question"
                                        placeholder="Write your question here ..."
                                        type="textarea"
                                        value={this.state.question}
                                        onChange={this.handleChange}
                                    />
                                        </Form>
                                    </td>}
                                    {(this.props.ispara!=="yes") &&
                                    <td>
                                        <Form>
                                            <ReactQuill value={this.state.question}
                                                onChange={this.handleChangeQuestion}
                                                name="question" />
                                        </Form>
                                    </td>}
                                    </tr>}                                    
                                    {this.state.showrightanswer && <tr>
                                    <th scope="row">Right Answer:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="rightanswer"
                                            placeholder="Enter Single Word Answer Here.."
                                            rows=""
                                            type="text"
                                            name = "singlerightanswer"
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4"></td>
                                    </tr>}   
                                    {this.state.showoptions && <>
                                    <tr>
                                    
                                    <th scope="row">Option 1:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option1"
                                            placeholder="Enter Option 1 Here.."
                                            rows=""
                                            type="text"
                                            name = "option1"
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                            name = "iscorrect1"
                                            onChange={this.handleChange}
                                            />
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 2:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option2"
                                            placeholder="Enter Option 2 Here.."
                                            rows=""
                                            type="text"
                                            name = "option2"
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect2"
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 3:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option3"
                                            placeholder="Enter Option 3 Here.."
                                            rows=""
                                            type="text"
                                            name = "option3"
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect3"
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 4:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option4"
                                            placeholder="Enter Option 4 Here.."
                                            rows=""
                                            type="text"
                                            name = "option4"
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect4"
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    <tr>
                                    
                                    <th scope="row">Option 5:</th>
                                    <td colSpan="3"><Form>
                                        <Input
                                            id="option5"
                                            placeholder="Enter Option 5 Here.."
                                            rows=""
                                            type="text"
                                            name = "option5"
                                            onChange={this.handleChange}
                                        />
                                        </Form>
                                    </td>
                                    <td colSpan="4">
                                        <input type="checkbox"
                                        name = "iscorrect5"
                                        onChange={this.handleChange}/>
                                        <label style={{marginLeft:"10px"}}>Is Correct Option</label>
                                    </td>
                                    </tr>
                                    </>}
                                    {this.state.showwritingrightanswer &&
                                    <tr>
                                        <th>Right Answer Type:</th>
                                        <td><Input placeholder="Select Right Answer Type" 
                                                    list="rightanswer"
                                                    name="rightanswer"
                                                    onChange={this.handleChange}/>
                                                    <datalist id="rightanswer">
                                                        <option value="Essay"/>
                                                        <option value="Letter"/>
                                                        <option value="Graph"/>
                                                    </datalist>
                                        </td>
                                    </tr>}
                                    <tr>
                                        <th>Answer Explanation:</th>
                                        <td><Form>
                                            <ReactQuill value={this.state.answerexplain}
                                                onChange={this.handleChangeanswerexplain}
                                                name="answerexplain" />
                                            </Form>
                                        </td> 
                                    </tr>
                                    <tr>
                                    <td colSpan="4"></td>
                                    <td><Button color="primary" size = "sm" onClick={this.onquesubmit} >Submit</Button></td>
                                    </tr>
                                    </tbody>
                                    
                                    </Table>
                </Card>}
            </>
        );
    }

}