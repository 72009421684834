import React from "react";
import axios from "axios";

import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import Loader from 'react-loader-spinner'


import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

export default class AddBlog extends React.Component {

  constructor(props){
    super(props);
    this.onChangeBlogImage = this.onChangeBlogImage.bind(this);
        this.onChangeBlogTitle = this.onChangeBlogTitle.bind(this);
        this.onChangeBlogContent = this.onChangeBlogContent.bind(this);
        this.onChangeBlogDate = this.onChangeBlogDate.bind(this);
        this.onChangeBlogAuthor = this.onChangeBlogAuthor.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state={
            imageuri: null,
            title: '',
            content: '',
            date: new Date(),
            author: '',
            success:false,
            isLoading:false
        }
  }

  onChangeBlogImage(ev){
        this.setState({
            success: false, imageuri : "",imageuri:ev.target.value
        }); 
        let file = ev.target.files[0];
        
        // Split the filename to get the name and type
        let fileParts = ev.target.files[0].name.split('.');
        let fileName = fileParts[0];
        let fileType = fileParts[1];
        axios.post("https://api.testacademia.com/admin/audio/sign_s3",{
          fileName : fileName,
          fileType : fileType
        })
        .then(response => {
          var returnData = response.data.data.returnData;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          this.setState({imageuri: url,
            })
          var options = {
            headers: {
              'Content-Type': fileType,
              'x-amz-acl': 'public-read'
            }
          };
          axios.put(signedRequest,file,options)
          .then(result => {
            this.setState({success: true});
          })
          .catch(error => {
            alert("ERROR " + JSON.stringify(error));
          })
        })
        .catch(error => {
          alert(JSON.stringify(error));
        })
    
}

onChangeBlogTitle(e){
    this.setState({
        title: e.target.value
    });
}

onChangeBlogContent(e){
    this.setState({
        content: e.target.value
    });
}

onChangeBlogDate = date => {
    this.setState({
      date: date
    });
};

onChangeBlogAuthor(e){
    this.setState({
        author: e.target.value
    });
}

  handleChange(e){
    this.readURI(e); // maybe call this with webworker or async library?
    if (this.props.onChange !== undefined)
      this.props.onChange(e); // propagate to parent component
  }

onSubmit(e){
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    console.log('Form submitted');
    console.log(`Blog ImageUri: ${this.state.imageuri}`);
    console.log(`Blog Title: ${this.state.title}`);
    console.log(`Blog Content: ${this.state.content}`);
    console.log(`Blog Date: ${this.state.date}`);
    console.log(`Blog Author: ${this.state.author}`);

    const newBlog ={
        imageuri: this.state.imageuri,
        title: this.state.title,
        content: this.state.content,
        date: this.state.date,
        author: this.state.author
    }

    axios.post('https://api.testacademia.com/admin/blog/add', newBlog)
        .then(res => {
          alert(res.data)
          this.setState({
            isLoading:false
          })
      })
    .catch(e=>{
        alert("Fill all the details")
        this.setState({
        isLoading:false
    })
    })

    this.setState({
        imageuri: '',
        title: '',
        content: '',
        date: new Date(),
        author: ''
    })
}

    
    render() {
        const SuccessMessage = () => (
            <div className="text-green">
                <i className="fas fa-check-circle text-green" style={{marginRight:"30px"}}/>{" "} 
            </div>
          )
    return (
        <>
        <UserHeader />
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
            <Col lg="8" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                <div style={{marginTop: 20, marginBottom: 40}} className="container">

                <h3>Create New Blog</h3>

                <br/>
                
                <form onSubmit={this.onSubmit}>

                    <Row className="form-group">
                        <label
                            htmlFor={this.state.id}
                            className="button"
                            style={{marginRight:50}}>
                            Blog Image:
                        </label>
                        <input
                            id={this.state.id}
                            type="file"
                            onChange={this.onChangeBlogImage}
                            className="show-for-sr" />
                        {this.state.success ? <SuccessMessage/> : null}
                                    
                    </Row>

                    <div className="form-group">
                        <label>Blog Title: </label>
                        <input  type="text"
                                className="form-control"
                                value={this.state.title}
                                onChange={this.onChangeBlogTitle}/>
                    </div>
                    <div className="form-group">
                        <label>Blog Content: </label>
                        <textarea class="form-control" id="exampleFormControlTextarea5" rows="5"
                                    value={this.state.content}
                                    onChange={this.onChangeBlogContent}></textarea>
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <br/>
                        <DatePicker
                            selected={this.state.date}
                            onChange={this.onChangeBlogDate}
                        />
                    </div>

                    <div className="form-group">
                        <label>Author: </label>
                        <input  type="text"
                                className="form-control"
                                value={this.state.author}
                                onChange={this.onChangeBlogAuthor}/>
                    </div>

                    <div className="form-group">
                        <input  type="submit"
                                value="Create Blog"
                                className="btn btn-primary"/>
                    </div>
                </form>
            </div>
                </CardBody>
            </Card>
        </Col>}
        </>
      );
    }
  }
  

  