import React from "react";
import axios from "axios";

import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import { isGetAccessor } from "typescript";
import {LoadPackagelist} from "../package/LoadPackageListfn";
import Loader from 'react-loader-spinner'

export default class EditCourse extends React.Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadpackagelist = this.loadpackagelist.bind(this);
    this.handleChangePackage = this.handleChangePackage.bind(this);

    this.state={
      course:this.props.location.state.course.course,
      courseid:this.props.location.state.course._id,
      premium:this.props.location.state.course.premium,
      videoname: this.props.location.state.course.videoname,
      subheading:this.props.location.state.course.subheading,
      videodesc:this.props.location.state.course.videodesc,
      videourl:this.props.location.state.course.videourl, 
      packages:[],
      ptepackages:[],
      selectedpack:[],
      unselectedpack:[],
      isLoading:false
    }
  }

  handleChange (evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  async componentDidMount(){
    var res= await LoadPackagelist(this.state.course)
    this.setState({
      packages: res,
      selectedpack: res.filter(item => this.state.premium.includes(item._id))
   })
    
}

loadpackagelist(){
    const packagelist = [];
    const ctx = this;
    if(this.state.packages){
        this.state.packages.map(function(pack,i){
            
            packagelist.push(
                <td>
                    <input type="checkbox" 
                        name="premium" 
                        value={pack.packagename}
                        defaultChecked = {ctx.state.premium.includes(pack._id)?true:false}
                        onChange={(evt)=>{ctx.handleChangePackage(evt,pack)}}/>
                        {pack.packagename}
                </td>
            )
        })
        return packagelist
    }
}


handleChangePackage(evt, pack){
  console.log(this.state.courseid);
  console.log(pack);
  if(evt.target.checked){
      this.setState({
          selectedpack: [...this.state.selectedpack,pack],
          premium:[...this.state.premium,pack._id]
          //coursecategory:"Videos"
      },()=>{
        console.log(this.state.premium)
        var coursetopackage = {
          packageid: pack._id,
          packagecat: "Videos",
          courseid: this.state.courseid,
      }
      if(this.state.course==="IELTS"){
        axios.post('https://api.testacademia.com/admin/package/addvideo/'+pack._id, coursetopackage)
                .then(res=>{
                    // alert(res.data)
                    console.log(res.data);
                })}
      else {
        var name = this.state.course.toLowerCase().replace(" ","","g")
        axios.post('https://api.testacademia.com/admin/'+name+'/package/addvideo/'+pack._id, coursetopackage)
          .then(res=>{
              // alert(res.data)
              console.log(res.data);
          })}
      })
  }else if(!evt.target.checked){
      var selectedpackArr = [...this.state.selectedpack];
      var premiumArr = [...this.state.premium];
      var indextoremove = selectedpackArr.indexOf(pack);
      var indextoremove_pre = premiumArr.indexOf(pack._id);
      console.log(indextoremove);
      console.log(indextoremove_pre);
      if(indextoremove!==-1){
          selectedpackArr.splice(indextoremove,1);
          premiumArr.splice(indextoremove_pre,1);
          this.setState({
              selectedpack: selectedpackArr,
              premium:premiumArr,
              unselectedpack:[...this.state.unselectedpack,pack]
          },()=>{
            console.log(this.state.premium)
            if(this.state.course==="IELTS"){
            axios.post('https://api.testacademia.com/admin/package/removevideo/'+pack._id,{courseid:this.state.courseid})
            .then(res=>{
                console.log(res.data);
            })
            .catch(e=>console.log(e))}
            else {
              var name = this.state.course.toLowerCase().replace(" ","","g")
              axios.post('https://api.testacademia.com/admin/'+name+'/package/removevideo/'+pack._id,{courseid:this.state.courseid})
              .then(res=>{
                  console.log(res.data);
              })
              .catch(e=>console.log(e))}
          })
      }
     // console.log(this.state.selectedpack);
  }
}


  onSubmit(e){
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    if(this.state.course==="IELTS"){

    axios.post('https://api.testacademia.com/admin/course/editvideo/'+this.state.courseid, this.state)
    .then(res => {
      console.log(res.data)
      alert("Course edited Successfully")
      this.setState({
        isLoading:false
      })
        })
    .catch(e=>{
      alert("Fill all the details")
        this.setState({
          isLoading:false
        })
    })
    }
    else {
      var name = this.state.course.toLowerCase().replace(" ","","g")
      axios.post('https://api.testacademia.com/admin/'+name+'/course/editvideo/'+this.state.courseid, this.state)
      .then(res => {
        console.log(res.data)
        alert("Course edited Successfully")
        this.setState({
          isLoading:false
        })
          })
      .catch(e=>{
        alert("Fill all the details")
          this.setState({
            isLoading:false
          })
      })
    }
  }
    
    render() {
    return (
      <>
      <UserHeader />
      {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
        <Col lg="8" md="7">
        <Card className="bg-secondary shadow border-0" >
              <CardBody className="px-lg-3 py-lg-5" >
              <form onSubmit={this.onSubmit}>
                <Form role="form">
                <FormGroup>
                  <Row> 
                           {this.loadpackagelist()}
                      </Row>
                  </FormGroup>
                  
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Video Name" type="text"
                      name ="videoname"
                        value={this.state.videoname}
                        onChange={this.handleChange} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                      <Row> Sub-heading:
                      <div className="custom-control  mb-3">
                      <InputGroup className="input-group-alternative">
                                      <select  id ="subheading"
                                           name ="subheading"
                                           list="subheading"
                                           value={this.state.subheading}
                                           onChange={this.handleChange}>
                                          <option >Select Sub-heading</option>
                                          <option value="Reading">Reading</option>
                                          <option value="Speaking">Speaking</option>
                                          <option value="Listening">Listening</option>
                                          <option value="Writing">Writing</option>
                                          <option value="Grammar">Grammar</option>
                                          <option value="Basic Grammar">Basic Grammar</option>
                                          <option value="Advanced Grammar">Advanced Grammar</option>
                                          <option value="Essay">Essay</option>
                                          <option value="Business Etiquette">Business Etiquette</option>
                                          <option value="Quant (Arithmetic)">Quant (Arithmetic)</option>
                                          <option value="Quant (Algebra)">Quant (Algebra)</option>
                                          <option value="Quant (Geometry)">Quant (Geometry)</option>
                                          <option value="Quant (Data Analysis)">Quant (Data Analysis)</option>
                                          <option value="Quant (Miscellaneous Topics)">Quant (Miscellaneous Topics)</option>
                                          <option value="Verbal">Verbal</option>
                                          <option value="Verbal (Reading Comprehension)">Verbal (Reading Comprehension)</option>
                                          <option value="Verbal (Critical Reasoning)">Verbal (Critical Reasoning)</option>
                                          <option value="Integrated Reasoning">Integrated Reasoning</option>
                                          <option value="Analytical Writing">Analytical Writing</option>
                                          <option value="Verbal (Sentence Correction)">Verbal (Sentence Correction)</option>
                                      </select>
                                    </InputGroup>
                      </div>
                      </Row>
                  </FormGroup>
                  
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Video Description" type="String" 
                      name ="videodesc"
                      value={this.state.videodesc}
                      onChange={this.handleChange}/>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Video URL" type="text" 
                      name ="videourl"
                      value={this.state.videourl}
                      onChange={this.handleChange}/>
                    </InputGroup>
                  </FormGroup>
                  <div className="form-group"  >
                        <input  type="submit"
                                value="Submit"
                                className="btn btn-primary"/>
                    </div>
                  </Form>
              </form>
            </CardBody>
          </Card>
      </Col>}
      </>
      );
    }
  }
  

  