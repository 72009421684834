import React from "react";
import "../../assets/css/custom.css";
import {Form,Input} from "reactstrap";

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class WritingQues extends React.Component{

    constructor(props){
        super(props);
        this.state={
            writingques: props.writingques[0],
            writingpassage:'',
            words:0,
            resultindex:null,
        }
        this.handleChangewritingpassage = this.handleChangewritingpassage.bind(this);
        this.wordcount = this.wordcount.bind(this);
    }

    componentDidMount(){
        if(this.state.writingques)
        for(let i=0; i<this.props.results.length; i++){
            if(this.props.results[i].questionnum===this.state.writingques.questionnum){
                this.setState({
                    resultindex:i
                })
            }
        }
    }
    

    componentDidUpdate(prevProps){
        if(prevProps.writingques[0] !== this.props.writingques[0]) {
            this.setState({writingques: this.props.writingques[0]});
        }
    }

    handleChangewritingpassage(evt){
        this.setState({
            writingpassage:evt.target.value
        },()=>{
            var results = this.props.results;
            var quesresult = results[this.state.resultindex];
            quesresult.useranswer = this.state.writingpassage;
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })

    }

    wordcount(){
        if(this.state.writingpassage){
            return(this.state.writingpassage.split(/\s+/).length)
        }
    }

    render(){

        return(
            <>  
                <Form>
                    <Input
                        id="exampleFormControlTextarea1"
                        placeholder="Write your answer here ..."
                        rows="20"
                        type="textarea"
                        data-gramm_editor="false"
                        onChange = {this.handleChangewritingpassage}
                        style={{color:"black"}}
                    />
                </Form>
                Word Count: {this.wordcount()}
   
            </>   
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(WritingQues);