import React from "react";
import axios from "axios";

import {
  Col,
  Input,
  InputGroup,
  Table,
  Button
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import Loader from 'react-loader-spinner'

export default class EditPackage extends React.Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state={
        packagename:this.props.location.state.package.packagename,
        noofscored :this.props.location.state.package.noofscored,
        noofunscored :this.props.location.state.package.noofunscored,
        noofvideos :this.props.location.state.package.noofvideos,
        noofsectional :this.props.location.state.package.noofsectional,
        price :this.props.location.state.package.price,
        validity :this.props.location.state.package.validity,
        discount:this.props.location.state.package.discount,
        course:this.props.location.state.package.course,
        videolesson:this.props.location.state.package.videolesson,
        practiceques:this.props.location.state.package.practiceques,
        scoredflt:this.props.location.state.package.scoredflt,
        livesess:this.props.location.state.package.livesess,
        scorepred:this.props.location.state.package.scorepred,
        isLoading:false
    }
  }
  handleChange (evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  onSubmit(e){
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    const id = this.props.match.params.id;
    if(this.state.course==="IELTS"){
      axios.post('https://api.testacademia.com/admin/package/editpackage/'+id, this.state)
    .then(res => {console.log(res.data)
          alert(res.data)
          this.setState({
            isLoading:false
          })
      })
    .catch(e=>{
        alert("Fill all the details")
        this.setState({
        isLoading:false
    })
    })
    }
    else{
      var name = this.state.course.toLowerCase().replace(" ","","g")
      console.log(this.state)
      axios.post('https://api.testacademia.com/admin/'+name+'/package/editpackage/'+id, this.state)
    .then(res => {console.log(res.data)
          alert(res.data)
          this.setState({
            isLoading:false
          })
      })
    .catch(e=>{
        alert("Fill all the details")
        this.setState({
        isLoading:false
    })
    })
    }
    
  }
    
    render() {
    return (
      <>
      <UserHeader />
      {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
        <Col lg="8" md="7">
        <Table className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
        <tr><th scope="row" style={{borderColor:"white"}}>Package Name:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Package name"
                type="text" 
                value={this.state.packagename}
                onChange={this.handleChange}
                name ="packagename"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Scored:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="No of Scored"
                type="Number" 
                value={this.state.noofscored}
                onChange={this.handleChange}
                name ="noofscored"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Unscored:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="No of Unscored"
                type="Number" 
                value={this.state.noofunscored}
                onChange={this.handleChange}
                name ="noofunscored"/>
                </InputGroup></td>
        </tr> 
        <tr><th scope="row" style={{borderColor:"white"}}>Videos:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="No of Videos"
                type="Number" 
                value={this.state.noofvideos}
                onChange={this.handleChange}
                name ="noofvideos"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Sectional test:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="No of Sectional Test"
                type="Number" 
                value={this.state.noofsectional}
                onChange={this.handleChange}
                name ="noofsectional"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Price (in INR):</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Price of the package"
                type="Number" 
                value={this.state.price}
                onChange={this.handleChange}
                name ="price"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Validity (in Days):</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Validity"
                type="Number" 
                value={this.state.validity}
                onChange={this.handleChange}
                name ="validity"/>
                </InputGroup></td>
        </tr> 
        <tr><th scope="row" style={{borderColor:"white"}}>Discount (in percentage):</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Discount"
                type="Number" 
                value={this.state.discount}
                onChange={this.handleChange}
                name ="discount"/>
                </InputGroup></td>
        </tr> 
        <tr>
        <hr style={{borderWidth:"2px",backgroundColor:"black"}}></hr>
        </tr> 
        <tr>
          <th scope="row" style={{borderColor:"white"}}>Video lessons:</th>
          <td>
                <InputGroup className="input-group-alternative">
                <Input placeholder="videolesson"
                type="text" 
                value={this.state.videolesson}
                onChange={this.handleChange}
                name ="videolesson"/>
                </InputGroup>
          </td>
        </tr>
        <tr>
          <th scope="row" style={{borderColor:"white"}}>Practice questions:</th>
          <td>
                <InputGroup className="input-group-alternative">
                <Input placeholder="practiceques"
                type="text" 
                value={this.state.practiceques}
                onChange={this.handleChange}
                name ="practiceques"/>
                </InputGroup>
          </td>
        </tr>
        <tr>
          <th scope="row" style={{borderColor:"white"}}>Scored Full Length test:</th>
          <td>
                <InputGroup className="input-group-alternative">
                <Input placeholder="scoredflt"
                type="text" 
                value={this.state.scoredflt}
                onChange={this.handleChange}
                name ="scoredflt"/>
                </InputGroup>
          </td>
        </tr>
        <tr>
          <th scope="row" style={{borderColor:"white"}}>Hours of live sessions:</th>
          <td>
                <InputGroup className="input-group-alternative">
                <Input placeholder="livesess"
                type="text" 
                value={this.state.livesess}
                onChange={this.handleChange}
                name ="livesess"/>
                </InputGroup>
          </td>
        </tr>
        <tr>
          <th scope="row" style={{borderColor:"white"}}>Score predictor:</th>
          <td>
                <Input placeholder="scorepred"
                type="radio" 
                value={true}
                defaultChecked={this.state.scorepred==="true"?true:false}
                onChange={this.handleChange}
                name ="scorepred"/>
                True
          </td>
          <td>
                <Input placeholder="scorepred"
                type="radio" 
                value={false}
                defaultChecked={this.state.scorepred==="false"?true:false}
                onChange={this.handleChange}
                name ="scorepred"/>
                False
          </td>
        </tr> 
        <tr>
            <th colSpan="5" style={{borderColor:"white"}}/>
            <td ><Button color="primary" size = "sm" onClick={this.onSubmit}>Submit</Button></td>
            </tr>   
                </Table>
      </Col>}
      </>
      );
    }
  }
  

  