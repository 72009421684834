import React from 'react';
import EachQuesMcqSr from './EachQuesMcqSr';

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuesMcqSr extends React.Component{

    constructor(props){
        super(props)
        this.state={
            questions:this.props.quesdata
        }
        this.results = [];
        this.loadquestiondetails = this.loadquestiondetails.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata!== this.props.quesdata) {
            this.setState({
                questions: this.props.quesdata,
            });
          }
    }

    loadquestiondetails(){
        const quesrows = [];
        const ctx = this;
        if(this.state.questions){
            this.state.questions.map(function (ques, i){
                for(let i=0;i<ctx.props.results.length; i++){
                    if(ctx.props.results[i].questionnum===ques.questionnum
                        && ctx.props.results[i].parttype===ctx.props.part.parttype){
                        quesrows.push(<EachQuesMcqSr 
                                        question={ques} 
                                        resultindex={i}
                                        currentques={ctx.props.currentques}
                                        setting = {ctx.props.setting}
                                        changeQues={ctx.props.changeQues}/>)
                                        break;
                                    }
                }
            })
        }
        return quesrows;
    }

    render(){
        return(
            <>
                {this.loadquestiondetails()}
            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuesMcqSr);