export const updateResult = (result) =>{
    return{
        type: 'update_result',
        result: result
    }
}

export const initialiseResult = (results) =>{
    return{
        type: 'initialise_results',
        results: results
    }
}