import React from 'react';
import EachQuesMcqMr from './EachQuesMcqMr';

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuesMcqMr extends React.Component{

    constructor(props){
        super(props)
        this.state={
            questions:this.props.quesdata
        }
        this.results = [];
        this.loadquestiondetails = this.loadquestiondetails.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata !== this.props.quesdata) {
            this.setState({questions: this.props.quesdata});
          }
    }

    loadquestiondetails(){
        const quesrows = [];
        const ctx = this;
        //console.log(this.state.questions)
        if(this.state.questions){
            this.state.questions.map(function (ques, i){
                for(let i=0;i<ctx.props.results.length; i++){
                    if(ctx.props.results[i].questionnum===ques.questionnum
                        && ctx.props.results[i].parttype===ctx.props.part.parttype){
                        quesrows.push(<EachQuesMcqMr question={ques} 
                                                    resultindex={i}
                                                    currentques={ctx.props.currentques}
                                                    setting = {ctx.props.setting}/>)
                    }
                }
            })
        }
        return quesrows;
    }

    render(){
        return(
            <>
                {this.loadquestiondetails()}
            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuesMcqMr);