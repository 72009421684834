import React from "react";

import {
    Card,
    Row,
    Col
  } from "reactstrap";

  import "./addteststyle.css";

class PartDetail extends React.Component{

    constructor(props){
        super(props);
        this.state={
            partdetails: this.props.partdetails
        }
    }

    render(){
        return(
            <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                    

                    <Row >
                        <Col>
                            <p><strong>Part Type: </strong> {this.state.partdetails.parttype}</p>
                        </Col>
                        <Col>
                            <p><strong>Start Question: </strong>{this.state.partdetails.startques}</p>
                        </Col>
                        <Col>
                            <p><strong>End Question: </strong>{this.state.partdetails.endques}</p>
                        </Col>
                        <Col>
                            <p><strong>Part Category: </strong>{this.state.partdetails.partcategory}</p>
                        </Col>
                        {this.state.partdetails.audio && <Col>
                            <p><strong>Audio File: </strong>{this.state.partdetails.audio}</p>
                        </Col>}
                        {this.state.partdetails.image && <Col>
                            <p className="imageuri"><strong>Image File: </strong>{this.state.partdetails.image}</p>
                        </Col>}
                    </Row>
                    {this.state.partdetails.passage && <Row>
                        <Col>
                            <p><strong>Passage: </strong><span><div dangerouslySetInnerHTML={{ __html: this.state.partdetails.passage }} 
                    style={{width:"700px", wordWrap:"break-word"}} /></span> </p>
                        </Col>
                    </Row>}
                    {this.state.partdetails.singlepartques && <Row>
                        <Col>
                            <p><strong>Question: </strong><span><div dangerouslySetInnerHTML={{ __html: this.state.partdetails.singlepartques }} 
                    style={{width:"700px", wordWrap:"break-word"}} /></span> </p>
                        </Col>
                    </Row>}
                    {this.state.partdetails.noofheadings && <Row>
                        <Col>
                             <p><strong>No of heading:</strong>{this.state.partdetails.noofheadings}</p>
                        </Col>
                        <Col>
                            {this.state.partdetails.listofheadings && <p><strong>List of heading:</strong>{this.state.partdetails.listofheadings}</p>}
                        </Col>
                    </Row>}
                    <Row>
                        {this.state.partdetails.startoption &&<Col>
                             <p><strong>Start option:</strong>{this.state.partdetails.startoption}</p>
                        </Col>}
                        {this.state.partdetails.endoption && <Col>
                            <p><strong>End Option:</strong>{this.state.partdetails.endoption}</p>
                        </Col>}
                    </Row>
                    {this.state.partdetails.noofoptions && <Row>
                        <Col>
                            {this.state.partdetails.queslabel && <p><strong>Question Label:</strong>{this.state.partdetails.queslabel}</p>}
                        </Col>
                        <Col>
                            {this.state.partdetails.optionlabel && <p><strong>Option Label:</strong>{this.state.partdetails.optionlabel}</p>}
                        </Col>
                        <Col>
                            {this.state.partdetails.noofoptions && <p><strong>No of Options:</strong>{this.state.partdetails.noofoptions}</p>}
                        </Col>
                        <Col>
                            {this.state.partdetails.listofoptions && <p><strong>List of Options:</strong>{this.state.partdetails.listofoptions}</p>}
                        </Col>
                    </Row>}
                </Card>
        )
    }
}

export default PartDetail;