import React from "react";
import axios from "axios";
import {LoadPackagelist} from "../package/LoadPackageListfn";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import Loader from 'react-loader-spinner'

export default class AddCourse extends React.Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadpackagelist = this.loadpackagelist.bind(this);
    this.handleChangePackage = this.handleChangePackage.bind(this);

    this.state={
      course:'IELTS',
      premium:[],
      videoname: '',
      subheading:'',
      videodesc:'',
      videourl:'',
      course:'',
      packages:[],
      selectedpack:[],
      receivedpack:[],
      isLoading:false
    }
  }

  async handleChange (evt) {
    this.setState({ [evt.target.name]: evt.target.value });
      if(evt.target.name==="course"){
        var pack = await LoadPackagelist(evt.target.value)
        this.setState({
          packages: pack
        })
    }
  }
  
loadpackagelist(){
    const packagelist = [];
    const ctx = this;
    if(this.state.packages){
        this.state.packages.map(function(pack,i){
            packagelist.push(
                <td>
                    <input type="checkbox" 
                        name="premium" 
                        value={pack._id}
                        onChange={(evt)=>{ctx.handleChangePackage(evt,pack)}}/>
                        {pack.packagename}
                </td>
            )
        })
        return packagelist
    }
    
}

handleChangePackage(evt, pack){
  if(evt.target.checked){

      var premium = {
        packageid: pack._id,
        packagecat: "Videos"
      }
      this.setState({
          selectedpack: [...this.state.selectedpack,pack],
          premium:[...this.state.premium,pack._id]
      },()=>{
        console.log(this.state.premium)
      })
  }else if(!evt.target.checked){
      var selectedpackArr = [...this.state.selectedpack];
      var premiumArr = [...this.state.premium];
      var indextoremove = selectedpackArr.indexOf(pack);
      var indextoremove_pre = premiumArr.indexOf(pack._id);
      if(indextoremove!==-1){
          selectedpackArr.splice(indextoremove,1);
          premiumArr.splice(indextoremove_pre,1);
          this.setState({
              selectedpack: selectedpackArr,
              premium:premiumArr
          })
      }
  }
}

  onSubmit(e){
    //e.preventDefault();
    this.setState({
      isLoading:true
    })
    if(this.state.course === "IELTS"){

    axios.post('https://api.testacademia.com/admin/course/addvideo', this.state)
    .then(res => {console.log(res.data)
            var ctx = this;
            this.state.selectedpack.map(function(pack){
                var coursetopackage = {
                    packageid: pack._id,
                    packagecat: "Videos",
                    courseid: res.data
                }
                console.log(coursetopackage);
                axios.post('https://api.testacademia.com/admin/package/addvideo/'+pack._id, coursetopackage)
                .then(res=>{
                    alert("Course Added Successfully")
                    ctx.setState({
                      isLoading:false
                    },()=>{
                      ctx.setState({
                        course:'',
                        videoname: '',
                        subheading:'',
                        videodesc:'',
                        videourl:'',
                        course:'',
                      })  
                    })
                })
                .catch(e=>{
                  console.log(e)
                })
            })
          })
      .catch(e=>{
        alert("Fill all the details")
        this.setState({
          isLoading:false
        })
      })
    }
      else{
        var name = this.state.course.toLowerCase().replace(" ","","g")
      axios.post('https://api.testacademia.com/admin/'+name+'/course/addvideo', this.state)
      .then(res => {console.log(res.data)
              var ctx = this;
              this.state.selectedpack.map(function(pack){
                  var coursetopackage = {
                      packageid: pack._id,
                      packagecat: "Videos",
                      courseid: res.data
                  }
                  console.log(coursetopackage);
                  axios.post('https://api.testacademia.com/admin/'+name+'/package/addvideo/'+pack._id, coursetopackage)
                  .then(res=>{
                    ctx.setState({
                      isLoading:false
                  },()=>{
                    alert("Course Added Successfully")   
                    ctx.setState({
                      course:'',
                      videoname: '',
                      subheading:'',
                      videodesc:'',
                      videourl:'',
                      course:'',
                    })                
                  })
                  })
                  .catch(e=>console.log(e))
              })
            })
      .catch(e=>{
        alert("Fill all the details")
        this.setState({
          isLoading:false
        })
      })
    }
  }
    
    render() {
    return (
      <>
      <UserHeader />
      {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
        <Col lg="8" md="7">
        <Card className="bg-secondary shadow border-0" >
              <CardBody className="px-lg-3 py-lg-5" >
              <form onSubmit={()=>this.onSubmit()}>
                <Form role="form">
                <FormGroup>
                      <Row>   Course:
                      <div className="custom-control custom-radio mb-3">
                      <InputGroup className="input-group-alternative">
                                    <select  id ="course"
                                            list="course" 
                                            name = "course"
                                            value ={this.state.course} 
                                            onChange={this.handleChange}>
                                        <option >Select the course</option>                                            
                                        <option value="IELTS">IELTS</option>
                                        <option value="PTE">PTE</option>
                                        <option value="GRE">GRE</option>
                                        <option value="GMAT">GMAT</option>
                                        <option value="TOEFL">TOEFL</option>
                                        <option value="SAT">SAT</option>
                                        <option value="OET">OET</option>
                                        <option value="EXPERT ENGLISH">EXPERT ENGLISH</option>
                                    </select>
                                    </InputGroup>
                      </div>
                      </Row>
                  </FormGroup>
                  
                  <FormGroup>
                  <Row> Packages:
                           {this.loadpackagelist()}
                      </Row>
                  </FormGroup>
                  
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Video Name" type="text"
                      name ="videoname"
                        value={this.state.videoname}
                        onChange={this.handleChange} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                      <Row> Sub-heading:
                      <div className="custom-control  mb-3">
                      <InputGroup className="input-group-alternative">
                                      <select  id ="subheading"
                                           name ="subheading"
                                           list="subheading"
                                           value={this.state.subheading}
                                           onChange={this.handleChange}>
                                          <option >Select Sub-heading</option>
                                          <option value="Reading">Reading</option>
                                          <option value="Speaking">Speaking</option>
                                          <option value="Listening">Listening</option>
                                          <option value="Writing">Writing</option>
                                          <option value="Grammar">Grammar</option>
                                          <option value="Basic Grammar">Basic Grammar</option>
                                          
                                          <option value="Advanced Grammar">Advanced Grammar</option>
                                          <option value="Essay">Essay</option>
                                          <option value="Business Etiquette">Business Etiquette</option>
                                          <option value="Quant (Arithmetic)">Quant (Arithmetic)</option>
                                          <option value="Quant (Algebra)">Quant (Algebra)</option>
                                          <option value="Quant (Geometry)">Quant (Geometry)</option>
                                          <option value="Quant (Data Analysis)">Quant (Data Analysis)</option>
                                          <option value="Quant (Miscellaneous Topics)">Quant (Miscellaneous Topics)</option>
                                          <option value="Verbal">Verbal</option>
                                          <option value="Verbal (Critical Reasoning)">Verbal (Critical Reasoning)</option>
                                          <option value="Verbal (Reading Comprehension)">Verbal (Reading Comprehension)</option>
                                          <option value="Integrated Reasoning">Integrated Reasoning</option>
                                          <option value="Analytical Writing">Analytical Writing</option>
                                          <option value="Verbal (Sentence Correction)">Verbal (Sentence Correction)</option>
                                      </select>
                                    </InputGroup>
                      </div>
                      </Row>
                  </FormGroup>
                  
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Video Description" type="String" 
                      name ="videodesc"
                      value={this.state.videodesc}
                      onChange={this.handleChange}/>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Video URL" type="text" 
                      name ="videourl"
                      value={this.state.videourl}
                      onChange={this.handleChange}/>
                    </InputGroup>
                  </FormGroup>
                  <div className="form-group"  >
                        <input  type="submit"
                                value="Submit"
                                className="btn btn-primary"/>
                    </div>
                  </Form>
              </form>
            </CardBody>
          </Card>
      </Col>}
      </>
      );
    }
  }
  

  