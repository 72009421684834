import React from "react";
import DatePicker from "react-datepicker";

import axios from 'axios';
 
import "react-datepicker/dist/react-datepicker.css";
// reactstrap components

import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// core components
import Loader from 'react-loader-spinner'

import UserHeader from "components/Headers/UserHeader.jsx";
import { LoadPackagelist } from "../package/LoadPackageListfn";
import { Form, Input, Select, Button, Checkbox } from 'antd';

export default class EditStudents extends React.Component {

	_isMounted = false;

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadpackagelist = this.loadpackagelist.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.state={
      name: this.props.location.state.student.name,
      mobile:this.props.location.state.student.mobile,
      email:this.props.location.state.student.email,
      password:this.props.location.state.student.password,
      course:this.props.location.state.student.course,
      userType:this.props.location.state.student.userType,
      startDate:this.props.location.state.student.startDate?new Date(this.props.location.state.student.startDate.split("Z")[0]+"+00:00"):"",
      endDate:this.props.location.state.student.endDate?new Date(this.props.location.state.student.endDate.split("Z")[0]+"+00:00"):"",
      packages:[],
      ptepackages:[],
      isLoading:false
    }
  }

  handleChange (evt) {
    this.setState({ [evt.target.name]: evt.target.value },()=>{console.log(this.state)});}
  
  async componentDidMount(){
    var res= await LoadPackagelist(this.state.course)
    this.setState({
      packages: res
    })
  }

  onChangeStartDate = date =>{
    this.setState({
        startDate: date
    },()=>{this.onChangeEndDate()});
  }

  onChangeEndDate() {
    var validity = 0;
      for(var i=0;i<this.state.packages.length;i++){
        if(this.state.packages[i]._id===this.state.userType){
            validity = this.state.packages[i].validity;
        }
      }
    this.setState({
        endDate:  new Date(this.state.startDate.valueOf() + (validity*86400000))
    },()=>{console.log(this.state.endDate)});
  }

  loadpackagelist(){
    const packagelist = [];
    const ctx = this;
    if(this.state.packages){
        this.state.packages.map(function(pack,i){
            packagelist.push(
                <td>
                    <input type="radio" 
                        name="userType" 
                        value={pack._id}
                        defaultChecked = {ctx.state.userType===pack._id}
                        onChange={(evt)=>{ctx.onChangeType(evt, pack)}}/>
                        {pack.packagename}
                </td>
            )
        })
        return packagelist
    
  }
}

onChangeType(evt, pack){
  if(evt.target.checked){
      this.setState({
          userType:pack._id
      },()=>{console.log(this.state.userType);
              var validity = pack.validity;
              this.setState({
                  endDate: new Date(this.state.startDate.valueOf() + (validity*86400000))
              },()=>{console.log(this.state.endDate)});    
      })
  }
}
  
  
  onSubmit(e){
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    const id = this.props.match.params.id;
    if(this.state.course==="IELTS"){
    axios.post('https://api.testacademia.com/admin/student/editstud/'+id, this.state)
            .then(res => {console.log(res.data)
                  alert(res.data)
                  this.setState({
                    isLoading:false
                  },()=>{
                    this.props.history.push('/admin/Users');
                  })
                  })
            .catch(e=>{console.log(e)
              alert("Enter the details")
                  this.setState({
                    isLoading:false
                  })
            })
      }
    else {
      var name = this.state.course.toLowerCase().replace(" ","","g")
        axios.post('https://api.testacademia.com/admin/'+name+'/student/editstud/'+id, this.state)
        .then(res => {console.log(res.data)
          alert(res.data)
          this.setState({
            isLoading:false
          },()=>{
            this.props.history.push('/admin/Users');
          })
          })
    .catch(e=>{console.log(e)
      alert("Enter the details")
          this.setState({
            isLoading:false
          })
    })
    }
	}
	
    
    render() {
    return (
      <>
      <UserHeader />
      {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
      <Col lg="8" md="7">
      <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
            <form onSubmit={this.onSubmit}>
              <Form
                name="basic"
                initialValues={{
                  name: this.state.name,
                  mobile: this.state.mobile,
                  email:this.state.email,
                  password:this.state.password,
                }}
              >
                <FormGroup>
                   <Form.Item
                   label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Student name!',
                      },
                    ]}
                    style={{display:"flex"}}
                  >
                    <Input placeholder="Name" 
                      name="name"
                      type="text" 
                      value={this.state.name}
                      onChange={this.handleChange}/>
                  </Form.Item>
                  </FormGroup> 
                  <FormGroup> 
                  <Form.Item
                  label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Phone No!',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || (getFieldValue('mobile').length === 10 && (!isNaN(getFieldValue('mobile'))))) {
                            return Promise.resolve();
                          }
            
                          return Promise.reject('Enter valid phone no');
                        },
                      }),
                    ]}
                    style={{display:"flex"}}
                    value={this.state.mobile}
                  >
                    <Input placeholder="Mobile"
                    name="mobile"
                       type="text"
                       value={this.state.mobile}
                        onChange={this.handleChange} />
                  </Form.Item>
                </FormGroup> 
                <FormGroup>
                <Form.Item
                  label="Email Id: "
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                    style={{display:"flex"}}
                  >
                    <Input placeholder="Email" 
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      type="email" />
                  </Form.Item>
                </FormGroup>
                <FormGroup>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback
                    style={{display:"flex"}}
                  >
                    <Input.Password placeholder="Password" 
                      type="password" 
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}/>
                  </Form.Item>
                </FormGroup>
                
                <FormGroup>
                <Row>
                  {this.loadpackagelist()}
                    </Row>
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                            
                        </InputGroupText>
                        </InputGroupAddon>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.onChangeStartDate}
                            dateFormat = "dd/MM/yyyy"
                        />
                                              
                    </InputGroup>
                </FormGroup>
                {/* <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                        </InputGroupAddon>                      
                          <DatePicker
                            selected={this.state.endDate}
                            onChange={this.onChangeEndDate}
                            dateFormat = "dd/MM/yyyy"
                        />
                    </InputGroup>
                </FormGroup> */}
                
                {/* <div className="form-group"  >
                        <input  type="submit"
                                value="Add Student"
                                className="btn btn-primary"/>
                    </div> */}
                <Form.Item
                  wrapperCol={{
                    span: 12,
                    offset: 6,
                  }}
                >
                  <Button className="btn btn-primary" type="primary" htmlType="submit" onClick={this.onSubmit}>
                    Edit Student
                  </Button>
                </Form.Item>
              </Form>
              </form>
            </CardBody>
          </Card>
      </Col>}
      </>
      );
    }
  }
  