import React from "react";
import axios from "axios";

import {
  Col,
  Input,
  InputGroup,
  Table,
  Button
} from "reactstrap";


import UserHeader from "components/Headers/UserHeader.jsx";
import Loader from 'react-loader-spinner'

export default class AddInstitute extends React.Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.loadpackagelist = this.loadpackagelist.bind(this);

    this.state={
        institutename:'',
        logo :'',
        emailid :'',
        password :'',
        package :'',
        noofids :null,
        packageoptions:[],
        successimg:false,
        isLoading:false
    }
  }
  componentDidMount(){
    axios.post('https://api.testacademia.com/admin/package/showallpack')
    .then(res=>{
        this.setState({
            packageoptions: res.data
        })
    })
    .catch(function(err){
        console.log(err)
    })
}

loadpackagelist(){
    const packagelist = [];
    const ctx = this;
    
    if(this.state.packageoptions){
        
        this.state.packageoptions.map(function(pack,i){
            packagelist.push(
                    <option 
                        value={pack._id}
                        >
                        {pack.packagename}
                    </option>
            )
        })
        return packagelist
    }
}

handleChange (evt) {
    this.setState({ [evt.target.name]: evt.target.value });}

onChangeImage(ev){
        this.setState({
          successimg: false, logo : "",logo:ev.target.value
      }); 
      let file = ev.target.files[0];

      // Split the filename to get the name and type
      let fileParts = ev.target.files[0].name.split('.');
      let fileName = fileParts[0];
      let fileType = fileParts[1];
      axios.post("https://api.testacademia.com/admin/audio/sign_s3",{
        fileName : fileName,
        fileType : fileType
      })
      .then(response => {
        var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        this.setState({logo: url,
          })
        var options = {
          headers: {
            'Content-Type': fileType,
            'x-amz-acl': 'public-read'
          }
        };
        axios.put(signedRequest,file,options)
        .then(result => {
          this.setState({successimg: true});
        })
        .catch(error => {
          alert("ERROR " + JSON.stringify(error));
        })
      })
      .catch(error => {
        alert(JSON.stringify(error));
      })
    }

  onSubmit(e){
    e.preventDefault();
    this.setState({
      isLoading:true
    })
    axios.post('https://api.testacademia.com/admin/institute/addinstitute', this.state)
    .then(res => {
        console.log(res.data);
        axios.get("https://api.testacademia.com/domainapi/maker/s7z5exgxi8tom88",
            {
              params:{
                subdomain: this.state.institutename,
                ip: '206.189.142.116'
              }
            })
            .then(response=>{
              console.log(response.data); 
              alert(res.data);
              this.setState({
                institutename:'',
                logo :'',
                emailid :'',
                password :'',
                package :'',
                noofids :null,
                isLoading:false
            })
        })
        .catch(e=>{
          alert("Fill all the details")
          this.setState({
          isLoading:false
      })
      })
  })
  }
    
    render() {
      const SuccessMessage = () => (
        <div className="text-green">
            <i className="fas fa-check-circle text-green" style={{marginRight:"30px"}}/>{" "} 
        </div>
      )
    return (
      <>
      <UserHeader />
      {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
      {!this.state.isLoading &&
        <Col lg="8" md="7">
        <Table className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
        <tr><th scope="row" style={{borderColor:"white"}}>Institute Name:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Institute name"
                type="text" 
                value={this.state.institutename}
                onChange={this.handleChange}
                name ="institutename"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Logo:</th>
            <td><InputGroup className="input-group-alternative">
                <Input 
                type="file" 
                onChange={this.onChangeImage}
                name ="logo"/>
                </InputGroup></td>
                <td colSpan="1">{this.state.successimg ? <SuccessMessage/> : null}</td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Email Id:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Enter Email Id"
                type="text" 
                value={this.state.emailid}
                onChange={this.handleChange}
                name ="emailid"/>
                </InputGroup></td>
        </tr> 
        <tr><th scope="row" style={{borderColor:"white"}}>Password:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="Set Password"
                type="text" 
                value={this.state.password}
                onChange={this.handleChange}
                name ="password"/>
                </InputGroup></td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>Package:</th>
        <td><select  id ="package"
              list="package" 
              name = "package"
              value ={this.state.package} 
              onChange={this.handleChange}>
              <option>Select package</option>
          {this.loadpackagelist()}
          </select>
          </td>
        </tr>
        <tr><th scope="row" style={{borderColor:"white"}}>No Of Ids:</th>
            <td><InputGroup className="input-group-alternative">
                <Input placeholder="No of Ids"
                type="Number" 
                value={this.state.noofids}
                onChange={this.handleChange}
                name ="noofids"/>
                </InputGroup></td>
        </tr>
       
        <tr>
            <th colSpan="5" style={{borderColor:"white"}}/>
            <td ><Button color="primary" size = "sm" onClick={this.onSubmit}>Submit</Button></td>
            </tr>   
                </Table>
      </Col>}
      </>
      );
    }
  }
  

  