import React from "react";
import axios from "axios";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";

class TestRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            testname:this.props.test.testname,
            testid:this.props.test._id,
            testpremium:this.props.test.premium
        }

    }

    render(){
        return(
            <>
                <tr>
                    <th scope="row">{this.state.testname}</th>
                    <td></td>
                    <td></td>
                                                
                    {this.state.testpremium.includes("free") && <Link to={"/test/livetest/"+this.state.testid}>
                        <td className="text-green">
                        <i className="ni ni-single-copy-04" style={{"marginRight":"10px"}}/>{" "}
                        Take Test
                        </td>
                    </Link>}

                    {!this.state.testpremium.includes("free") && <Link to={"/test/livetest/"+this.state.testid}>
                        <td className="text-red">
                        <i className="ni ni-bag-17 text-red" style={{marginRight:"5px"}}/>{" "}
                        Upgrade
                        </td>
                    </Link>}

                    <Link to={"/admin/edittest/"+this.state.testid}>
                    <td className="text-red">
                        <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                        Edit
                    </td>
                    </Link>
                </tr>
            </>
        )
    }
}

export default TestRow;