import React from 'react';

import EachQuesMatrix from './EachQuesMatrix';
import { Tab, Table } from 'react-bootstrap';

import "../testpanel/panel.css";

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuesMatrix extends React.Component{

    constructor(props){
        super(props)
        this.state={
            questions:this.props.quesdata,
            startoption:this.props.startoption,
            endoption:this.props.endoption,
            currentques:this.props.currentques
        }
        this.results = [];
        this.loadquestiondetails = this.loadquestiondetails.bind(this);
        this.changeques = this.changeques.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata !== this.props.quesdata || prevProps.currentques !== this.props.currentques) {
            this.setState({
                questions: this.props.quesdata,
                currentques: this.props.currentques
            });
          }
    }

    changeques(quesnum){
        this.setState({
            currentques:quesnum
        })
    }

    loadquestiondetails(){
        const quesmatrix = [];
        const labelrow =[];

        if(this.state.startoption && this.state.endoption){
            for (let i=this.state.startoption.charCodeAt(0)-2; i<=this.state.endoption.charCodeAt(0); i++){
                if(i<this.state.startoption.charCodeAt(0)){
                    labelrow.push(<th style={{border:"1px solid"}}></th>)
                }else{
                    labelrow.push(<th style={{border:"1px solid"}}>{String.fromCharCode(i)}</th>)
                }
            }
            quesmatrix.push(<tr>{labelrow}</tr>);
        }
        if(this.state.questions && this.state.startoption && this.state.endoption){
            const ctx = this;
            this.state.questions.map(function (ques, i){
                for(let i=0;i<ctx.props.results.length; i++){
                    if(ctx.props.results[i].questionnum===ques.questionnum 
                        && ctx.props.results[i].parttype===ctx.props.part.parttype){
                        quesmatrix.push(
                            <tr className={ctx.state.currentques===ques.questionnum?"matrix-row-active":""}
                                onClick={()=>{ctx.changeques(ques.questionnum)}}>
                                <EachQuesMatrix question={ques} 
                                startoption={ctx.state.startoption} 
                                endoption={ctx.state.endoption} 
                                resultindex={i}
                                changeques={ctx.changeques}
                                setting = {ctx.props.setting}/>
                            </tr>)
                    }
                }
            })
        }
        return quesmatrix;
    }

    render(){
        return(
            <>
                <table className="matrix-table">
                    {this.loadquestiondetails()}
                </table>
            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuesMatrix);