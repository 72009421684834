import React from "react";
import axios from 'axios';
import Loader from 'react-loader-spinner'

import { NavLink as NavLinkRRD, Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { relative } from "path";

class Login extends React.Component {

  constructor(props){
    super(props);

    this.onChangeuserid = this.onChangeuserid.bind(this);
    this.onChangepassword = this.onChangepassword.bind(this);

    this.onlogin = this.onlogin.bind(this);
    this.state={
      userid: '',
      password:'',
      isLoading:false,
    }
  }
  componentDidMount(){
    console.log(window.location.href);
  }

  onChangeuserid(e){
    this.setState({
        userid: e.target.value
    });
  }

  onChangepassword(e){
    this.setState({
        password: e.target.value
    });
  }

  onlogin(e){
    this.setState({isLoading:true})
    e.preventDefault();
    const newLogin ={
      email: this.state.userid,
      password: this.state.password,
    }

    axios.post('https://api.testacademia.com/admin/auth/login', newLogin)
    .then(res => {
        console.log(res.data)
        if(res.data!==''){
          this.setState({isLoading:false})
          this.props.history.push('/admin/Users');
        }else{
          alert("Enter Right Email and Password")
        }
        })
    .catch(e=>{
      this.setState({isLoading:false})
      alert("Enter Right Email and Password")
    })

    this.setState({
        userid: '',
        password:'',
      })
    }



  render() {
    return (
      <>
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
        <Col lg="5" md="7" style={{"position":"relative", "left":"300px"}}>
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <img src={require("assets/img/aspirelogo.jpg")} style={{"width":"200px"}}/>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" value={this.state.userid}
                          onChange={this.onChangeuserid}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" 
                        value={this.state.password}
                        onChange={this.onChangepassword}/>
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  
                </div>
                <div className="text-center">
                <Link to="/admin/Users">
                  <Button className="my-4" color="primary" onClick={this.onlogin}>
                    Sign in
                  </Button>
                  </Link>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>}
      </>
    );
  }
}

export default Login;
