import React from "react";
import { Button } from "reactstrap";

class PackageDetails extends React.Component{

    constructor(props){
        super(props);

        this.state={
            package:this.props.package,
            testcategory:'',
            showdetail:true
        }

        this.onsubmit = this.onsubmit.bind(this);
        this.handlechange = this.handlechange.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.package !== this.props.package) {
            this.setState({package: this.props.package});
          }
    }

    handlechange(evt){
        this.setState({ testcategory: evt.target.value });
    }

    onsubmit(){
        this.setState({
            showdetail:false
        })
        var pack = {
            packageid: this.state.package._id,
            packagename: this.state.package.packagename,
            testcategory : this.state.testcategory
        };
        this.props.receivepack(pack);
    }

    render(){
        return(
            <>
                {this.state.showdetail &&<tr>
                    <th>{this.state.package.packagename}</th>
                    <td><input type="radio" name={this.state.package.packagename} value="Scored Test" onChange={this.handlechange}/> Scored Test</td>
                    <td><input type="radio" name={this.state.package.packagename} value="Unscored Test" onChange={this.handlechange}/> Unscored Test</td>
                    <td><input type="radio" name={this.state.package.packagename} value="Sectional Test" onChange={this.handlechange}/> Sectional Test</td>
                    <td><Button color="primary" size = "sm" onClick={()=>{this.onsubmit()}}>Submit</Button></td>
                </tr>}
            </>
        )
    }
}

export default PackageDetails;