import React from "react";
import "../../assets/css/custom.css";

import 'react-quill/dist/quill.snow.css';
import "./panel.css";

import SelectionHighlighter from 'react-highlight-selection';

class WritingPara extends React.Component{

    constructor(props){
        super(props);
        this.state={
            paragraph: props.passage,
            image: props.image,
            task: props.task
        }
    }
    
    selectionHandler(selection) {
        //do something with selection
        console.log(selection);
    }

    componentDidUpdate(prevProps){
        if(prevProps.passage !== this.props.passage || 
            prevProps.image !== this.props.image || 
            prevProps.task !== this.props.task) {
            this.setState({
                paragraph: this.props.passage,
                image: this.props.image,
                task: this.props.task
            });
          }
    }

    render(){

        return(
            <>
                {/* <p style={{width:"800px", wordWrap:"break-word", padding:"20px", border:"1px solid"}}>
                    <SelectionHighlighter
                        text={text}
                        selectionHandler={this.selectionHandler}
                        customClass='custom-class'
                    />
                </p> */}
                <div className={"writing-passage_"+this.props.setting}>
                    <p style={{fontWeight:"bold"}}>Task {this.state.task}</p>
                    <div dangerouslySetInnerHTML={{ __html: this.state.paragraph }}/>
                    <img src={this.state.image} alt=""/>
                </div>

            </>
        )
    }
}

export default WritingPara;