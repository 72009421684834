import React from "react";

import {
    Badge,
    Button,
    Card,
    Form,
    Input,
    InputGroup,
    Table,
    Row,
    Col
  } from "reactstrap";

  import "./addteststyle.css";

class TestDetail extends React.Component{

    constructor(props){
        super(props);

        this.state={
            testdetails: this.props.testdetails
        }
    }
    render(){
        return(
            <>
                <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                    

                    <Row >
                        <Col>
                            <p><strong>Test Name: </strong> {this.state.testdetails.testname}</p>
                        </Col>
                        <Col>
                            <p><strong>Main Category: </strong>{this.state.testdetails.maincategory}</p>
                        </Col>
                        <Col>
                            <p><strong>Duration: </strong>{this.state.testdetails.duration}</p>
                        </Col>
                        <Col>
                            <p><strong>Total Marks: </strong>{this.state.testdetails.totalmarks}</p>
                        </Col>

                        <Col>
                            <p><strong>Package: </strong>{this.state.testdetails.premium}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><strong>Test Instruction: </strong><div dangerouslySetInnerHTML={{ __html: this.state.testdetails.testinstruction }} 
                    style={{width:"700px", wordWrap:"break-word"}} />  </p>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}

export default TestDetail;