import React from "react";
import axios from "axios";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

class UserRow extends React.Component{

    constructor(props){
        super(props);
        this.state={
            user:this.props.user,
            package:[],
        }
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount(){
        const ctx = this;
        this.props.allpackages.map((pack) => {
            if(pack._id===this.state.user.userType){
                ctx.setState({
                    package: pack,
                })
            }
        })
    }

deleteUser(){
    confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete the user?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
                if(this.props.selectedCourse==="IELTS"){
                    axios.post("https://api.testacademia.com/admin/student/deletestudent/"+this.state.user._id)
                .then(res=>{
                    console.log(res.data);
                    alert(res.data)
                }).catch(err=>{
                    console.log(err)
                })
            }
            else{
                console.log(this.props.user);
                const name = this.props.selectedCourse.toLowerCase().replace(" ","","g")
                axios.post("https://api.testacademia.com/admin/"+name+"/student/deletestudent/"+this.state.user._id)
                .then(res=>{
                    console.log(res.data);
                    alert(res.data)
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
          },
          {
            label: 'No'
          }
        ]
    });
}


    render(){
        return(
            <>
            <tr>
                <th scope="row">{this.state.user.name}</th>
                <th scope="row">{this.state.user.email}</th>
                <th scope="row">{this.state.user.course}</th>
                <th scope="row">{this.state.package.packagename}</th>
                <th scope="row">{this.state.user.startDate.split("T")[0]}</th>
                <th scope="row">{this.state.user.endDate && this.state.user.endDate.split("T")[0]}</th>
                <th scope="row">{this.state.user.mobile}</th>
                <Link to={{pathname:"/admin/editstud/"+this.state.user._id, state:{student:this.state.user}}}>
                    <td className="text-red">
                    <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                        Edit
                    </td>
                </Link>
                <td className="text-red" onClick={() => this.deleteUser()}>
                    <i className="ni ni-basket text-red" style={{marginRight:"5px"}} />{" "}
                    Delete
                </td>
            </tr>
            </>
        )
    }
}

export default UserRow;