import React from "react";
import axios from "axios";
import '../../../node_modules/video-react/dist/video-react.css';
import ReactPlayer from 'react-player';
import Loader from 'react-loader-spinner'
import classnames from "classnames";


import {
  Card,
  CardHeader,
  Label,
  Row,
  Col,
  CardTitle,
  CardText,
  Table,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";

import SingleVideo from "../course/SingleVideo";
import FreeVideoRow from "../course/FreeVideoRow";
import PremiumVideoRow from "../course/PremiumVideoRow";
import {LoadVideolist} from "../package/LoadPackageListfn"
class Course extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      course:'IELTS',
      videos: [],
      currentvideo:'',
      singlevideo:{
        videoname:"Testacademia IELTS",
        videodesc:"The videos covering all the modules in IELTS with Grammar, are structured in depth elucidating  the crucial concepts in each section and focusing on their correct application in practice tests. Every detail is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/434395242.m3u8?s=b0a569003520a03a181e3062eb8d1f5ec1742ce9"
      },
      isLoading:true
  }
  this.videoList = this.videoList.bind(this);
  this.loadVideo = this.loadVideo.bind(this);
  this.onChangeCourse = this.onChangeCourse.bind(this);
  this.getOnClickVideoId = this.getOnClickVideoId.bind(this);
  }

  componentDidMount(){
    axios.post('https://api.testacademia.com/admin/course/showallvideo')
        .then(res => {
            this.setState({videos: res.data},()=>{
              this.setState({isLoading:false})
            });
        })
        .catch(function(error){
            console.log(error);
        });
  }

  async onChangeCourse(evt){
    this.setState({
      course:evt.target.value,
      isLoading:true
    })
    var list = await LoadVideolist(evt.target.value)
    this.setState({
      videos:list
    },()=>{
      this.setState({
        isLoading:false
      })
    })
  }

  getOnClickVideoId(videoindex,videocourse){
    this.setState({
      currentvideo:videoindex,
      singlevideo:this.state.videos[videoindex]
    })
  }

  videoList(){
	const ctx = this;
	const readingrows = [];
    const speakingrows = [];
    const listeningrows = [];
    const writingrows = [];
	const grammarrows = [];
	const quantarthmetic = [];
	const quantalgebra = [];
	const quantgeo = [];
	const quantdata = [];
	const verbal = [];
	const verbalreading = [];
	const verbalcrict = [];
	const analytical = [];
	const sentence = [];
	const quantmis =[];
	const essay=[];
	const basicgrammar=[];
	const advangrammar=[];
	const businessetiq=[];
	const integrated = [];
    const videorows = [];

    if(this.state.videos){
		this.state.videos.map(function(currentVideo, i){
		if(currentVideo.subheading === "Speaking" ){
			speakingrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
			
		else if(currentVideo.subheading === "Reading" ){
			readingrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}

		else if(currentVideo.subheading === "Listening" ){
			listeningrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}

		else if(currentVideo.subheading === "Writing" ){
			writingrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}

		else if(currentVideo.subheading === "Grammar" ){
			grammarrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Quant (Arithmetic)" ){
			quantarthmetic.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Quant (Algebra)" ){
			quantalgebra.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Quant (Geometry)" ){
			quantgeo.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Quant (Data Analysis)" ){
			quantdata.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Verbal" ){
			verbal.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		else if(currentVideo.subheading === "Verbal (Critical Reasoning)" ){
			verbalcrict.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		else if(currentVideo.subheading === "Integrated Reasoning" ){
			integrated.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}  
		else if(currentVideo.subheading === "Verbal (Reading Comprehension)" ){
			verbalreading.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Analytical Writing" ){
			analytical.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Verbal (Sentence Correction)" ){
			sentence.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		else if(currentVideo.subheading === "Quant (Miscellaneous Topics)" ){
			quantmis.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		else if(currentVideo.subheading === "Essay" ){
			essay.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		} 
		else if(currentVideo.subheading === "Basic Grammar" ){
			basicgrammar.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		else if(currentVideo.subheading === "Advanced Grammar" ){
			advangrammar.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		else if(currentVideo.subheading === "Business Etiquette" ){
			businessetiq.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
		}
		});

		if(speakingrows.length!==0){
		videorows.push(
			<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Speaking</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{speakingrows}
			</>
		)
		}
		if(readingrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Reading</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{readingrows}
				</>
			)
		}
		if(listeningrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Listening</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{listeningrows}
				</>
			)
		}
		if(writingrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Writing</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{writingrows}
				</>
			)
		}
		if(grammarrows.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Grammar</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{grammarrows}
				</>
			)
		}
		if(quantarthmetic.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Arithmetic)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantarthmetic}
				</>
			)
		}
		if(quantalgebra.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Algebra)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantalgebra}
				</>
			)
		}
		if(quantgeo.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Geometry)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantgeo}
				</>
			)
		}
		if(quantdata.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Data Analysis)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantdata}
				</>
			)
		}
		if(verbal.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Verbal</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{verbal}
				</>
			)
		}
		if(verbalcrict.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Verbal (Critical Reasoning)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{verbalcrict}
				</>
			)
		}
		if(verbalreading.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Verbal (Reading Comprehension)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{verbalreading}
				</>
			)
		}
		if(analytical.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Analytical Writing</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{analytical}
				</>
			)
		}
		if(integrated.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Integrated Reasoning</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{integrated}
				</>
			)
		}
		if(sentence.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Verbal (Sentence Correction)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{sentence}
				</>
			)
		}
		if(quantmis.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Quant (Miscellaneous Topics)</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{quantmis}
				</>
			)
		}
		if(essay.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Essay</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{essay}
				</>
			)
		}
		if(basicgrammar.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Basic Grammar</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{basicgrammar}
				</>
			)
		}
		if(advangrammar.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Advanced Grammar</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{advangrammar}
				</>
			)
		}
		if(businessetiq.length!==0){
			videorows.push(
				<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Business Etiquette</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{businessetiq}
				</>
			)
		}
	return videorows
  }
  
}

  loadVideo(){
    return (<SingleVideo video={this.state.singlevideo}/>);
  }

  render() {
    return (
      <div>
        <UserHeader />
        {/* Page content */}
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
          className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
      <Row>
        <Col >
          {this.loadVideo()}
        </Col>
        <Col style={{marginTop:"30px", height:"550px", overflowY:"scroll"}}>
          <Col className="" >
              <Card className="shadow">
                <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
                style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
              >
                  <label for="course" style={{marginRight:"10px"}}>Course:</label>
                  <select  id ="course"
                        list="course" 
                        name = "course"
                        value ={this.state.course} 
                        onChange={this.onChangeCourse}
                        >
                    <option value="IELTS">IELTS</option>
                    <option value="PTE">PTE</option>
                    <option value="GRE">GRE</option>
                    <option value="GMAT">GMAT</option>
                    <option value="TOEFL">TOEFL</option>
                    <option value="SAT">SAT</option>
                    <option value="OET">OET</option>
                    <option value="EXPERT ENGLISH">EXPERT ENGLISH</option>
                </select>
                  </Nav>
                  
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {this.videoList()}
                  </tbody>
                </Table>
              </Card>
            </Col>
        </Col>
        </Row>}
    </div>
    );
  }
}

export default Course;
