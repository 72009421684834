import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert'; // Import
import axios from "axios";
class QuesDetails extends React.Component{

    constructor(props){
        super(props);
        this.state={
            ques: this.props.question,
            questtype: this.props.questype,
            partid:this.props.partid,
            testid:this.props.testid,
            ispara:this.props.ispara,
        }
        this.deleteques = this.deleteques.bind(this);
    }
    deleteques(){
        console.log(this.state.ques._id);
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete the question?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {

                    axios.post("https://api.testacademia.com/admin/test/deleteques/"+this.state.ques._id, {partid: this.state.partid,testid:this.state.testid})
                    .then(res=>{
                        console.log(res.data);
                        alert(res.data);
                        window.location.reload();
                    }).catch(err=>{
                        console.log(err)
                    })
                }
              },
              {
                label: 'No'
              }
            ]
          });
    }

    renderrightanswer(){
        var rightanswers = ""
        for(let i=0;i<this.state.ques.rightanswer.length;i++){
            console.log(this.state.ques.rightanswer[i])
            if(i === this.state.ques.rightanswer.length-1){
                rightanswers = rightanswers + this.state.ques.rightanswer[i]
            }
            else{
                rightanswers = rightanswers + this.state.ques.rightanswer[i] + "/"
            }
        }
        return rightanswers;
    }

    render(){
        return(
            <>
            <div className="quesdetails">
                <table className="edittest">
                    <tr>
                        <th>Question Number:</th>
                        <td>{this.state.ques.questionnum}</td>
                    </tr>
                    <tr>
                        <th>Question:</th>
                        <td><p dangerouslySetInnerHTML={{ __html: this.state.ques.question }} 
                    style={{width:"700px", wordWrap:"break-word"}} /></td>
                    </tr>
                    <tr>
                        <th>Right Answer:</th>
                        <td>{this.renderrightanswer()}</td>
                    </tr>
                    {(this.state.questtype==="MCQ Single Right" || this.state.questtype==="MCQ Multiple Right") 
                    && this.state.ques.option1 &&
                    <tr>
                        <th>Option 1</th>
                        <td>{this.state.ques.option1}</td>
                    </tr>}
                    {(this.state.questtype==="MCQ Single Right" || this.state.questtype==="MCQ Multiple Right") 
                    && this.state.ques.option2 &&
                    <tr>
                        <th>Option 2</th>
                        <td>{this.state.ques.option2}</td>
                    </tr>}
                    {(this.state.questtype==="MCQ Single Right" || this.state.questtype==="MCQ Multiple Right") 
                    && this.state.ques.option3 &&
                    <tr>
                        <th>Option 3</th>
                        <td>{this.state.ques.option3}</td>
                    </tr>}
                    {(this.state.questtype==="MCQ Single Right" || this.state.questtype==="MCQ Multiple Right") 
                    && this.state.ques.option4 &&
                    <tr>
                        <th>Option 4</th>
                        <td>{this.state.ques.option4}</td>
                    </tr>}
                    {(this.state.questtype==="MCQ Single Right" || this.state.questtype==="MCQ Multiple Right") 
                    && this.state.ques.option5 &&
                    <tr>
                        <th>Option 5</th>
                        <td>{this.state.ques.option5}</td>
                    </tr>}
                    <tr><th>Answer Explaination:</th>
                    <td><p dangerouslySetInnerHTML={{ __html: this.state.ques.answerexplain }} 
                    style={{width:"700px", wordWrap:"break-word"}} /></td>
                    </tr>
                    <tr>
                    <td className="text-red" colSpan="2" style={{textAlign:"right"}}
                            onClick={()=>{this.deleteques()}}>
                                <i className="ni ni-fat-delete text-red" style={{marginRight:"5px"}}/>{" "}
                                Delete Ques
                        </td>
                        <td className="text-red" colSpan="8" style={{textAlign:"right"}}>
                            <Link to={{
                                    pathname:"/admin/editquesdetails/"+this.state.ques._id,
                                    state:{
                                        ques: this.state.ques,
                                        questype:this.state.questtype,
                                        testid:this.state.testid,
                                        partid:this.state.partid,
                                        ispara:this.state.ispara
                                    }}}>
                                <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                                Edit
                            </Link>
                        </td>
                    </tr>
                </table>
            </div>
            </>
        )
    }
}

export default QuesDetails;