export const evaluateRead = (readingResultList, testname)=>{
    var bandscore =0;
    var totalscore = 0;     
    for(let i=0; i<readingResultList.length; i++){
        totalscore = totalscore + readingResultList[i].score
    }
    var readingtype = testname.split(" ")[0];

    if(readingtype==="General"){
        if(totalscore>=6 && totalscore<=8) bandscore=2.5;
        else if(totalscore>=9 && totalscore<=11) bandscore=3;
        else if(totalscore>=12 && totalscore<=14) bandscore=3.5;
        else if(totalscore>=15 && totalscore<=18) bandscore=4;
        else if(totalscore>=19 && totalscore<=22) bandscore=4.5;
        else if(totalscore>=23 && totalscore<=26) bandscore=5;
        else if(totalscore>=27 && totalscore<=29) bandscore=5.5;
        else if(totalscore>=30 && totalscore<=31) bandscore=6;
        else if(totalscore>=32 && totalscore<=33) bandscore=6.5;
        else if(totalscore>=34 && totalscore<=35) bandscore=7;
        else if(totalscore===36) bandscore=7.5;
        else if(totalscore>=37 && totalscore<=38) bandscore=8;
        else if(totalscore===39) bandscore=8.5;
        else if(totalscore===40) bandscore=9;
    }else{
        if(totalscore>=4 && totalscore<=5) bandscore=2.5;
        else if(totalscore>=6 && totalscore<=7) bandscore=3;
        else if(totalscore>=8 && totalscore<=9) bandscore=3.5;
        else if(totalscore>=10 && totalscore<=12) bandscore=4;
        else if(totalscore>=13 && totalscore<=14) bandscore=4.5;
        else if(totalscore>=15 && totalscore<=18) bandscore=5;
        else if(totalscore>=19 && totalscore<=22) bandscore=5.5;
        else if(totalscore>=23 && totalscore<=26) bandscore=6;
        else if(totalscore>=27 && totalscore<=29) bandscore=6.5;
        else if(totalscore>=30 && totalscore<=32) bandscore=7;
        else if(totalscore>=33 && totalscore<=34) bandscore=7.5;
        else if(totalscore>=35 && totalscore<=36) bandscore=8;
        else if(totalscore>=37 && totalscore<=38) bandscore=8.5;
        else if(totalscore>=39 && totalscore<=40) bandscore=9;
    }

    return bandscore;
}