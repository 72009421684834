import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class TestNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/user/tests"
            >
              {this.props.brandText}
              <img src={require("assets/img/aspirelogo.jpg")} style={{height:"100px", marginRight:"20px"}}/>
            </Link>
            
            
          </Container>
        </Navbar>
      </>
    );
  }
}

export default TestNavbar;
