import Users from "views/user/Users.jsx";
import Course from "views/course/Course.jsx";
import Tests from "views/testpanel/Tests.jsx";
import Package from "views/package/Package.jsx";
import LiveTest from "views/testpanel/LiveTest.jsx";


import Profile from "views/examples/Profile.jsx";
import Register from "views/examples/Register.jsx";
import Login from "views/examples/Login.jsx";
import Review from "views/examples/Review.jsx";
import Blog from "views/blog/Blog.jsx";

import AddStudents from "views/user/AddStudents.jsx";
import EditStudents from "views/user/EditStudents.jsx";
import AddCourse from "views/course/AddCourse.jsx";
import AddBlog from "views/blog/AddBlog.jsx";
import EditCourse from "views/course/EditCourse.jsx";
import AddTest from "views/addtest/AddTest.jsx";

import AddPackage from "views/package/AddPackage.jsx";
import EditPackage from "views/package/EditPackage.jsx";
import ViewPackage from "views/package/ViewPackage.jsx";

import Institute from "views/institute/Institute.jsx"
import AddInstitute from "views/institute/AddInstitute.jsx";
import EditInstitute from "views/institute/EditInstitute.jsx";

import TestDetails from "views/edittest/TestDetails";
import EditTestDetails from "./views/edittest/EditTestDetails";
import EditPartDetails from "./views/edittest/EditPartDetails";
import EditQuesDetails from "./views/edittest/EditQuesDetails";
import AddNewPart from "./views/edittest/AddNewPart";
import AddNewQues from "./views/edittest/AddNewQues";


var routes = [
  
  {
    path: "/Users",
    name: "Users",
    icon: "ni ni-tv-2 text-primary",
    component: Users,
    layout: "/admin",
    show: "yes"
  },
  {
    path: "/Course/",
    name: "Course",
    icon: "ni ni-book-bookmark text-blue",
    component: Course,
    layout: "/admin",
    show: "yes"
  },
  {
    path: "/Tests",
    name: "Tests",
    icon: "ni ni-ruler-pencil text-red",
    component: Tests,
    layout: "/admin",
    show: "yes"
  },
  {
    path: "/Package",
    name: "Package",
    icon: "ni ni-folder-17 text-green",
    component: Package,
    layout: "/admin",
    show: "yes"
  },
  {
    path: "/Institute",
    name: "Institute",
    icon: "ni ni-hat-3 text-black",
    component: Institute,
    layout: "/admin",
    show: "yes"
  },
  {
    path: "/addstudent",
    component: AddStudents,
    layout: "/admin",
    name: "Add Students",
  },
  {
    path: "/editstud/:id",
    component: EditStudents,
    layout: "/admin",
    name: "Edit Students",
  },
  {
    path: "/addvideo",
    component: AddCourse,
    layout: "/admin",
    name: "Add Course",
  },
  {
    path: "/addblog",
    component: AddBlog,
    layout: "/admin",
    name: "Add Blog",
  },
  {
    path: "/addpackage",
    component: AddPackage,
    layout: "/admin",
    name: "Add Package",
  },
  {
    path: "/addinstitute",
    component: AddInstitute,
    layout: "/admin",
    name: "Add Institute",
  },
  {
    path: "/editinstitute/:id",
    component: EditInstitute,
    layout: "/admin",
    name: "Edit Institute",
  },
  {
    path: "/editvideo/:id",
    component: EditCourse,
    layout: "/admin",
    name: "Edit Course",
  },
  {
    path: "/editpackage/:id",
    component: EditPackage,
    layout: "/admin",
    name: "Edit Package",
  },
  {
    path: "/viewpackage/:id",
    component: ViewPackage,
    layout: "/admin",
    name: "View Package",
  },
  {
    path: "/addTest",
    component: AddTest,
    layout: "/admin",
    name: "Add Test",
  },
  {
    path: "/user-profile",
    name: "Flash Cards",
    icon: "ni ni-credit-card text-red",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "ni ni-single-copy-04 text-yellow",
    component: Blog,
    layout: "/admin",
    show: "yes"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Review,
    layout: "/adminN"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/livetest/:id",
    component: LiveTest,
    layout: "/test",
  },
  {
    path: "/edittest/:id",
    name:"Edit Test",
    component: TestDetails,
    layout: "/admin",
  },
  {
    path: "/edittestdetails/:id",
    name:"Edit Test Details",
    component: EditTestDetails,
    layout: "/admin",
  },
  {
    path: "/editpartdetails/:id",
    name:"Edit Part Details",
    component: EditPartDetails,
    layout: "/admin",
  },
  {
    path: "/editquesdetails/:id",
    name:"Edit Ques Details",
    component: EditQuesDetails,
    layout: "/admin",
  },
  {
    path: "/addnewpart",
    name:"Add New Part",
    component: AddNewPart,
    layout:"/admin"
  },
  {
    path: "/addnewques",
    name:"Add New Question",
    component: AddNewQues,
    layout:"/admin"
  }
];
export default routes;
