import React from "react";

// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import AddTestDetails from "./AddTestDetails";


export default class AddTest extends React.Component {

    
    constructor(props){
        super(props);

        this.state={
          showtestcomp: true,
          showpartcomp: false,
          showquescomp: false,
          startques:0,
          endques:0
        }


        this.showpart = this.showpart.bind(this);
        //this.showques = this.showques.bind(this);
      }

      showpart(testdetails){
        this.setState({
          showtestcomp: false,
          showpartcomp: true,
          showquescomp: false
        })
        console.log(testdetails);
      }
    
      render() {
    
        return (
          <>
            <UserHeader />
            {this.state.showtestcomp && <AddTestDetails/>}
          </>
        );
      }
    }
    
