import React from 'react';
import "../testpanel/panel.css";
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';

import{
    Row,
    Col,
    Table
} from "reactstrap";
import EachHeading from './EachHeading';

class QuesParaHead extends React.Component{
    constructor(props){
        super(props)
        this.state={
            currentques:this.props.currentques,
            questions:this.props.quesdata,
            part:this.props.part,
            listofheadings:this.props.listofheadings
        }
        this.results = [];
        this.loadlistofheadings = this.loadlistofheadings.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata !== this.props.quesdata) {
            this.setState({questions: this.props.quesdata});
          }
        if(prevProps.listofheadings !== this.props.listofheadings) {
            this.setState({listofheadings: this.props.listofheadings});
          }
        if(prevProps.question !== this.props.question) {
            this.setState({question: this.props.question});
          }
        if(prevProps.part !== this.props.part) {
            this.setState({part: this.props.part});
        }
        if(prevProps.currentques !== this.props.currentques){
            this.setState({currentques:this.props.currentques},()=>{console.log(this.state.currentques)});
        }
    }

    loadlistofheadings(){
        const ctx = this;
        const headingrows = [];
        if(this.state.listofheadings){
            this.state.listofheadings.map(function(heading, i){
                headingrows.push(<div>
                                    <DragDropContainer 
                                        targetKey="headings" 
                                        dragData={heading}>
                                            <button className={"headingTD_"+ctx.props.setting}
                                                dangerouslySetInnerHTML={{ __html: heading  }} />
                                    </DragDropContainer>
                                </div>)
            })
        }
        return headingrows;
    }

    render(){
        return(
            <>
                {this.loadlistofheadings()}

            </>
        )
    }
}

export default QuesParaHead;