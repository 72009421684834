import React from "react";
import UserHeader from "components/Headers/UserHeader.jsx";
import QuesInput from "./QuesInput";
import axios from "axios";

class AddNewQues extends React.Component{

    constructor(props){
        super(props);
        this.state={
            part:this.props.location.state.part,
            testid:this.props.location.state.testid
        }
        this.renderquescomp = this.renderquescomp.bind(this);
        this.appendquesarray = this.appendquesarray.bind(this);
    }

    renderquescomp(){
        return <QuesInput parttype={this.state.part.parttype} 
                            ispara={this.state.part.ispara} 
                            quesnum={0} 
                            appendquesarray={this.appendquesarray}/>
    }

    appendquesarray(quesid, quesdata){
        console.log(quesdata)
        axios.post('https://api.testacademia.com/admin/test/partaddnewques/'+this.state.part._id, 
                    {testid:this.state.testid,quesid: quesid,quesdata:quesdata})
                    .then(res=>{
                        console.log(res.data);
                        alert(res.data);
                        window.location.reload();
                    })
    }

    render(){
        return(
            <>
                <UserHeader />
                {this.renderquescomp()}
            </>
        )
    }
}

export default AddNewQues;