import React from "react";
import axios from 'axios';
// import { Button } from "reactstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Table,
  Row,
  Col,
  Button,
  NavLink,
  Nav,
  Card,
  TabPane,
  CardHeader,
  TabContent
} from "reactstrap";

import TestRow from "./TestRow";
import PremiumVideoRow from "./PremiumVideoRow";
import UserHeader from "components/Headers/UserHeader.jsx";


class ViewPackage extends React.Component {

  constructor(props){
    super(props);
    this.state={
      tabs: 1,
      package:'',
      scoredtests:[],
      unscoredtests:[],
      sectionaltests:[],
      videos:[],
      course:this.props.location.state.package.course
    }

    this.renderscored = this.renderscored.bind(this);
    this.renderunscored = this.renderunscored.bind(this);
    this.rendersectional = this.rendersectional.bind(this);
  }

  componentDidMount(){
    const id = this.props.match.params.id;
    const ctx = this;
    if(this.state.course==="IELTS"){
        axios.post('https://api.testacademia.com/admin/package/onepackage/'+id)
        .then(res => {
            this.setState({package: res.data})
            if(this.state.package.scored){
              this.state.package.scored.map(function(test){
                if(test){
                axios.post('https://api.testacademia.com/admin/test/onetest/'+test)
                .then(res => {
                    ctx.setState({scoredtests: [...ctx.state.scoredtests,res.data]})
                });
              }
              else{
                console.log("no test")
              }
              })
            } 
            if(this.state.package.unscored){
              this.state.package.unscored.map(function(test){
                if(test){
                axios.post('https://api.testacademia.com/admin/test/onetest/'+test)
                .then(res => {
                    ctx.setState({unscoredtests: [...ctx.state.unscoredtests,res.data]})
                });}
              })
            }
            if(this.state.package.sectional){
              this.state.package.sectional.map(function(test){
                if(test){
                axios.post('https://api.testacademia.com/admin/test/onetest/'+test)
                .then(res => {
                    ctx.setState({sectionaltests: [...ctx.state.sectionaltests,res.data]})
                });
              }
              })
            } 
            if(this.state.package.videos){
              this.state.package.videos.map(function(video){
                if(video){
                axios.post('https://api.testacademia.com/admin/course/showonevideo/'+video)
                .then(res => {
                    ctx.setState({videos: [...ctx.state.videos,res.data]})
                });
              }
              })
            }    
        })
        .catch(function(error){
            console.log(error);
        });
      }
    else{
      var name = this.state.course.toLowerCase().replace(" ","","g")
      axios.post('https://api.testacademia.com/admin/'+name+'/package/onepackage/'+id)
        .then(res => {
            this.setState({package: res.data})
            if(this.state.package.scored){
              this.state.package.scored.map(function(test){
                axios.post('https://api.testacademia.com/admin/'+name+'/test/onetest/'+test)
                .then(res => {
                    ctx.setState({scoredtests: [...ctx.state.scoredtests,res.data]})
                });
              })
            } 
            if(this.state.package.unscored){
              this.state.package.unscored.map(function(test){
                axios.post('https://api.testacademia.com/admin/'+name+'/test/onetest/'+test)
                .then(res => {
                    ctx.setState({unscoredtests: [...ctx.state.unscoredtests,res.data]})
                });
              })
            }
            if(this.state.package.sectional){
              this.state.package.sectional.map(function(test){
                axios.post('https://api.testacademia.com/admin/'+name+'/test/onetest/'+test)
                .then(res => {
                    ctx.setState({sectionaltests: [...ctx.state.sectionaltests,res.data]})
                });
              })
            } 
            if(this.state.package.videos){
              this.state.package.videos.map(function(video){
                axios.post('https://api.testacademia.com/admin/'+name+'/course/showonevideo/'+video)
                .then(res => {
                    ctx.setState({videos: [...ctx.state.videos,res.data]})
                });
              })
            }    
        })
        .catch(function(error){
            console.log(error);
        });
    }
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  renderscored(){
    if(this.state.scoredtests)
    return this.state.scoredtests.map(function(currentTest, i){
        return <TestRow test={currentTest} />;
    });
  }

  renderunscored(){
    if(this.state.unscoredtests)
    return this.state.unscoredtests.map(function(currentTest, i){
        return <TestRow test={currentTest} />;
    });
  }

  rendersectional(){
    if(this.state.sectionaltests)
    return this.state.sectionaltests.map(function(currentTest, i){
        return <TestRow test={currentTest} />;
    });
  }

  rendervideos(){
    const videorows = [];
    if(this.state.videos){
      this.state.videos.map(function(currentVideo, i){
          videorows.push( <PremiumVideoRow video={currentVideo} />);
      });
      return videorows;
    }
    
  }

  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
                style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
              >
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 1)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px",  "width":"150px", height:"40px"}}
                  >
                    <center>Scored Tests</center>
                  </NavLink>
                  <NavLink
                  aria-selected={this.state.tabs === 2}
                  className={classnames("mb-sm-2 mb-md-2", {
                    active: this.state.tabs === 2
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 2)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Unscored Tests</center>
                  </NavLink>
                  <NavLink
                  aria-selected={this.state.tabs === 3}
                  className={classnames("mb-sm-2 mb-md-2", {
                    active: this.state.tabs === 3
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 3)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Sectional Tests</center>
                  </NavLink>
                  <NavLink
                  aria-selected={this.state.tabs === 4}
                  className={classnames("mb-sm-2 mb-md-2", {
                    active: this.state.tabs === 4
                  })}
                  onClick={e => this.toggleNavs(e, "tabs", 4)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Videos</center>
                  </NavLink>                  
              </Nav>

              <Card className="shadow" style={{marginLeft:"20px",marginTop:"30px", marginRight:"20px"}}>
              <div>
                <TabContent activeTab={"tabs" + this.state.tabs}>
                <TabPane tabId="tabs1">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Scored Tests</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderscored()}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs2">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Unscored Tests</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>      
                            </thead>
                            <tbody>
                              {this.renderunscored()}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs3">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Sectional Tests</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>
                            </thead>
                            <tbody>
                              {this.rendersectional()}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs4">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Videos</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Video</th>
                                </tr>
                            </thead>
                            <tbody>
                              {this.rendervideos()}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                </TabContent>
                </div>
            </Card>

      </>
    );
  }
}

export default ViewPackage;
