import React from "react";
import { Button } from "reactstrap";

class PackageDetails extends React.Component{

    constructor(props){
        super(props);

        this.state={
            package:this.props.package,
            testcategory:'',
            showdetail:true,
            id:this.props.id
        }

        this.onsubmit = this.onsubmit.bind(this);
        this.handlechange = this.handlechange.bind(this);
    }

    componentDidMount(){
        if(this.state.package.scored.includes(this.state.id)){
            this.setState({
                testcategory:"Scored Test"
            })
        }else if(this.state.package.unscored.includes(this.state.id)){
            this.setState({
                testcategory:"Unscored Test"
            })
        }else if(this.state.package.sectional.includes(this.state.id)){
            this.setState({
                testcategory:"Sectional Test"
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.package !== this.props.package && prevProps.id !== this.props.id) {
            this.setState({
                package: this.props.package,
                id: this.props.id
            });
          }
    }

    handlechange(evt){
        this.setState({ testcategory: evt.target.value });
    }

    onsubmit(){
        this.setState({
            showdetail:false
        })
        var pack = {
            packageid: this.state.package._id,
            packagename: this.state.package.packagename,
            testcategory : this.state.testcategory
        };
        this.props.receivepack(pack);
    }

    check

    render(){
        return(
            <>
                {this.state.showdetail &&<tr>
                    <th>{this.state.package.packagename}</th>
                    <td><input type="radio" name={this.state.package.packagename} 
                    checked={this.state.testcategory==="Scored Test"} 
                    value="Scored Test" 
                    onChange={this.handlechange}/> 
                        Scored Test
                    </td>
                    <td><input type="radio" 
                    name={this.state.package.packagename} 
                    checked={this.state.testcategory==="Unscored Test"}
                    value="Unscored Test" 
                    onChange={this.handlechange}/> 
                        Unscored Test
                    </td>
                    <td><input type="radio" 
                    name={this.state.package.packagename}
                    checked={this.state.testcategory==="Sectional Test"} 
                    value="Sectional Test" 
                    onChange={this.handlechange}/> 
                        Sectional Test
                    </td>
                    <td><Button color="primary" size = "sm" onClick={()=>{this.onsubmit()}}>Submit</Button></td>
                </tr>}
            </>
        )
    }
}

export default PackageDetails;