import React from "react";

import "./edittest.css";
import QuesDetails from "./QuesDetails";
import { confirmAlert } from 'react-confirm-alert'; // Import
import axios from "axios"
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

class PartDetails extends React.Component{

    constructor(props){
        super(props);
        this.state={
            part: this.props.part,
            testid: this.props.testid
        }
        this.loadquestions = this.loadquestions.bind(this);
        this.deletepart = this.deletepart.bind(this);
    }


    loadquestions(){
        const quesrows = [];
        if(this.state.part)
        for (var i=0; i <this.state.part.quesdata.length; i++){
            quesrows.push(<QuesDetails 
                            partid={this.state.part._id} 
                            parttype={this.state.part.parttype}
                            testid={this.state.testid}
                            ispara={this.state.part.ispara}
                            question={this.state.part.quesdata[i]} 
                            questype={this.state.part.parttype}/>)
        }
        return quesrows;
    }
    deletepart(){

        console.log(this.state.testid)

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete the part?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    axios.post("https://api.testacademia.com/admin/test/deletepart/"+this.state.part._id, {testid: this.state.testid})
                    .then(res=>{
                        console.log(res.data);
                        alert(res.data)
                    }).catch(err=>{
                        console.log(err)
                    })
                }
              },
              {
                label: 'No'
              }
            ]
          });
    }

    rendercoordinates(){
        if(this.state.part){
            var coordinatesstr = [];
            for(let i=0; i<this.state.part.coordinates.length; i++){
                coordinatesstr.push("(x: "+this.state.part.coordinates[i].x+", y: "+this.state.part.coordinates[i].y+") ");
            }
            return coordinatesstr;
        }
    }

    renderheading(){
        var headings = ""
        for(let i=0;i<this.state.part.listofheadings.length;i++){
            console.log(this.state.part.listofheadings[i])
            if(i === this.state.part.listofheadings.length-1){
                headings = headings + this.state.part.listofheadings[i]
            }
            else{
                headings = headings + this.state.part.listofheadings[i] + "/"
            }
        }
        return headings;
    }

    renderoption(){
        var options = ""
        for(let i=0;i<this.state.part.listofoptions.length;i++){
            console.log(this.state.part.listofoptions[i])
            if(i === this.state.part.listofoptions.length-1){
                options = options + this.state.part.listofoptions[i]
            }
            else{
                options = options + this.state.part.listofoptions[i] + "/"
            }
        }
        return options;
    }

    render(){
        return(
            <>
            <div className="partdetails">
                <table className="edittest">
                    <tr>
                        <th>Part Type:</th>
                        <td>{this.state.part.parttype}</td>
                        <th>Start Ques:</th>
                        <td>{this.state.part.startques}</td>
                        <th>End Ques:</th>
                        <td>{this.state.part.endques}</td>
                        <th>Part Category:</th>
                        <td>{this.state.part.partcategory}</td>
                        
                    </tr>
                    <tr><th>Is question of paragraph type?</th>
                        <td>{this.state.part.ispara}</td></tr>
                    <tr>
                        <th>Part Instruction:</th>
                        <td colSpan="7"><div dangerouslySetInnerHTML={{ __html: this.state.part.partinstruction }} 
                    style={{width:"700px", wordWrap:"break-word"}} /> </td>
                    </tr>
                    <tr>
                        <th>Coordinates:</th>
                        <td colSpan="7">{this.rendercoordinates()}</td>
                    </tr>
                    {this.state.part.passage &&
                    <tr>
                        <th>Passage:</th>
                        <td colSpan="7"><div dangerouslySetInnerHTML={{ __html: this.state.part.passage }} 
                    style={{width:"700px", wordWrap:"break-word"}} /> </td>
                    </tr>}
                    {this.state.part.audio &&
                    <tr>
                        <th>Audio:</th>
                        <td colSpan="7">{this.state.part.audio}</td>
                    </tr>}
                    {this.state.part.image &&
                    <tr>
                        <th>Image:</th>
                        <td colSpan="7" style={{maxWidth:"200px", overflow:"hidden"}}>{this.state.part.image}</td>
                    </tr>}
                    {this.state.part.cuetimer &&
                    <tr>
                        <th>Cue Card Timer:</th>
                        <td colSpan="7" style={{maxWidth:"200px", overflow:"hidden"}}>{this.state.part.cuetimer}</td>
                    </tr>
                    }
                    {this.state.part.parttype ==="Matrix" &&<tr>
                        <th>Matrix</th>
                        <th>Start Option:</th>
                        <td>{this.state.part.startoption}</td>
                        <th>End Option:</th>
                        <td>{this.state.part.endoption}</td>
                    </tr>}
                    {(this.state.part.parttype ==="List of Headings" ||
                    this.state.part.parttype ==="Paragraph Headings") &&<tr>
                        <th>List of Headings</th>
                        <th>No of Headings:</th>
                        <td>{this.state.part.noofheadings}</td>
                        <th>Headings:</th>
                        <td>{this.renderheading()}</td>
                    </tr>}
                    {this.state.part.parttype ==="Matching" &&<tr>
                        <th>Matching</th>
                        <th>Question Label:</th>
                        <td>{this.state.part.queslabel}</td>
                        <th>Option Label:</th>
                        <td>{this.state.part.optionlabel}</td>
                        <th>No of Options:</th>
                        <td>{this.state.part.noofoptions}</td>
                        <th>List of Options:</th>
                    </tr>}
                    {this.state.part.parttype ==="Matching" &&<tr>
                        <td colSpan="8">
                          {this.renderoption()}  
                        </td>
                    </tr>}
                    {this.state.part.parttype ==="Single Part Multiple Question" &&
                        <tr>
                            <th>Question:</th>
                            <td colSpan="7"><div dangerouslySetInnerHTML={{ __html: this.state.part.singlepartques }} /></td>
                        </tr>}
                    {this.state.part.parttype ==="Single Part Multiple Question" &&    
                    <tr><th>List of Options:</th>
                        <td colSpan="8">
                          {this.renderoption()}  
                        </td>
                    </tr>}
                    <tr>
                    <td className="text-red" colSpan="6" style={{textAlign:"right"}}
                            onClick={()=>{this.deletepart()}}>
                                <i className="ni ni-fat-delete text-red" style={{marginRight:"5px"}}/>{" "}
                                    Delete Part
                        </td>
                        <td className="text-red" style={{textAlign:"right"}}>
                            <Link to={{
                                pathname:"/admin/addnewques",
                                state:{
                                    part: this.state.part,
                                    testid:this.state.testid

                                }}}>
                                <i className="ni ni-fat-add text-red" style={{marginRight:"5px"}}/>{" "}
                                    Add New Ques
                            </Link>
                        </td>
                            <td className="text-red" colSpan="8" style={{textAlign:"right"}}>
                                <Link to={{
                                    pathname:"/admin/editpartdetails/"+this.state.part._id,
                                    state:{
                                        part: this.state.part,
                                        testid:this.state.testid
                                    }}}>
                                    <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                                    Edit
                                </Link>
                            </td>
                        </tr>
                </table>
                {this.loadquestions()}
            </div>
            </>
        )
    }
}

export default PartDetails;