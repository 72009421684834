import React from "react";

import { NavLink as Link } from "react-router-dom";

import {
    Button,
    Card,
    CardHeader,
    Table,
    Row,
}from "reactstrap";

import ListeningInstruction from "./ListeningInstruction.jsx"
class TestInstruction extends React.Component{

    constructor(props){
        super(props)
    }


    render(){
        return(
            <>
            <Card className="shadow" style={{marginLeft:"250px",marginTop:"50px", marginRight:"250px"}}>
              <div>
                    <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">{this.props.test.testname}</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Test Details</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Test Name:</th>
                                    <td></td>
                                    <td>
                                    {this.props.test.testname}
                                    </td>
                                </tr> 
                                {this.props.test.duration && <tr>
                                    <th scope="row">Test Duration:</th>
                                    <td></td>
                                    <td>
                                    {this.props.test.duration}
                                    </td>
                                </tr>}
                                {this.props.test.totalmarks && <tr>
                                    <th scope="row">Total Marks:</th>
                                    <td></td>
                                    <td>
                                    {this.props.test.totalmarks}
                                    </td>
                                </tr>}
                            </tbody>
                            </Table>

                            <div>
                                <Row style={{padding:"50px",paddingTop:'10px',paddingBottom:"20px"}}>
                                <div dangerouslySetInnerHTML={{ __html: this.props.test.testinstruction }} 
                    style={{width:"700px", wordWrap:"break-word"}} />                      
                                </Row>
                                <Row style={{paddingLeft:"50px",paddingRight:"50px",paddingBottom:"10px"}}>
                                    <Button color="primary" type="button" style={{marginLeft:"auto", marginRight:"0"}} onClick={this.props.changetestinstrstate}>
                                        Start Test
                                    </Button>
                                </Row>
                            </div>
                            <Row style={{padding:"50px", paddingBottom:"0px" ,justifyContent:"center"}}>
                                <div style={{display:"flex",padding:'10px', color:"white", background:"#1e73be"}}>
                                    Powered By: Testacademia
                                </div>
                            </Row>
                        </Card>
                    </div>
                  
              </div>
            </Card>
            </>
        )
    }
}

export default TestInstruction;