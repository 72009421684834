import React from "react";

import { NavLink as Link } from "react-router-dom";

class PremiumVideoRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            video:this.props.video
        }
    }

    render(){
        return(
            <>
                <tr>
                    <th scope="row">{this.state.video.videoname}</th>
                    <td> 
                    </td>
                    <Link to={{pathname:"/admin/editvideo/"+this.state.video._id,state:{course:this.state.video}}}>
                        <td className="text-red">
                        <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                        Edit
                        </td>
                    </Link>
                </tr>
            </>
        )
    }
}

export default PremiumVideoRow;