import React, { Component } from 'react'

class ReadingResults extends Component {

    constructor(props){
        super(props);
        this.state={
            reading: this.props.reading
        }
        this.renderreadingresult = this.renderreadingresult.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.reading!==this.props.reading){
            this.setState({
                reading:this.props.reading
            })
        }
    }

    renderreadingresult(){
        var mcqsr, mcqmr, fillblanks, matrix, heading, matching;

        mcqsr = this.state.reading.filter((result) => {return result.parttype==="MCQ Single Right"});
        mcqmr = this.state.reading.filter((result) => {return result.parttype==="MCQ Multiple Right"});
        fillblanks = this.state.reading.filter((result) => {return result.parttype==="Fill Blanks"})
        matrix = this.state.reading.filter((result) => {return result.parttype==="Matrix"})
        heading = this.state.reading.filter((result) => {return result.parttype==="List of Headings"})
        matching = this.state.reading.filter((result) => {return result.parttype==="Matching"})
        
        var mcqsrscore=0, mcqmrscore=0, fillblanksscore=0, matrixscore=0, headingscore=0, matchingscore=0;

        for(let i =0; i<mcqsr.length; i++){
            mcqsrscore=mcqsrscore+mcqsr[i].score
        }
        for(let i =0; i<mcqmr.length; i++){
            mcqmrscore=mcqmrscore+mcqmr[i].score
        }
        for(let i =0; i<fillblanks.length; i++){
            fillblanksscore=fillblanksscore+fillblanks[i].score
        }
        for(let i =0; i<matrix.length; i++){
            matrixscore=matrixscore+matrix[i].score
        }
        for(let i =0; i<heading.length; i++){
            headingscore=headingscore+heading[i].score
        }
        for(let i =0; i<matching.length; i++){
            matchingscore=matchingscore+matching[i].score
        }
        
        return(
            <tbody>
                {mcqsr.length!==0 && <tr>
                    <th>MCQ Single Right</th>
                    <td>{mcqsr.length}</td>
                    <td>{mcqsrscore}</td>
                </tr>}
                {mcqmr.length!==0 && <tr>
                    <th>MCQ Multiple Right</th>
                    <td>{mcqmr.length}</td>
                    <td>{mcqmrscore}</td>
                </tr>}
                {fillblanks.length!==0 && <tr>
                    <th>Fill Blanks</th>
                    <td>{fillblanks.length}</td>
                    <td>{fillblanksscore}</td>
                </tr>}
                {matrix.length!==0 && <tr>
                    <th>Matrix</th>
                    <td>{matrix.length}</td>
                    <td>{matrixscore}</td>
                </tr>}
                {heading.length!==0 && <tr>
                    <th>List of Headings</th>
                    <td>{heading.length}</td>
                    <td>{headingscore}</td>
                </tr>}
                {matching.length!==0 && <tr>
                    <th>Matching</th>
                    <td>{matching.length}</td>
                    <td>{matchingscore}</td>
                </tr>}
            </tbody>
        )
    }

    render() {
        return (
            <>
                {this.renderreadingresult()}
            </>
        )
    }
}


export default ReadingResults;
