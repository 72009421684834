import React from 'react';

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}


const Option = props =>(
    <div className="custom-radio">
        <input
            style={{margin:"10px",display:"inline"}}
            type="checkbox"
            value={props.option}
            name={props.quenum}
            checked={props.useranswer.includes(props.option)}
            onChange={(evt) => {props.handlechange(evt,props.option)}}
        />
        <label style={{display:"inline"}} >
            {props.option}
        </label>
    </div>
)

class EachQuesMcqMr extends React.Component{
    constructor(props){
        super(props);

        this.state={
            question: this.props.question,
            show3rdoption: false,
            show4thoption: false,
            show5thoption: false,
            useranswer: this.props.results[this.props.resultindex].useranswer,
            results: this.props.results[this.props.resultindex],
            showoptions:this.props.question.questionnum===this.props.currentques
        }
        this.renderquestion = this.renderquestion.bind(this);
        this.renderoptions = this.renderoptions.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.showques = this.showques.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.question !== this.props.question || prevProps.currentques !== this.props.currentques) {
            this.setState({
                question: this.props.question,
                useranswer: this.props.results[this.props.resultindex].useranswer,
                results: this.props.results[this.props.resultindex],
                showoptions:this.props.question.questionnum===this.props.currentques
            });
          }
    }

    showques(){
        this.setState({
            showoptions:!this.state.showoptions
        })
    }

    handlechange(evt, option){
        if(evt.target.checked){
            this.setState({
                useranswer: [...this.state.useranswer,evt.target.value]
            },()=>{
                //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer,this.state.question.answerexplain);
                var results = this.props.results;
                var quesresult = results[this.props.resultindex];
                if(quesresult.rightanswer.includes(this.state.useranswer)){
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=1;
                }else{
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=0;
                }
                results[this.props.resultindex] = quesresult;
                this.props.updateResult(results);
            });
        }
        else if(!evt.target.checked){
            var useranswerArr = [...this.state.useranswer];
            var indextoremove = useranswerArr.indexOf(option);
            if(indextoremove!==-1){
                useranswerArr.splice(indextoremove,1);
                this.setState({
                    useranswer: useranswerArr
                },()=>{
                    //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer);
                    var results = this.props.results;
                    var quesresult = results[this.props.resultindex];
                    if(quesresult.rightanswer.includes(this.state.useranswer)){
                        quesresult.useranswer = this.state.useranswer;
                        quesresult.score=1;
                    }else{
                        quesresult.useranswer = this.state.useranswer;
                        quesresult.score=0;
                    }
                    results[this.props.resultindex] = quesresult;
                    this.props.updateResult(results);
                });
            }
        }
    }

    renderquestion(){
        return (<p className={this.state.showoptions?"mcq-question-active":"mcq-question"}
                    onClick={()=>{this.showques()}}>
                    {this.props.question.questionnum}. {this.props.question.question}
                </p>)
    }
    
    renderoptions(){
        const optionrows = [];
        if(this.props.question.option5!==""  &&this.props.question.option4!==null&& this.props.question.option5!==" "){
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option1} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option2} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option3} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option4} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option5} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            return optionrows;
        }
        if(this.props.question.option4 !== '' && this.props.question.option4!==null && this.props.question.option4!==" "){
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option1} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option2} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option3} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option4} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            return optionrows;
        }
        if(this.props.question.option3!=='' && this.props.question.option3!==null && this.props.question.option3!==" "){
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option1} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option2} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.props.question.questionnum} option={this.props.question.option3} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            return optionrows;
        }
    }

    render(){
        return(
            <div className={"mcq-ques_"+this.props.setting}>
                {this.renderquestion()}
                {this.state.showoptions && this.renderoptions()}
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(EachQuesMcqMr);