import React from "react";

export default class LabelInputs extends React.Component{
    constructor(props){
        super(props);

        this.state={
            xvalue: this.props.xvalue,
            yvalue: this.props.yvalue
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.xvalue!==this.props.xvalue || prevProps.yvalue!==this.props.yvalue){
            this.setState({
                xvalue: this.props.xvalue,
                yvalue: this.props.yvalue
            })
        }
    }

    render(){
        return(
            <div className="labelinput-container" style={{position:"absolute", left:this.state.xvalue, top:this.state.yvalue}}>
                <input type="text"/>
                <i class="fas fa-times-circle" onClick={()=>{this.props.removeinput(this.state.xvalue,this.state.yvalue)}}></i>
            </div>
        )
    }
}