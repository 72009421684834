import React from "react";
import {Row, Button} from 'reactstrap';
import axios from "axios";
import RecordRTC from 'recordrtc';
import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";

const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

// const recorder = 
class SpeakingQues extends React.Component{

    constructor(props){
        super(props);
        this.state={
            audio:'',
            question:this.props.quesdata,
            isblocked: this.props.isblocked,
            blobUrl: '',
            isrecording:this.props.startrecording,
            startrecord:false,
            isLoading:false,
            resultindex:null,
            mins : 0,
            secs : 0,
            recordAudio:''
        }
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.handleaudiofile = this.handleaudiofile.bind(this);
        this.captureUserMedia = this.captureUserMedia.bind(this);
	}
	
	componentDidMount(){
		if(this.state.question)
        for(let i=0; i<this.props.results.length; i++){
            if(this.props.results[i].questionnum===this.state.question[0].questionnum){
                this.setState({
                    resultindex:i
                })
            }
        }
    
  }

  captureUserMedia(callback) {
    var params = { audio: true, video: false };
    navigator.getUserMedia(params, callback, error => {
      alert(JSON.stringify(error));
    });
  }
    
    componentDidUpdate(prevProps){
		if(prevProps.startrecording !== this.props.startrecording) {
			this.setState({isrecording: this.props.startrecording});
		}
	}
	
    start = () => {
      if(this.state.isblocked){
        navigator.getUserMedia({ audio: true ,video:false},
          () => {
            console.log('Permission Granted');
            this.setState({ isblocked: false });
          },
          () => {
            console.log('Permission Denied');
            this.setState({ isblocked: true })
          },
      );
      }
      else{
        this.captureUserMedia((stream) => {
          this.state.recordAudio = RecordRTC(stream, { type: 'audio' });
          this.state.recordAudio.startRecording();
        });
          this.setState({
           isrecording:false,
           startrecord:true
       });
       this.myInterval = setInterval(() => {
         if (this.state.secs <= 60) {
             this.setState(({ secs }) => ({
                 secs: this.state.secs + 1
             }))
         }
         if (this.state.secs === 60) {  
            this.setState(({ mins }) => ({
                mins:this.state.mins + 1,
                secs: 1
            }))   
         } 
        }, 1000)
    }
    };
  
    stop = () => {
      this.setState({
        startrecord:false
      });  
      this.state.recordAudio.stopRecording(() => {
      clearInterval(this.myInterval)
      const blobUrl = URL.createObjectURL(this.state.recordAudio.blob)
      this.setState({blobUrl,isrecording:false});
      var d = new Date();
      var file = new File([this.state.recordAudio.blob,],d.valueOf(),{ type:"audio/wav" })
      console.log(file);
      this.handleaudiofile(file);
    })       
   };
    
   handleaudiofile(ev){
     this.setState({isLoading:true})
    let file = ev;
    let fileName = ev.name;
    let fileType = ev.type;
    axios.post("https://api.testacademia.com/admin/audio/sign_s3",{
      fileName : fileName,
      fileType : fileType
    })
    .then(response => {
      var returnData = response.data.data.returnData;
      var signedRequest = returnData.signedRequest;
      var url = returnData.url;
      this.setState({audio: url,
        },()=>{
			var results = this.props.results;
            var quesresult = results[this.state.resultindex];
            quesresult.useranswer = this.state.audio;
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })
      var options = {
        headers: {
          'Content-Type': fileType,
          'x-amz-acl': 'public-read'
        }
      };
      axios.put(signedRequest,file,options)
      .then(result => {
        this.setState({isLoading:true})
        console.log("audio upload")})
      .catch(error => {
        alert("ERROR " + JSON.stringify(error));
      })
    })
    .catch(error => {
      alert(JSON.stringify(error));
    })
}

    render(){
        return(
            <>
            <Row style={{textAlign:"center",display:"inherit"}}>

            <Button 
                  color="primary"
                  size ="sm" 
                  onClick={this.start} 
                  disabled={!this.state.isrecording} 
                  type="button">
              <i className="ni ni-button-play" style={{marginRight:"10px"}}/>Start
            </Button>
            {this.state.mins}:{this.state.secs}
            <Button 
                  color="primary" 
                  size ="sm" 
                  onClick={this.stop} 
                  disabled={!this.state.startrecord} 
                  type="button">
            <i className="ni ni-button-pause" style={{marginRight:"10px"}}/>Stop
            </Button>
            </Row>
             </>   
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(SpeakingQues);