import React from "react";
// reactstrap components
import {
    Button,
    Card,
    Table,
    
  } from "reactstrap";
  // core components

import QuesInput from "./QuesInput";
import TestDetails from "./AddTestDetails";

export default class QuesDetails extends React.Component{

    constructor(props){
        super(props);
        this.state={
            startques: this.props.startques,
            endques: this.props.endques,
            submittest: false,
            showpartscomp:false,
            parttype:this.props.parttype,
            questions:[],
            quesdatas:[]
        }
        this.onclickNoExtraParts = this.onclickNoExtraParts.bind(this);
        this.onclickYesExtraParts = this.onclickYesExtraParts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.appendquesarray = this.appendquesarray.bind(this);
        this.onclickYesSubmitTest = this.onclickYesSubmitTest.bind(this);
        // this.onclickNoSubmitTest = this.onclickNoSubmitTest.bind(this);
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value });
      }

    renderquesinput(){
        var i;
        var quesrows = [];
        for(i=parseInt(this.state.startques); i<=parseInt(this.state.endques); i++){
            quesrows.push(<QuesInput parttype={this.state.parttype} ispara={this.props.ispara} quesnum={i} appendquesarray={this.appendquesarray}/>)
          }
          
        return quesrows
    }

    onclickYesSubmitTest(){
        this.props.submittest();
    }

    onclickNoExtraParts(){
        this.setState({
            submittest: true
        },() => {
            this.props.getQuesArray(this.state.questions, this.state.quesdatas);
        })
    }

    onclickYesExtraParts(){
        this.props.getQuesArray(this.state.questions, this.state.quesdatas);
        this.props.rerenderpartcomp();
    }

    appendquesarray(quesdata){
        this.setState({
            questions: [...this.state.questions,quesdata],
            quesdatas: [...this.state.quesdatas, quesdata]
        });
    }

    render(){
        return(
            <>
            {/* Page content */}
                {!this.state.showpartscomp && this.renderquesinput()}
                {!this.state.showpartscomp && !this.state.submittest && <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                    <tbody>
                        <tr>
                            <th colSpan="5">Do you want to add more parts?
                                <Button color="primary" 
                                    size = "sm" 
                                    style={{marginLeft:"50px"}}
                                    onClick={this.onclickYesExtraParts}>
                                        Yes
                                </Button>
                                <Button color="primary" 
                                    size = "sm" 
                                    style={{marginLeft:"50px"}}
                                    onClick={this.onclickNoExtraParts}>
                                        No
                                </Button>
                            </th>
                        </tr>
                    </tbody>
                </Table>
                </Card>}
                {!this.state.showpartscomp && this.state.submittest && <Card className="shadow" style={{marginLeft:"50px",marginTop:"50px", marginRight:"50px"}}>
                <Table className="align-items-center table-flush" responsive>
                    <tbody>
                        <tr>
                            <th colSpan="5">Do you want to submit test?
                                <Button color="primary" 
                                    size = "sm" 
                                    style={{marginLeft:"50px"}}
                                    onClick={this.onclickYesSubmitTest}>
                                        Yes
                                </Button>
                                {/* <Button color="primary" 
                                    size = "sm" 
                                    style={{marginLeft:"50px"}}>
                                        No
                                </Button> */}
                            </th>
                        </tr>
                    </tbody>
                </Table>
                </Card>}
                {this.state.showpartscomp && TestDetails.addnewpart()}
            </>
        );
    }

}