import axios from "axios";
async function evaluateWrite(userid,test, writingPartList){
    var writingbandscore = 0;
    const res = await axios.post('https://api.testacademia.com/user/upgrade/finduser',{userid:userid})
    const res2 = await axios.post('https://api.testacademia.com/admin/package/onepackage/'+res.data.userType)
    if (res2.data.scored.includes(test._id)) {
        var scoredtest = true;
        var writingPartListResult = writingPartList;
        const headers = {
            'x-api-key': '6ih0Rdi2qm4faked9SbD33mCvgraTca53dZAxCsA',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        }
        for(let i=0; i<writingPartList.length; i++){
            const res3 = await axios.post("https://api.crucibledesk.com/v1/ieltswriting",
                        {"text":writingPartList[i].useranswer,"Type":"essay"},
                        {headers: headers})
            console.log(res3.data);
            writingPartListResult[i].result = res3.data;
            writingbandscore = writingbandscore + res3.data["IELTS Writing Band"];
        }
        return {writingbandscore:writingbandscore, scoredtest:scoredtest, results:writingPartListResult}
    }else{
        return {scoredtest:false,result:null}
    }
}

export default evaluateWrite;