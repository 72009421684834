import React from "react";
import axios from "axios";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'

class PackageRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            package :this.props.package
        }

    }

    deletePackage(){

      confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure you want to delete the Package?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  if(this.props.package.course==="IELTS"){
                      axios.post("https://api.testacademia.com/admin/package/deletepackage/"+this.state.package._id)
                  .then(res=>{
                      console.log(res.data);
                      alert(res.data)
                  }).catch(err=>{
                      console.log(err)
                  })
              }
              else{
                  const name = this.props.package.course.toLowerCase().replace(" ","","g")
                  axios.post("https://api.testacademia.com/admin/"+name+"/package/deletepackage/"+this.state.package._id)
                  .then(res=>{
                      console.log(res.data);
                      alert(res.data)
                  }).catch(err=>{
                      console.log(err)
                  })
              }
          }
            },
            {
              label: 'No'
            }
          ]
        });
  
      
  }

    render(){
        return(
            <>
                <tr>
                    <th scope="row">{this.state.package.packagename}</th>
                    <th scope="row">{this.state.package.price}</th>
                    <th scope="row">{this.state.package.validity}</th>
                        <Link to={{pathname:"/admin/viewpackage/"+this.state.package._id, 
                            state:{package:this.state.package}}}>
                              <td className="text-blue">
                                <i className="ni ni-glasses-2 text-blue" style={{marginRight:"5px"}}/>{" "}
                                  View
                                </td>
                        </Link>
                            
                        <Link to={{pathname:"/admin/editpackage/"+this.state.package._id, 
                            state:{package:this.state.package}}}>
                              <td className="text-red">
                                <i className="ni ni-scissors text-red" style={{marginRight:"5px"}}/>{" "}
                                  Edit
                                </td>
                        </Link>
                        <td className="text-red"  onClick={() => this.deletePackage()}>
                        <i className="ni ni-basket text-red" style={{marginRight:"5px"}}/>{" "}
                        Delete
                    </td>
                </tr>
            </>
        )
    }
}

export default PackageRow;