import React from "react";
import classnames from "classnames";
import {
    Card,
    CardHeader,
    NavLink,
    Nav,
    Row,
    TabContent,
    TabPane,
    Table,
    Pagination, 
    PaginationItem, 
    PaginationLink
}from "reactstrap";

import axios from "axios";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";

import ReactAudioPlayer from 'react-audio-player';

import {evaluateRead} from "./evaluteread";
import {evaluateListen} from "./evaluatelisten";
import evaluateWrite from "./evaluatewrite";
import {evaluateSpeak} from "./evaluatespeak";

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
import BriefResults from "./BriefResults";
import ListeningResults from "./ListeningResults";
import ReadingResults from "./ReadingResults";
import WritingResults from "./WritingResults";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

const QuesNav = props => (
    <PaginationItem className={props.statequesnum===props.questionnum?"active":"disabled"} 
        onClick={()=>{props.changeques(props.questionnum, props.index)}}>
        <PaginationLink>
        {props.questionnum}
        </PaginationLink>
    </PaginationItem>
)

class ResultComponent extends React.Component{
    constructor(props){
        super(props)
        this.state={
            tabs: 1,
            userid: cookie.load('userid'),
            test:this.props.test,
            results:this.props.results,
            quesnumindex:0,
            questionnum:this.props.results[0].questionnum,
            listening:[],
            reading:[],
            writing:[],
            speaking:[],
            readingband:0,
            listeningband:0,
            writingband:0,
            speakingband:0,
            overallband:0,
            package:'',
            scored:null,
            isLoading:false
        }
        this.renderquestionresults = this.renderquestionresults.bind(this);
        this.quesnav = this.quesnav.bind(this);
        this.changeques = this.changeques.bind(this);
        this.questiondetails = this.questiondetails.bind(this);
    }
    
    componentDidMount(){
        this.setState({
            reading: this.state.results.filter((result)=>{return result.partcategory==="Reading"}),
            listening: this.state.results.filter((result)=>{return result.partcategory==="Listening"}),
            writing: this.state.results.filter((result)=>{return result.partcategory==="Writing"}),
            speaking: this.state.results.filter((result)=>{return result.partcategory==="Speaking"})
        },async ()=>{
            if(this.state.reading.length!==0){
                var readingbandscore = await evaluateRead(this.state.reading,this.state.test.testname);
                console.log(readingbandscore);
                this.setState(prevState=>({
                    overallband:prevState.overallband+readingbandscore,
                    readingband:readingbandscore
                }),()=>{
                    if(this.state.test.testcategory!=="Full Length Test"){
                        var reqbody = {
                            userid: this.state.userid,
                            testid: this.state.test._id,
                            testname: this.state.test.testname,
                            eachresult: this.props.results,
                            bandscore: this.state.readingband
                        }
                        axios.post("https://api.testacademia.com/user/result/addresult",reqbody)
                        .then(res=>{
                            console.log(res.data);
                            this.setState({
                                isLoading:false
                            })
                        }).catch(err=>{
                            console.log(err);
                        });
                    }
                });
            }
            if(this.state.listening.length!==0){
                var listeningbandscore = await evaluateListen(this.state.listening);
                this.setState(prevState=>({
                    overallband:prevState.overallband+listeningbandscore,
                    listeningband:listeningbandscore
                }),()=>{
                    if(this.state.test.testcategory!=="Full Length Test"){
                        var reqbody = {
                            userid: this.state.userid,
                            testid: this.state.test._id,
                            testname: this.state.test.testname,
                            eachresult: this.props.results,
                            bandscore: this.state.listeningband
                        }
                        axios.post("https://api.testacademia.com/user/result/addresult",reqbody)
                        .then(res=>{
                            console.log(res.data);
                            this.setState({
                                isLoading:false
                            })
                        }).catch(err=>{
                            console.log(err);
                        });
                    }
                });
            }
            if(this.state.writing.length!==0){
                var evaluateWriteRes = await evaluateWrite(this.state.userid,this.state.test,this.state.writing);
                this.setState(prevState=>({
                    overallband:prevState.overallband+evaluateWriteRes.writingbandscore,
                    writingband:evaluateWriteRes.writingbandscore,
                    scored:evaluateWriteRes.scoredtest
                }),()=>{
                    for(let i=0; i<evaluateWriteRes.results.length; i++){
                        for(let j=0; j<this.props.results.length; j++){
                            if(this.props.results[j].questionnum===evaluateWriteRes.results[i].questionnum){
                                var stateresult = this.props.results;
                                stateresult[j].result = evaluateWriteRes.results[i].result;
                                this.props.updateResult(stateresult);
                            }
                        }
                    }

                    if(this.state.test.testcategory!=="Full Length Test"){
                        var reqbody = {
                            userid: this.state.userid,
                            testid: this.state.test._id,
                            testname: this.state.test.testname,
                            eachresult: this.props.results,
                            bandscore: this.state.writingband
                        }
                        axios.post("https://api.testacademia.com/user/result/addresult",reqbody)
                        .then(res=>{
                            console.log(res.data);
                            this.setState({
                                isLoading:false
                            })
                        }).catch(err=>{
                            console.log(err);
                        });
                    }

                });
                console.log(evaluateWriteRes);
            }
            if(this.state.speaking.length!==0){
                var evaluateSpeakRes = await evaluateSpeak(this.state.userid,this.state.test,this.state.speaking);
                console.log(evaluateSpeakRes);
            }
        });
        
    }

    quesnav(){
        const ques = [];
        for(let i=0; i<this.state.results.length;i++){
            ques.push(<QuesNav index={i} statequesnum={this.state.questionnum} changeques={this.changeques} questionnum={this.state.results[i].questionnum}/>)
        }
        return ques;
    }

    changeques(quesnum, index){
        this.setState({
            questionnum:quesnum,
            quesnumindex: index
        })
    }

    questiondetails(){
        return(
            <tbody>
                {this.state.listening.length!==0?
                <tr>
                    <th>Question Audio:</th>
                    <td ><ReactAudioPlayer
                    src={this.state.results[this.state.quesnumindex].audiourl }
                    controls="true"
                  /></td>
                </tr>:
                <tr>
                    <th>Passage:</th>
                    <td ><div className="passage" dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].passage }}/></td>
                </tr>}
                <tr>
                    <th>Question:</th>
                    <td><div dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].question }}/></td>
                </tr>
                <tr>
                    <th>Right Answer:</th>
                    <td>{this.state.results[this.state.quesnumindex].rightanswer}</td>
                </tr>
                <tr>
                    <th>Your Answer:</th>
                    <td>{this.state.results[this.state.quesnumindex].useranswer}</td>
                </tr>
                <tr>
                    <th>Answer Explanation:</th>
                    <td><div className="passage" dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].answerexpl }}/></td>
                </tr>
            </tbody>
        )
    }

    renderquestionresults(){
        return(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <ul style={{padding:"20px 0px 20px 0px", width:"inherit", overflowX:"scroll", display:"flex", listStyleType:"none"}}>
                                    {this.quesnav()}
                        </ul>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    {this.questiondetails()}
                </Table>
            </>
        )
    }


    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
          [state]: index
        });
      };

    render(){
        return(
            <>
            <Card className="shadow" style={{marginLeft:"200px",marginTop:"50px", marginRight:"200px"}}>
              <div>
                    <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Result</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Nav
                                className="nav-fill flex-column flex-md-row"
                                id="tabs-icons-text"
                                pills
                                role="tablist"
                                style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
                            >
                                
                                
                                <NavLink
                                    aria-selected={this.state.tabs === 1}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 1
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 1)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px",  "width":"150px", height:"40px"}}
                                >
                                    <center>Overall Analysis</center>
                                </NavLink>
                                {this.state.listening.length!==0 && <NavLink
                                    aria-selected={this.state.tabs === 2}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 2
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 2)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Listening</center>
                                </NavLink>}
                                {this.state.reading.length!==0 &&<NavLink
                                    aria-selected={this.state.tabs === 3}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 3
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 3)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Reading</center>
                                </NavLink>}
                                {this.state.writing.length!==0 &&<NavLink
                                    aria-selected={this.state.tabs === 4}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 4
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 4)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Writing</center>
                                </NavLink>}
                                {this.state.speaking.length!==0 &&<NavLink
                                    aria-selected={this.state.tabs === 5}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 5
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 5)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Speaking</center>
                                </NavLink>}
                                {(this.state.test.maincategory==="Reading"||
                                this.state.test.maincategory==="Listening"||
                                this.state.test.maincategory==="Full Length Test")
                                && <NavLink
                                    aria-selected={this.state.tabs === 6}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 6
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 6)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Question Wise</center>
                                </NavLink>}
                            </Nav>

                            {this.state.isLoading && <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={80}
                                    width={80}
                                    className="iconcontainer"			
                                />}
                            {!this.state.isLoading &&
                            <Card className="shadow" style={{marginLeft:"20px",marginTop:"30px", marginRight:"20px", marginBottom:"20px"}}>
                                <div>
                                    <TabContent activeTab={"tabs" + this.state.tabs}>
                                    
                                    <TabPane tabId="tabs1">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                                <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Total Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{this.state.overallband}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">No. Of Questions</th>
                                                    <th scope="col">No. of Correct Answers</th>
                                                    <th scope="col">Band Score</th>
                                                    </tr>
                                                </thead>
                                                    <BriefResults 
                                                        listening ={this.state.listening}
                                                        reading = {this.state.reading}
                                                        writing = {this.state.writing}
                                                        speaking = {this.state.speaking}
                                                        listeningband = {this.state.listeningband}
                                                        readingband={this.state.readingband}
                                                        writingband={this.state.writingband}
                                                        speakingband={this.state.speakingband}
                                                        scored={this.state.scored}/>
                                                </Table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs2">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Listening Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{this.state.listeningband}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Part Type</th>
                                                        <th scope="col">No. of Questions</th>
                                                        <th scope="col">No. of Correct Answer</th>
                                                    </tr>     
                                                </thead>
                                                    <ListeningResults
                                                        listening={this.state.listening}/>
                                                </Table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs3">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Reading Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{this.state.readingband}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Part Type</th>
                                                        <th scope="col">No. of Questions</th>
                                                        <th scope="col">No. of Correct Answer</th>
                                                    </tr>     
                                                </thead>
                                                    <ReadingResults 
                                                        reading ={this.state.reading}/>
                                                </Table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs4">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Writing Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{(this.state.scored)?this.state.writingband:"Unscored Test"}</h1>
                                                    </div>
                                                </Row>
                                            </CardHeader>
                                            <WritingResults
                                                        writing={this.state.writing}/>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs5">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Speaking Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{(this.state.scored)?this.state.writingband:"Unscored Test"}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Part Type</th>
                                                        <th scope="col">No. of Questions</th>
                                                        <th scope="col">No. of Correct Answer</th>
                                                    </tr>     
                                                </thead>
                                                    {/* {this.renderlisteningresults()} */}
                                                </Table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs6">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                                {this.renderquestionresults()}
                                            </Card>
                                        </div>
                                    </TabPane>

                                    </TabContent>
                                </div>
                            </Card>
                            }
                        </Card>
                    </div>
              </div>
            </Card>
            </>
        );
    }
}
export default connect(mapStateToProps,mapDispatchToProps())(ResultComponent);